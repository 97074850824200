import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../../assets/Logo-Negro.png'
import Step1 from '../../../assets/Index/Step1.png';
import Step2 from '../../../assets/Index/Step2.png';
import Step3 from '../../../assets/Index/Step3.png';
import Step4 from '../../../assets/Index/Step4.png';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { useTranslation } from 'react-i18next';

const Tutorial = () => {

    const { t } = useTranslation();

    return (
        <section id="process-overview" className='md:p-16 px-6 justify-center bg-third md:py-32 xl:pt-48 py-16'>
            <div className='text-center mb-10'>
                <h1 className='font-bold md:text-6xl text-4xl'>{t("Businesses.TutorialTitle")}</h1>
                <div className='mb-6 mt-2 h-[3rem] md:h-[5rem] flex justify-center'><img alt='Fortimy Logo' className='h-[100%]' src={Logo} /></div>
                <p className='md:text-3xl text-xl m-2'>{t("Businesses.TutorialDescription1")}<span className='font-bold text-primary'>{t("Businesses.TutorialDescription1Word")}</span></p>
                <p className='md:text-3xl text-xl m-2'>{t("Businesses.TutorialDescription2")}<span className='font-bold text-primary'>{t("Businesses.TutorialDescription2Word")}</span></p>
            </div>

            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
                <div id="step-by-step" className='md:grid md:grid-cols-10 items-center md:my-10 flex flex-col-reverse'>

                    <div className='xl:col-start-2 xl:col-end-5 md:col-start-1 md:col-end-6 md:w-auto w-full flex justify-end md:mt-0 mt-10'>
                        <img alt='Step 1' className='text-end md:w-auto max-w-[50%]' src={Step1} />
                    </div>

                    <div className='xl:col-start-5 xl:col-end-9 md:col-start-6 md:col-end-11 md:ml-10'>
                        <button className='rounded-full bg-primary text-white shadow-xl w-12 h-12 font-bold text-4xl mb-2'>1</button>
                        <h2 className='md:text-4xl text-3xl font-bold my-3'>{t('Businesses.TutorialStep1Title')}</h2>
                        <p className='md:text-2xl text-xl my-3'>{t('Businesses.TutorialStep1')}</p>
                        {/* <div className='md:mt-7 mt-5'>
                            <Link to="/pricing" className='bg-primary rounded-full font-bold text-white py-3 px-6 text-xl shadow-lg'>{t('Businesses.TutorialStep1Btn')}</Link>
                        </div> */}
                    </div>

                </div>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
                <div className='md:grid md:grid-cols-10 items-center md:my-10 my-10 flex flex-col'>

                    <div className='xl:col-start-2 xl:col-span-4 md:col-start-1 md:col-end-6 md:text-start text-end'>
                        <button className='rounded-full bg-primary text-white shadow-xl w-12 h-12 font-bold text-4xl mb-2'>2</button>
                        <h2 className='md:text-4xl text-3xl font-bold my-3'>{t('Businesses.TutorialStep2Title')}</h2>
                        <p className='md:text-2xl text-xl my-3'>{t('Businesses.TutorialStep2')}</p>
                        {/* <Link to="/product" className='text-xl text-primary underline font-bold'>{t('Businesses.TutorialStep2Btn')}</Link> */}
                    </div>

                    <div className='xl:col-start-6 xl:col-span-4 md:col-start-6 md:col-end-11 md:w-auto w-full flex justify-start'>
                        <img alt='Step 2' className='md:w-auto w-[85%]' src={Step2} />
                    </div>

                </div>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
                <div className='md:grid md:grid-cols-10 items-center md:my-10 my-10 flex flex-col-reverse'>

                    <div className='xl:col-start-2 xl:col-span-4 md:col-start-1 md:col-end-6 flex justify-end'>
                        <img alt='Step 3' className='md:w-auto w-[75%]' src={Step3} />
                    </div>

                    <div className='xl:col-start-6 xl:col-span-4 md:col-start-6 md:col-end-11 md:ml-10'>
                        <button className='rounded-full bg-primary text-white shadow-xl w-12 h-12 font-bold text-4xl mb-2'>3</button>
                        <h2 className='md:text-4xl text-3xl font-bold my-3'>{t('Businesses.TutorialStep3Title')}</h2>
                        <p className='md:text-2xl text-xl my-3'>{t('Businesses.TutorialStep3')}</p>
                        {/* <Link to="/product" className='text-xl text-primary underline font-bold'>{t('Businesses.TutorialStep3Btn')}</Link> */}
                    </div>

                </div>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
                <div className='md:grid md:grid-cols-10 items-center md:my-10 my-20 flex flex-col'>

                    <div className='xl:col-start-2 xl:col-span-4 md:col-start-1 md:col-end-6 md:mr-10 text-end md:text-start'>
                        <button className='rounded-full bg-primary text-white shadow-xl w-12 h-12 font-bold text-4xl mb-2'>4</button>
                        <h2 className='md:text-4xl text-3xl font-bold my-3'>{t('Businesses.TutorialStep4Title')}</h2>
                        <p className='md:text-2xl text-xl mt-4 mb-6'>{t('Businesses.TutorialStep4')}</p>
                        <Link to="/request-demo" className='bg-primary rounded-full font-bold text-white py-3 px-6 text-xl shadow-lg'>{t('Businesses.TutorialStep4Btn')}</Link>
                    </div>

                    <div className='xl:col-start-6 xl:col-span-4 md:col-start-6 md:col-end-11 flex justify-start md:mt-0 mt-5'>
                        <img alt='Step 4' className='md:w-auto w-[75%]' src={Step4} />
                    </div>

                </div>
            </AnimationOnScroll>


        </section>
    )
}

export default Tutorial;