import React from 'react';
import getVideoID from '../../../helpers/getVideoID';

const VideoModal = ({ video, onOpen, onClose }) => {
  return (
    <>
    {onOpen &&
      <div onClick={() => onClose()} className='fixed top-0 left-0 z-[100] h-screen w-screen bg-black/40 flex justify-center items-center'>
        <iframe className='w-[80vw] h-[80vh]' src={`https://www.youtube.com/embed/${getVideoID(video.youtube_link)}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
      </div>
    }
    </>
  )
}

export default VideoModal