import React from "react";

const TermsConditions = () => {
  return (
    <>
      <section className="w-full flex flex-col p-10 pt-20 md:pt-10 space-y-10 md:px-40">
        <h1 className="text-primary text-4xl md:text-5xl font-bold leading-tight">
          Termos e Condições
        </h1>
        <div className="space-y-4">
          <p>
          A Fortimy, Lda (“Fortimy, “Nós”, “Nosso”) com sede social sita em Rua do Mirante, Casa das Flores 25, 2750-004 Cascais, Portugal, com o número de pessoa coletiva 517 193 493 apresenta estes Termos e Condições.
          </p>
          <p>
          Estes Termos e Condições, juntamente com qualquer documentação adicional aplicável em função do tipo de produtos, serviços ou entidade, regem a utilização e acesso aos Serviços prestados pela Fortimy, Lda., (a “Fortimy”).
          </p>
          <p>
            <span className="font-bold">
            Antes de utilizar, ou de se registar para utilizar, a plataforma e os serviços da Fortimy, o cliente deverá ler atentamente estes termos e condições.
            </span>{" "}
            Estes termos e condições constituem um contrato juridicamente vinculativo entre Fortimy e o cliente. Ao clicar em concordo e continuar, o cliente confirma que tomou conhecimento e aceita estes termos e condições.
          </p>
          <ul className="list-roman list-inside">
            <li>
            Ao fazer o download, instalar, aceder ou utilizar a Plataforma, o Cliente aceita vincular-se aos presentes Termos e Condições. Não estando de acordo com estes Termos e Condições, o Cliente não deve proceder ao download, à instalação, ou à utilização da Plataforma e/ou Serviços da Fortimy;
            </li>
            <li>
            Quando a aceitação do presente Contrato seja feita por quem atue em nome e representação de outra pessoa ou entidade, a pessoa em questão deverá ter legitimidade e os necessários poderes para agir por conta da pessoa ou entidade e, assim, vinculá-la aos presentes Termos e Condições.
            </li>
          </ul>
        </div>

        <ol className="list-decimal list-inside space-y-1">
          <p className="font-bold">Lista de Secções</p>
          <li className="m-0"><a href="#definicoes-e-interpretacao">Definições e Interpretação</a></li>
          <li><a href="#prestacao-de-servicos">Prestação de Serviços</a></li>
          <li><a href="#licenca">Licença</a></li>
          <li><a href="#pagamento-e-condicoes-de-pagamento">Pagamento e Condições de Pagamento</a></li>
          <li><a href="#atualizacao-de-termos-e-condicoes">Atualização de Termos e Condições</a></li>
          <li><a href="#prazo-e-rescisao">Prazo e Rescisão</a></li>
          <li><a href="#confidencialidade">Confidencialidade</a></li>
          <li><a href="#propriedade-intelectual-propriedade-e-titulo">Propriedade Intelectual, Propriedade e Título</a></li>
          <li><a href="#protecao-de-dados">Proteção de Dados</a></li>
          <li><a href="#garantia-e-isencao-de-responsabilidade">Garantia e Isenção de Responsabilidade</a></li>
          <li><a href="#indemnizacao">Indemnização</a></li>
          <li><a href="#limitacoes-e-exclusoes-de-responsabilidade">Limitações e exclusões de responsabilidade</a></li>
          <li><a href="#forca-maior">Força Maior</a></li>
          <li><a href="#diversos">Diversos</a></li>
        </ol>

        <ol className="list-decimal list-inside space-y-10">
          <li id="definicoes-e-interpretacao" className="text-primary text-2xl md:text-3xl font-bold mb-2">
            <h2 className="mb-4">Definições e Interpretação</h2>
            <ol className="list-decimal list-inside text-black text-base font-normal">
              <li>
                Nestes Termos e Condições, exceto nos casos em que o contexto exija interpretação diferente, as seguintes expressões deverão ser interpretadas de acordo com o significado abaixo descrito:
                <ol className="list-decimal list-inside">
                  <li>
                    <span className="font-bold">“Cliente”</span> - pessoa singular ou coletiva (conforme aplicável) que aceita este Contrato que esta a adquirir o Serviço online via{" "}
                    <a
                      href="https://fortimy.com/"
                      target="_blank"
                      className="text-primary"
                      rel="noreferrer"
                    >
                      www.fortimy.com
                    </a>
                    ;
                  </li>
                  <li>
                    <span className="font-bold">“Conta”</span> - conta através
                    da qual os utilizadores acedem e utilizam a Plataforma;
                  </li>
                  <li>
                    <span className="font-bold">“Contacto do Cliente”</span> -
                    dados ou informações eletrónicas enviadas pelo Cliente aos Serviços online através da página{" "}
                    <a
                      href="https://fortimy.com/"
                      target="_blank"
                      className="text-primary"
                      rel="noreferrer"
                    >
                      www.fortimy.com
                    </a>
                    ;
                  </li>
                  <li>
                    <span className="font-bold">“Contrato”</span> - contrato entre as Partes para a prestação de Serviços, conforme estabelecido nestes Termos e Condições, incluindo quaisquer alterações ou atualizações que, periodicamente, sejam necessárias;
                  </li>
                  <li>
                    <span className="font-bold">“Dados do Cliente”</span> -
                    dados, conteúdos e materiais:{" "}
                    <span className="font-bold">(i)</span> carregados ou
                    armazenados na Plataforma pelo Cliente;{" "}
                    <span className="font-bold">(ii)</span> transmitidos ou
                    organizados pela Plataforma a pedido do Cliente;{" "}
                    <span className="font-bold">(iii)</span> fornecidos pelo
                    Cliente à Fortimy para carregamento, transmissão ou
                    armazenamento na Plataforma;{" "}
                    <span className="font-bold">(iv)</span> ou gerados pela
                    Plataforma como resultado da utilização dos Serviços,
                    conforme acordado com o Cliente (com exceção de dados
                    analíticos relacionados com a utilização da Plataforma e dos
                    arquivos de log do servidor);
                  </li>
                  <li>
                    <span className="font-bold">“Data Efetiva”</span> - data de
                    subscrição dos Serviços pelo Cliente mediante aceitação dos
                    presentes Termos e Condições;
                  </li>
                  <li>
                    <span className="font-bold">“Empresa” ou “Fortimy”{" "}</span>
                    significa a sociedade Fortimy, Lda., com sede em Portugal;
                  </li>
                  <li>
                    <span className="font-bold">“Informação Confidencial”{" "}</span>
                    - qualquer informação de uma das Partes transmitida ou acedida pela outra Parte virtude deste Contrato (seja por escrito, oralmente ou de outra forma) e que não sejam de domínio público, independentemente de a Parte a quem a informação pertence a identificar ou não expressamente como confidencial. Entre outras, incluem-se como informações confidenciais: os dados de cada Parte; software e operações informáticas próprias de cada Parte; todo o código, invenções, algoritmos, conceitos de negócios, fluxo de trabalho, marketing, informações financeiras, segredos de negócio e informações técnicas e comerciais; os concretos termos e preços aplicados no âmbito do presente Contrato, credenciais de autenticação associadas à utilização dos Serviços, dados pessoais, bem qualquer tipo de informação que seja identificada como confidencial por qualquer das Partes;
                  </li>
                  <li>
                    <span className="font-bold">“Proteção de Dados”</span> -
                    legislação aplicável ao tratamento de dados pessoais,
                    incluindo o Regulamento Geral de Proteção de Dados
                    (Regulamento (UE) 2016/679);
                  </li>
                  <li>
                    <span className="font-bold">“Opção de Assinatura”{" "}</span>
                    significa o tipo de Serviço escolhido pelo Cliente via{" "}
                    <a
                      href="https://fortimy.com/"
                      target="_blank"
                      className="text-primary"
                      rel="noreferrer"
                    >
                      www.fortimy.com
                    </a>
                    , e definido no Adobe Acrobat E-sign form assinado com a
                    Fortimy, incluindo o tipo de produto e respetivas
                    características particulares, tais como: prazo de duração do
                    Serviço, contas, prazo e período de renovação automática
                    (nos termos da cláusula 6.2), destaques, etc;
                  </li>
                  <li>
                    <span className="font-bold">“Plataforma”</span> - plataforma
                    gerida pela Fortimy e através da qual a Fortimy presta os
                    seus Serviços ao Cliente;
                  </li>
                  <li>
                    <span className="font-bold">“Prazo”</span> - prazo o
                    definido na cláusula 6.1. do presente Contrato;
                  </li>
                  <li>
                    <span className="font-bold">“Propriedade Intelectual”{" "}</span>
                    - patentes, direitos de patente, direitos de autor,
                    incluindo direitos sobre base de dados ou quaisquer obras
                    passíveis de ser protegidas em sede de direitos de autor,
                    incluindo e sem excluir, texto, imagens ou vídeo, segredos
                    comerciais, know-how, marcas registadas, nomes comerciais,
                    bem como outros direitos de propriedade intelectual sob a
                    Plataforma ou os Serviços, quer existentes à presente data,
                    quer futuros, incluindo os pendentes de registo;
                  </li>
                  <li>
                    <span className="font-bold">“Serviços”</span> - serviços
                    prestados ou a prestar ao Cliente ao abrigo dos presentes
                    Termos e Condições;{" "}
                  </li>
                  <li>
                    <span className="font-bold">“Preços”</span> - valor a pagar
                    pelo Cliente como contrapartida da prestação de Serviços, de
                    acordo com a Opção de Assinatura aplicável;
                  </li>
                  <li>
                    <span className="font-bold">“Utilizador“</span> - pessoa
                    física, com mais de 18 anos, titular de uma conta, que
                    utilize e tenha acesso à Plataforma.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li id="prestacao-de-servicos" className="text-primary text-2xl md:text-3xl font-bold">
            <h2 className="mb-4">Prestação de Serviços</h2>
            <ol className="list-decimal list-inside text-black text-base font-normal">
              <li>
                <span className="font-bold">Serviço: </span> A partir da Data
                Efetiva e pelo prazo de duração do Contrato, a Fortimy
                compromete-se a prestar ao Cliente os Serviços.
                <ol>
                  <li>
                    A Fortimy reserva-se no direito de, a seu exclusivo e
                    absoluto critério, e sem necessidade aviso prévio,
                    introduzir periodicamente alterações e atualizações para
                    melhoria do Serviço, sem prejuízo do disposto na cláusula
                    5ª.
                  </li>
                </ol>
              </li>
              <li>
                <span className="font-bold">Registo: </span>O Contacto do
                Cliente fornecerá os detalhes para registo em{" "}
                <a
                  href="https://fortimy.com/"
                  target="_blank"
                  className="text-primary"
                  rel="noreferrer"
                >
                  www.fortimy.com{" "}
                </a>
                e para criação da Conta e configuração de palavra-passe.
                <ol>
                  <li>
                    A Fortimy criará uma Conta para o Cliente e dar-lhe-á os
                    detalhes necessários de login dessa Conta.
                  </li>
                </ol>
              </li>
              <li>
                <span className="font-bold">Obrigações do Cliente: </span> O
                Cliente deverá assegurar-se de que:{" "}
                <span className="font-bold">(i)</span> os seus trabalhadores,
                agentes e subcontratados atuam em conformidade com o presente
                Contrato, incluindo todas as restrições específicas aplicáveis e
                responder pela sua atuação;{" "}
                <span className="font-bold">(ii)</span> adota todos os esforços
                comercialmente razoáveis para evitar acessos ou utilização não
                autorizados dos Serviços, devendo notificar prontamente a
                Fortimy de quaisquer acessos ou utilização não autorizado;{" "}
                <span className="font-bold">(iii)</span> utiliza o Serviço de
                acordo com os termos e condições deste Contrato e legislação
                aplicável.
                <ol>
                  <li>
                    Embora não se encontre a tal legalmente obrigada, a Fortimy
                    poderá monitorizar a utilização do Serviço pelo Cliente, bem
                    como proibir qualquer utilização do Serviço que acredite
                    violar (ou alegadamente viole) o acima exposto ou qualquer
                    disposição legal aplicável.
                  </li>
                  <li>
                    É responsabilidade do Cliente a obtenção e manutenção dos
                    equipamentos e serviços auxiliares necessários para a
                    conexão, acesso e utilização do Serviço, incluindo, sem
                    limitação, modems, hardware, servidores, software, sistemas
                    operacionais, redes, servidores Web e similares. O Cliente
                    será também responsável por garantir a segurança de tais
                    equipamentos, contas, senhas (incluindo, entre outras,
                    senhas administrativas e de utilizador) e arquivos, bem como
                    as utilizações das contas do Cliente, com ou sem o seu
                    conhecimento e consentimento.
                  </li>
                </ol>
              </li>
              <li>
                <span className="font-bold">Referência do Cliente: </span>O
                Cliente concorda que a Fortimy pode identificar o Cliente como
                seu cliente, em apresentações de vendas, materiais de marketing
                e comunicados à imprensa.
              </li>
            </ol>
          </li>
          <li id="licenca" className="text-primary text-2xl md:text-3xl font-bold">
            <h2 className="mb-4">Licença</h2>
            <ol className="list-decimal list-inside text-black text-base font-normal">
              <li>
                <span className="font-bold">Licença: </span> Sujeito a este
                Contrato e durante o seu prazo, a Fortimy concede ao Cliente uma
                licença limitada para utilização pelo Cliente no âmbito da sua
                atividade comercial, não exclusiva e intransmissível, sem
                faculdade de sublicenciar, de utilização da Plataforma, no país
                em que é adquirida a licença durante o prazo definido para o
                Serviço, de acordo com as especificações e limitações previstas
                no presente Contrato e na Opção de Assinatura aplicável;
              </li>
              <li>
                <span className="font-bold">
                  Limites do direito de utilização da Plataforma:{" "}
                </span>
                A licença concedida pela Fortimy ao Cliente está sujeita aos
                seguintes limites de utilização:
                <ol>
                  <li>
                    Os Serviços apenas poderão ser utilizados por
                    administradores, trabalhadores, agentes e subcontratados do
                    Cliente ou de sociedades suas afiliadas, e devem sempre ser
                    previamente indicados pelo Cliente para a Fortimy;
                  </li>
                  <li>
                    Em circunstância alguma, poderão os Serviços ser
                    simultaneamente usados por um número de utilizadores
                    superior ao acordado, desde que o Cliente possa adicionar ou
                    remover licenças de utilizador simultâneas, notificando
                    devidamente a Fortimy.
                  </li>
                  <li>
                    Se um usuário não estiver a utilizar a plataforma corretamente, for denunciado mais de uma vez ou se houver suspeita de má conduta, poderá resultar na sua exclusão da plataforma.
                  </li>
                </ol>
              </li>
              <li>
                <span className="font-bold">Restrições de Utilização: </span>
                Exceto nos casos expressamente previstos no presente Contrato, o
                Cliente não se encontra autorizado (nem a conceder tal permissão
                a terceiros) a: <span className="font-bold">(i)</span> vender,
                alugar, licenciar, sublicenciar, distribuir, dar em promessa,
                ceder ou transferir, no todo ou em parte, os Serviços ou
                qualquer interesse neles para outra parte;{" "}
                <span className="font-bold">(ii)</span> fornecer, divulgar ou
                disponibilizar ou permitir a utilização dos Serviços, no todo ou
                em parte, a terceiros sem o consentimento prévio e por escrito
                da Fortimy; <span className="font-bold">(iii)</span> instalar ou
                utilizar a Plataforma de maneira a contornar ou interferir na
                operação da medida tecnológica que controla o acesso ao Serviço;{" "}
                <span className="font-bold">(iv)</span> modificar, traduzir,
                adaptar ou criar trabalhos derivados com base na Plataforma;{" "}
                <span className="font-bold">(v)</span> exportar ou reexportar a
                Plataforma ou qualquer trabalho derivado dela;{" "}
                <span className="font-bold">(vi)</span> utilizar a Plataforma
                para desenvolver, testar, alojar ou executar e operar aplicações
                em nome de terceiros sob o presente Contrato, sem o
                consentimento prévio da Fortimy;{" "}
                <span className="font-bold">(vii)</span> fazer engenharia
                reversa, descompilar, desmontar ou tentar descobrir o
                código-fonte, código-objeto ou estrutura subjacente, ideias,
                know-how ou algoritmos relevantes para a Plataforma, ou qualquer
                software, documentação ou dados relacionados ao Serviço;{" "}
                <span className="font-bold">(viii)</span> utilizar os Serviço
                para prestar formação a terceiros;{" "}
                <span className="font-bold">(ix)</span> tentar copiar, analisar,
                rastrear, modificar, duplicar, criar trabalhos derivados,
                enquadrar, espelhar, republicar, baixar, exibir, transmitir ou
                distribuir toda ou qualquer parte da Plataforma em qualquer
                forma ou mídia ou por qualquer meio ;{" "}
                <span className="font-bold">(x)</span> conceder acesso a toda ou
                qualquer parte da Plataforma com o intuído de criar produtos ou
                serviços que concorrentes;{" "}
                <span className="font-bold">(xi)</span> utilizar o Serviço de
                qualquer forma que seja contrária aos termos e condições deste
                Contrato; ou <span className="font-bold">(xii)</span> utilizar o
                Serviço para qualquer finalidade ilícita ou em contravenção com
                a legislação aplicável.
              </li>
              <li>
                <span className="font-bold">Dispositivos eletrónicos: </span>A
                quantidade de dispositivos eletrónicos em que um utilizador
                possa utilizar a Plataforma em simultâneo varia consoante a
                Opção de Assinatura escolhida pelo Cliente. Em todo o caso, a
                Plataforma dispõe de uma funcionalidade para limitação de
                utilizações simultâneas dos utilizadores a partir de diferentes
                dispositivos.
                <ol>
                  <li>
                    Dependendo da capacidade do Serviço, a Fortimy reserva-se o
                    direito de apenas conceder acesso às contas do Cliente a
                    partir de um dispositivo de cada vez, com um intervalo de
                    tempo razoável para mudar de dispositivo.
                  </li>
                  <li>
                    O Cliente deverá manter o controlo sobre os dispositivos que
                    são utilizados para aceder à Plataforma e manter a
                    palavra-chave ou detalhes associados à conta privados e
                    seguros, por forma a evitar acessos não autorizados.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li id="pagamento-e-condicoes-de-pagamento" className="text-primary text-2xl md:text-3xl font-bold">
            <h2 className="mb-4">Pagamento e Condições de Pagamento</h2>
            <ol className="list-decimal list-inside text-black text-base font-normal">
              <li>
                <span className="font-bold">Pagamento: </span>Como contrapartida
                pelos Serviços prestados ao abrigo do presente Contrato, o
                Cliente pagará à Fortimy os preços estabelecidos na Opção de
                Assinatura, nas datas de vencimento previstas, antes da data de
                início dos Serviços. Os pedidos não são canceláveis e uma vez
                pagos não serão reembolsáveis.
              </li>
              <li>
                <span className="font-bold">Custos Adicionais: </span>
                Sem prejuízo de outras circunstâncias expressamente previstas no
                Contrato que o determinem, em caso de utilização do Serviço pelo
                Cliente que exceda a capacidade estabelecida na Opção de
                Assinatura aplicável, a Fortimy reserva-se o direito de cobrar
                ao Cliente o valor proporcional à capacidade excedida, de acordo
                com os preços estabelecidos nos tarifários dos serviços à data
                em vigor previstos na respetiva Opção de Assinatura.
              </li>
              <li>
                <span className="font-bold">Alterações de Preço: </span> A
                Fortimy reserva-se o direito de alterar os Pagamentos ou
                encargos aplicáveis e definir novos encargos e Pagamentos no
                final do Termo de Serviço inicial ou renovação. A Fortimy
                notificará o Cliente da alteração em questão com 30 (trinta)
                dias de antecedência face à data em que a mesma deva entrar em
                vigor. A referida notificação poderá ser feita por email. O
                Cliente poderá endereçar quaisquer questões relacionadas com
                alterações de preços ao departamento de suporte ao cliente da
                Fortimy.
              </li>
              <li>
                <span className="font-bold">Pagamentos e Faturação: </span>O
                Cliente deverá fornecer à Fortimy comprovativos atualizados dos
                dados de método de pagamento utilizado, ou documentação
                alternativa que, de forma razoável, sirva o mesmo fim e seja
                aceite pela Fortimy. Ao fornecer à Fortimy informações relativas
                a cartões de crédito / débito ou contas de débito direto, o
                Cliente autoriza a Fortimy a cobrar os preços acordados na Opção
                de Assinatura, por via desses mesmos métodos, durante o prazo
                inicial da assinatura e suas renovações, nos termos da cláusula
                “Renovação” abaixo. Os pagamentos deverão ser efetuados até à
                data de vencimento das respetivas faturas, com periodicidade
                anual ou outra que seja acordada. O Cliente deverá fornecer
                dados completos e atualizados à Fortimy, tanto para cobrança dos
                pagamentos de cobrança como para contactos relativamente ao
                assunto, bem como notificar a Fortimy de quaisquer alterações
                relevantes sobre esses dados.
              </li>
              <li>
                <span className="font-bold">Atrasos no Pagamento: </span>Em caso
                de mora no pagamento pelo Cliente de valores faturados pela
                Fortimy face à respetiva data de vencimento, sem prejuízo de
                outros direitos ou recursos legais à disposição da Fortimy,{" "}
                <span className="font-bold">(i) </span>
                será devida uma penalidade mensal em valor corresponde a 1,5%
                sobre os montantes vencidos e não pagos, ou a aplicação de juros
                comerciais de mora à taxa legal que na altura vigore, consoante
                a que seja mais baixa, e/ ou{" "}
                <span className="font-bold">(ii)</span> reservando-se a Fortimy
                a condicionar futuras renovações de assinatura a condições de
                pagamento de prazo inferior ao estipulado na cláusula 4.4,
                “Pagamentos e Faturação”, acima.
              </li>
              <li>
                <span className="font-bold">
                  Suspensão dos Serviços e Exigibilidade das prestações:{" "}
                </span>
                Caso o Cliente não liquide, no prazo de 7 (sete) dias de
                calendário após a notificação para o efeito (nos termos da
                cláusula 14.6, “Notificação”, abaixo), os montantes em mora ao
                abrigo do presente Contrato, a Fortimy poderá exigir do Cliente
                o pagamento imediato dos montantes devidos ao abrigo de tais
                contratos, por forma a que essas obrigações se tornem
                imediatamente exigíveis e suspender os Serviços até pagamento
                integral dos montantes em questão.
              </li>
              <li>
                <span className="font-bold">
                  Diferendos relativos a Pagamentos:{" "}
                </span>
                Em caso de diferendo relativamente ao valor de Pagamentos
                devido, caso o Cliente se encontre em negociações de boa-fé, e
                de forma razoável e colaborativa, relativamente ao acerto dos
                valores de pagamentos aplicáveis, a Fortimy poderá renunciar ao
                exercício dos seus direitos previstos na cláusula 4.5, “Atrasos
                no Pagamento”, acima.
              </li>
              <li>
                <span className="font-bold">Moeda e Impostos: </span>Todos os
                montantes e Preços declarados ou mencionados neste Contrato
                deverão ser pagos em Euros. Os Preços faturados não incluem,
                além de IVA, outros impostos aplicáveis às faturas da Fortimy
                (exceto no caso de cobrança reversa de IVA da UE ou de clientes
                não pertencentes à UE).
              </li>
            </ol>
          </li>
          <li id="atualizacao-de-termos-e-condicoes" className="text-primary text-2xl md:text-3xl font-bold">
            <h2 className="mb-4">Atualização dos Termos e Condições</h2>
            <ol className="list-decimal list-inside text-black text-base font-normal">
              <li>
                A Fortimy reserva-se o direito de, periodicamente e em qualquer
                momento, proceder a alterações ou aditamentos de disposições
                nestes Termos e Condições, bem como a alterações na Plataforma e
                nos Serviços da Fortimy, com o propósito de refletir alterações
                legislativas que sejam aplicáveis ou de melhoria dos Serviços.
              </li>
              <li>
                A Fortimy notificará o Cliente das alterações pelo menos 30 dias
                antes da sua entrada em vigor e publicará a versão atualizada
                dos Termos e Condições atualizados com as alterações em questão
                em{" "}
                <a
                  href="https://fortimy.com/"
                  target="_blank"
                  className="text-primary"
                  rel="noreferrer"
                >
                  www.fortimy.com
                </a>
                , com a data da “última atualização” assinalada.
              </li>
              <li>
                Caso as alterações aos Termos e Condições comportem uma{" "}
                <span className="font-bold">(i) </span>
                alteração das características dos Serviços cujo impacto e
                efeitos esperados sejam não correspondam à vontade inicial de
                contratar por parte do Cliente, ou{" "}
                <span className="font-bold">(ii)</span> uma alteração
                significativa de Preços, o Cliente, caso não pretenda continuar
                vinculado aos Serviços, poderá, com esse fundamento, denunciar o
                contrato (estes Termos e Condições) com a Fortimy no prazo
                máximo de 30 dias após recepção da notificação da Fortimy sobre
                a atualização dos Termos e Condições.
              </li>
              <li>
                No caso de as alterações aos Termos e Condições não cumprirem os
                requisitos detalhados na cláusula 5.3, serão considerados
                aceites pelo Cliente se o Cliente não declarar o contrário antes
                de tais alterações entrarem em vigor. Nesse caso, a não
                aceitação implicará a rescisão do Contrato nos termos da
                cláusula 6.4.
              </li>
            </ol>
          </li>
          <li id="prazo-e-rescisao" className="text-primary text-2xl md:text-3xl font-bold">
            <h2 className="mb-4">Prazo e Cessação</h2>
            <ol className="list-decimal list-inside text-black text-base font-normal">
              <li>
                <span className="font-bold">Prazo: </span>
                Este Contrato inicia os seus efeitos na Data Efetiva e
                manter-se-á em vigor pelo prazo definido na Opção de Assinatura.
              </li>
              <li>
                <span className="font-bold">Renovação: </span>O Contrato
                renovar-se-á automaticamente no prazo de duração previsto na
                Opção de Assinatura aplicável, exceto se denunciado por qualquer
                das Partes com uma antecedência mínima de 30 (trinta) dias do
                final do período de Serviço relevante, por forma a impedir a sua
                renovação.
              </li>
              <li>
                <span className="font-bold">Resolução por incumprimento: </span>
                Qualquer das Partes poderá a todo o tempo resolver, com efeitos
                imediatos, o presente Contrato, mediante notificação por
                escrito, caso a outra Parte incumpra materialmente qualquer
                obrigação prevista pelo presente Contrato e quando notificada
                para o efeito, não sane o incumprimento em questão no prazo de
                30 (trinta) dias após a notificação por escrito para o efeito. A
                resolução do presente Contrato pela Fortimy nos termos da
                presente cláusula por incumprimento do Cliente, sem prejuízo de
                outros direitos que lhe assistem, não desobriga o Cliente do
                pagamento de todas as prestações vencidas e vincendas, devidas
                até ao final do prazo de vigência inicialmente definido para o
                contrato.
              </li>
              <li>
                <span className="font-bold">
                  Resolução por iniciativa do Cliente:{" "}
                </span>
                Sem prejuízo do disposto nos números anteriores, o Cliente
                poderá fazer cessar o Contrato a todo tempo, com efeitos
                antecipados, sem necessidade de fundamentação, ficando no
                entanto obrigado ao pagamento do Preço total considerado o
                período contratual em curso.
              </li>
            </ol>
          </li>
          <li id="confidencialidade" className="text-primary text-2xl md:text-3xl font-bold">
            <h2 className="mb-4">Confidencialidade</h2>
            <ol className="list-decimal list-inside text-black text-base font-normal">
              <li>
                <span className="font-bold">Utilização e Divulgação: </span>
                Durante a vigência do Contrato e pelo prazo subsequente de 5
                (cinco) anos, as Partes comprometem-se a manter sob sigilo e a
                não utilizar Informação Confidencial da outra Parte para
                quaisquer finalidades não relacionadas com o presente Contrato,
                bem como não a divulgar a terceiros (com exceção de
                trabalhadores ou subcontratados da Parte em questão que tenham
                necessidade de conhecer a Informação Confidencial, ou estejam
                sujeitos a obrigações de confidencialidade, no mínimo,
                equivalentes às vertidas neste documento). As Partes adotarão as
                medidas razoavelmente necessárias para garantir que as
                Informações Confidenciais não sejam divulgadas ou acedidas pelos
                seus trabalhadores, subcontratados ou agentes, em termos que
                possam violar os presentes Termos e Condições.
              </li>
              <li>
                <span className="font-bold">Divulgações Autorizadas: </span>
                Qualquer uma das Partes poderá utilizar Informações
                Confidenciais da outra Parte{" "}
                <span className="font-bold">(i)</span> em resposta a pedidos
                (válido) organismos judiciais, regulatórios ou outros no
                exercício de poderes públicos,{" "}
                <span className="font-bold">(ii)</span> nos casos expressamente
                previstos por lei ou <span className="font-bold">(iii)</span> na
                medida do necessário para estabelecer os direitos de qualquer
                uma das Partes sob este Contrato, desde que notifique
                prontamente a outra Parte de tal solicitação e permita a
                oportunidade de objetar ou requerer medidas judiciais para
                proteção da informação.
              </li>
              <li>
                <span className="font-bold">
                  Informações Não Confidenciais:{" "}
                </span>
                Não se consideram Informações Confidenciais as que:{" "}
                <span className="font-bold">(i)</span> sejam ou se tornem de
                domínio público, sem ser em resultado de ato ou omissão da Parte
                receptora; <span className="font-bold">(ii)</span> se encontrem
                legitimamente na posse da Parte receptora, e que não tenham sido
                direta ou indiretamente recebidas da Parte divulgadora pela
                Parte receptora <span className="font-bold">(iii)</span> sejam
                licitamente reveladas à Parte receptora por terceiro não sujeito
                sem restrições à sua divulgação; ou{" "}
                <span className="font-bold">(iv)</span> sejam geradas pela Parte
                receptora, de forma independente, sem ser por via de acesso às
                Informações Confidenciais.
              </li>
              <li>
                <span className="font-bold">Destruição ou Devolução: </span>
                Exceto na medida do previsto em contrário nos termos do presente
                Contrato, a Parte receptora de Informação Confidencial deverá,
                imediatamente após solicitação para o efeito da Parte
                divulgadora, destruir (e declará-lo por escrito) ou devolver-lhe
                todos os documentos e quaisquer outros suportes de Informações
                Confidenciais, incluindo todas cópias ou extratos das mesmas.
              </li>
            </ol>
          </li>
          <li id="propriedade-intelectual-propriedade-e-titulo" className="text-primary text-2xl md:text-3xl font-bold">
            <h2 className="mb-4">
              Propriedade Intelectual, Propriedade e Título
            </h2>
            <ol className="list-decimal list-inside text-black text-base font-normal">
              <li>
                <span className="font-bold">Propriedade e Título: </span>O
                Cliente é titular de todos os direitos e faculdades relativos
                aos Dados do Cliente, na medida do aplicável e permitido por lei
                e desde que tal não infrinja quaisquer direitos de terceiros, a
                título de Direitos de Propriedade Intelectual (nomeadamente de
                Direitos de Autor) e Industrial. A Fortimy manter-se-á titular
                de todos os direitos e faculdades referentes{" "}
                <span className="font-bold">(i)</span> ao Serviço e à
                Plataforma, incluindo todas as melhorias, updates ou alterações
                de que sejam objeto; <span className="font-bold">(ii)</span> a
                quaisquer software, aplicações, invenções ou outra tecnologia
                desenvolvida em conexão com o Serviço, Plataforma ou suporte de
                informação da Fortimy; <span className="font-bold">(iii) </span>
                a todos os direitos de autor, segredos de negócio, patentes,
                marcas e quaisquer outros direitos de propriedade intelectual e
                industrial, incluindo no que toca a pedidos de registo,
                renovações e extensões de tais direitos. Exceto na medida do
                expressamente previsto pelo presente Contrato, não são
                atribuídos ao Cliente quaisquer direitos sobre as marcas
                registadas da Fortimy, nem qualquer tipo de licenças sobre as
                mesmas.
              </li>
              <li>
                <span className="font-bold">
                  Respeito por Direitos de Propriedade Intelectual e Industrial
                  de Terceiros:{" "}
                </span>
                O Cliente compromete-se a utilizar a informação e dados que lhe
                sejam facultados pela Fortimy ao abrigo dos serviços prestados
                no presente Contrato e exclusivamente ao abrigo deste, sendo o
                responsável exclusivo pela utilização de faça de dados,
                informações, documentos ou obras de terceiros, comprometendo-se
                a respeitar Direitos de Propriedade Intelectual e Industrial dos
                mesmos.
              </li>
              <li>
                <span className="font-bold">Utilização de dados: </span>A
                Fortimy poderá, dentro dos limites legalmente previstos,
                recolher e analisar dados e outras informações relacionadas com
                o fornecimento, utilização, e desempenho de vários aspetos da
                Plataforma, sistemas e tecnologias relacionadas (incluindo
                informações relativas a Dados do Cliente e dados derivados dos
                mesmos). A Fortimy poderá ainda, durante e após a vigência dos
                presentes Termos e Condições{" "}
                <span className="font-bold">(i)</span> utilizar as informações e
                dados recolhidos desenvolver melhorias nos Serviços e para
                outros desenvolvimentos, diagnósticos e correções relacionadas
                com o Serviço e outras ofertas da Empresa, e{" "}
                <span className="font-bold">(ii)</span> divulgar esses dados
                para finalidades relacionadas com o negócio, mas apenas de forma
                agregada ou, por outra forma, anonimizados.
              </li>
            </ol>
          </li>
          <li id="protecao-de-dados" className="text-primary text-2xl md:text-3xl font-bold">
            <h2 className="mb-4">Proteção de Dados</h2>
            <ol className="list-decimal list-inside text-black text-base font-normal">
              <li>
                A recolha e tratamento de dados subsequentes pela Fortimy é
                feita ao abrigo e nos termos e do Regulamento Geral da UE
                2016/679 (Regulamento Geral de Proteção de Dados (“RGPD”)) e em
                cumprimento dos direitos do Cliente sob o RGPD.
              </li>
            </ol>
          </li>
          <li id="garantia-e-isencao-de-responsabilidade" className="text-primary text-2xl md:text-3xl font-bold">
            <h2 className="mb-4">Garantia e Isenção de Responsabilidade</h2>
            <ol className="list-decimal list-inside text-black text-base font-normal">
              <li>
                A Fortimy não garante que os produtos ou serviços contratados
                atenderão às exigências do Cliente, são aptos a finalidades
                determinadas, estarão livres de vírus ou que operarão de modo
                ininterrupto ou livre de quaisquer erros.
              </li>
              <li>
                O Cliente reconhece que o Serviço poderá estar temporariamente
                indisponível para manutenção agendada ou para manutenção de
                emergência não programada. Sempre que possível, a Fortimy
                enviará um aviso prévio por relativo às interrupções programadas
                do Serviço.
              </li>
            </ol>
          </li>
          <li id="indemnizacao" className="text-primary text-2xl md:text-3xl font-bold">
            <h2 className="mb-4">Indemnização</h2>
            <ol className="list-decimal list-inside text-black text-base font-normal">
              <li>
                A Fortimy não atribuirá ao Cliente quaisquer responsabilidades
                perante terceiros resultantes da violação de direitos de
                propriedade industrial ou direitos de autor ou apropriação
                indevida de segredos de negócio da titularidade de terceiros por
                parte da Fortimy na sua oferta de Serviços que possam vir a
                exigidas ao Cliente, desde que a Fortimy seja prontamente
                notificada pelo Cliente quanto a suspeitas, queixas e todos os
                detalhes de processos judiciais ou procedimentos relacionados
                com as infrações de propriedade intelectual em questão e lhe
                seja concedida, tempestiva e razoavelmente, oportunidade de
                assumir o controlo exclusivo da sua defesa; a Fortimy não ficará
                vinculada por qualquer acordo judicial ou de outra natureza que
                não aprove por escrito. O disposto acima não será aplicável
                quando as alegações digam respeito a partes ou componentes do
                Serviço <span className="font-bold">(i)</span> não fornecidos
                pela Fortimy, <span className="font-bold">(ii) </span>
                desenvolvidos total ou parcialmente de acordo com as
                especificações do Cliente e a seu pedido quando sobre este
                recaia a titularidade dos direitos de propriedade intelectual
                sobre os desenvolvimentos,{" "}
                <span className="font-bold">(iii)</span> que sejam modificados
                após a entrega pela Empresa,{" "}
                <span className="font-bold">(iv) </span>
                conjugados com outros produtos, processos ou materiais do
                Cliente <span className="font-bold">(v)</span> quando o Cliente
                persista com a conduta alegadamente infratora, mesmo após
                notificado ou informado de comportamentos cuja adotação teria
                evitado a concretização da alegada violação de direitos, ou{" "}
                <span className="font-bold">(vi)</span> quando a utilização do
                Serviço pelo Cliente não se encontre em estreita conformidade
                com o presente Acordo. Caso, na sequência da instauração de
                processo com o referido objeto, o tribunal competente considere
                que o Fortimy ou o próprio Serviço em si, viola direitos de
                terceiros, a Fortimy poderá, a seu critério e expensas (a)
                substituir ou modificar o Serviço para não que a violação de
                direitos em questão não ocorra, na medida em que tal não altere
                substancialmente as funcionalidades e funções em causa; (b)
                obter junto do Cliente uma licença para continuar a usar o
                Serviço; ou (c) caso nenhuma das duas opções anteriores seja
                viável do ponto de vista comercial, resolver o Contrato em vigor
                e reembolsar o Cliente de quantias pagas até à data pelos
                Serviços não prestados.
              </li>
            </ol>
          </li>
          <li id="limitacoes-e-exclusoes-de-responsabilidade" className="text-primary text-2xl md:text-3xl font-bold">
            <h2 className="mb-4">Limitações e exclusões de responsabilidade</h2>
            <ol className="list-decimal list-inside text-black text-base font-normal">
              <li>
                A responsabilidade global e cumulativa da Fortimy pelos danos
                decorrentes do incumprimento ou incumprimento defeituoso destes
                Termos e Condições está limitada ao valor dos serviços pagos
                para o ano de ocorrência dos factos geradores de
                responsabilidade.
              </li>
              <li>
                A Fortimy não será, em qualquer caso, responsável por qualquer
                perda de produção, perda de lucros ou de contratos, perda de
                negócios ou de receitas, perda de bom nome ou reputação, ou por
                qualquer perda de dados, dano indireto, custo ou despesa que
                possam ocorrer.
              </li>
            </ol>
          </li>
          <li id="forca-maior" className="text-primary text-2xl md:text-3xl font-bold">
            <h2 className="mb-4">Força Maior</h2>
            <ol className="list-decimal list-inside text-black text-base font-normal">
              <li>
                Para efeitos do presente Contrato, considera-se um evento de
                força maior: greve, incêndio, inundação, terramoto, atos
                legislativos ou administrativos de natureza pública, falhas de
                serviço de entidades terceiras fornecedoras, atrasos ou quebras
                de serviços de fornecedores de serviços de Internet, ataques que
                impeçam o funcionamento dos serviços de Internet ou outros
                fatores que determinem a incapacidade de cumprimento das
                obrigações das Partes e que se encontrem razoavelmente fora do
                controle da Parte incumpridora e não resultem conduta dolosa nem
                negligência, seja a que título for, da Parte incumpridora, seja
                por ação ou omissão.
              </li>
            </ol>
          </li>
          <li id="diversos" className="text-primary text-2xl md:text-3xl font-bold">
            <h2 className="mb-4">Diversos</h2>
            <ol className="list-decimal list-inside text-black text-base font-normal">
              <li>
                <span className="font-bold">
                  Natureza da relação jurídica entre as Partes:{" "}
                </span>
                As Partes são contraentes independentes e, por via do presente
                Contrato, não constituem entre si qualquer relação de agência,
                laboral, de joint ventures, nem de mandato que permita a uma
                agir por contra da outra na celebração de contratos ou outro
                tipo de obrigações. As Partes declaram e garantem que não
                poderão arguir a existência, expressa ou implícita de uma
                relação de natureza jurídica como as acima referidas.
              </li>
              <li>
                <span className="font-bold">Cessão: </span>
                Nenhuma das Partes poderá ceder a sua posição contratual no
                presente Contrato, sem consentimento prévio e por escrito da
                outra Parte, cuja concessão as Partes se comprometem desde já a
                garantir de forma razoável. A cessão da posição contratual por
                uma parte sem o consentimento da outra será inválida.. Em caso
                de cessão da posição contratual de qualquer das Partes, o
                presente Contrato, manter-se-á, integral ou, na parte relevante,
                parcialmente válido e em vigor vinculando a nova parte
                cessionária, e os seus titulares, administradores e
                representantes legais.
              </li>
              <li>
                <span className="font-bold">
                  Efeitos na nulidade ou anulabilidade:{" "}
                </span>
                Se, por qualquer motivo, um tribunal competente considerar, no
                todo ou em parte, inválidas ou ineficazes, certas disposições
                deste Contrato, deverão as mesmas, na extensão máxima permitida
                por lei, ter-se como válidas e eficazes, e não determinando a
                invalidade de todo o Contrato, salvo quando se mostre que sem, a
                parte viciada, as Partes não teriam tido intenção de celebrar o
                Contrato.
              </li>
              <li>
                <span className="font-bold">Não-Renúncia de Direitos: </span>O
                incumprimento ou cumprimento defeituoso de alguma das
                disposições deste Contrato que, a dado momento, possa ocorrer
                por parte de qualquer das Partes, bem como a circunstância de a
                Parte não faltosa não notificar a Parte faltosa para o
                cumprimento ou para sanação do mesmo, não poderão ser
                interpretados como renúncia aos direitos que assistam à Parte
                não faltosa, quer nessa mesma ocasião, quer de futuro.
              </li>
              <li>
                <span className="font-bold">Contrato Integral: </span>
                Os presentes Termos e Condições constituem o acordo integral
                entre as Partes relativamente ao seu objeto, substituindo
                quaisquer negociações ou acordos anteriores ou contemporâneos,
                escritos ou orais, referentes aos Serviços.
              </li>
              <li>
                <span className="font-bold">Notificação: </span>
                Quaisquer comunicações efetuadas ao abrigo deste Contrato
                deverão ser remetidas por email para os endereços eletrónicos
                indicados pelas partes, ou por correio registado com aviso de
                recepção.
              </li>
              <li>
                <span className="font-bold">Lei e Foro: </span>O presente
                Contrato é regido por lei Portuguesa. Em caso de litígio
                decorrente ou relacionado com este Contrato, serão competentes
                os tribunais do foro de Cascais, Portugal, com expressa renúncia
                a quaisquer outros.
              </li>
            </ol>
          </li>
        </ol>
      </section>
    </>
  );
};

export default TermsConditions;
