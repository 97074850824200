import React, { useState, useEffect } from 'react';
import PhoneInput from "react-phone-input-2";
import InputMask from "react-input-mask";
import { useAlert } from "../../contexts/AlertContext";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../UI/ErrorMessage";
import Modal from "../Modals/Modal";
import PrivacyPolicy from "../Modals/PrivacyPolicy";
import TermsConditions from "../Modals/TermsConditions";
import RequestDemoSuccess from "../Modals/RequestDemoSuccess";
import axios from "../Config/axios";

const Demo = () => {

    const { toggleLoader, triggerPopup } = useAlert();
    const { t } = useTranslation();

	const [isPolicyOpen, setIsPolicyOpen] = useState(false);
	const [isTermsOpen, setIsTermsOpen] = useState(false);
    const [isRequestDemoOpen, setIsRequestDemoOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [phoneIndex, setPhoneIndex] = useState("+351");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [company, setCompany] = useState("");
	const [companyRole, setCompanyRole] = useState("");
    const [privacyPolicy, setPrivacyPolicy] = useState(false);
	const [termsConditions, setTermsConditions] = useState(false);
    const [errors, setErrors] = useState({});

    const submitForm = async () => {
        setIsLoading(true);
        await axios.get("/sanctum/csrf-cookie");
        await axios
            .post("/api/request-demo", {
                first_name: firstName,
                last_name: lastName,
                phone_index: phoneIndex,
                phone_number: phoneNumber,
                email: email,
                company: company,
                company_role: companyRole,
                privacy_policy: privacyPolicy,
                terms_conditions: termsConditions,
            })
            .then((response) => {
                // triggerPopup('Popups.changes_saved');
                setIsRequestDemoOpen(true)
            })
            .catch((e) => {
                setErrors(e.response.data.errors);
                // triggerPopup('Popups.something_went_wrong');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        toggleLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    return (
        <>
            <Modal isOpen={isRequestDemoOpen}>
				<RequestDemoSuccess
					onClose={() => {
						setIsRequestDemoOpen(false);
						window.location.href = "/";
					}}
				/>
			</Modal>
            <Modal isOpen={isPolicyOpen}>
				<PrivacyPolicy
					onClose={() => {
						setIsPolicyOpen(false);
					}}
				/>
			</Modal>

			<Modal isOpen={isTermsOpen}>
				<TermsConditions
					onClose={() => {
						setIsTermsOpen(false);
					}}
				/>
			</Modal>

            <section className='my-20 md:my-10'>
                <h1 className='text-2xl lg:text-3xl font-bold text-center mb-5'>{t("RequestDemo.title")}</h1>
                <form  onSubmit={e => { e.preventDefault()}} className="max-w-sm px-5 md:px-0 mx-auto">
                    <div className='my-4'>
                        <input
                            onChange={(e) => {
                                setFirstName(e.target.value);
                            }}
                            value={firstName}
                            className="w-full p-2 border-[1px] shadow-lg rounded-md border-fifth lg:text-lg placeholder:text-grey"
                            type="text"
                            placeholder={t("Pricing.Step4BusinessInput1FirstName")}
                        />
                        <ErrorMessage errors={errors.first_name} />
                    </div>
                    <div className='my-4'>
                        <input
                            onChange={(e) => {
                                setLastName(e.target.value);
                            }}
                            value={lastName}
                            className="w-full p-2 border-[1px] shadow-lg rounded-md border-fifth lg:text-lg placeholder:text-grey"
                            type="text"
                            placeholder={t("Pricing.Step4BusinessInput2LastName")}
                        />
                        <ErrorMessage errors={errors.last_name} />
                    </div>
                    

                    <div className="flex my-2">
                        <PhoneInput
                            country={"pt"}
                            value={phoneIndex}
                            onChange={(code) => setPhoneIndex(code)}
                            containerClass='shadow-md !w-2/5 !md:w-1/5'
                            inputStyle={{ height: "100%", width: "100%" }}
                            enableSearch={true}
                            disableDropdown={false}
                            inputProps={{ readOnly: true }}
                        />
                        <InputMask
                            mask="999 999 999"
                            onChange={(e) => {
                                setPhoneNumber(e.target.value);
                            }}
                            value={phoneNumber}
                            className="ml-2 w-2/3 p-2 border-[1px] shadow-lg rounded-md border-fifth lg:text-lg placeholder:text-grey"
                            type="text"
                            placeholder={t("Pricing.Step4BusinessInput5Phone")}
                        />
                    </div>
                    <ErrorMessage errors={errors.phone_number} />
                    <div>
                        <input
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            value={email}
                            className="my-2 p-2 border-[1px] shadow-lg rounded-md border-fifth lg:text-lg placeholder:text-grey w-full"
                            type="email"
                            placeholder={t("Pricing.Step4BusinessInput6Email")}
                        />
                        <ErrorMessage errors={errors.email} />
                    </div>
                    <div>
                        <input
                            onChange={(e) => {
                                setCompany(e.target.value);
                            }}
                            value={company}
                            className="my-2 p-2 border-[1px] shadow-lg rounded-md border-fifth lg:text-lg placeholder:text-grey w-full"
                            type="text"
                            placeholder={t("RequestDemo.company")}
                        />
                        <ErrorMessage errors={errors.company} />
                    </div>
                    <div>
                        <input
                            onChange={(e) => {
                                setCompanyRole(e.target.value);
                            }}
                            value={companyRole}
                            className="my-2 p-2 border-[1px] shadow-lg rounded-md border-fifth lg:text-lg placeholder:text-grey w-full"
                            type="text"
                            placeholder={t("RequestDemo.company_role")}
                        />
                        <ErrorMessage errors={errors.company_role} />
                    </div>

                    <div className="flex flex-col xl:col-start-5 xl:col-end-7 lg:col-start-4 lg:col-end-8 md:col-start-3 md:col-end-9 mt-5">
                        <div className="flex flex-col justify-start my-4 space-y-2">
                            <label>
                                <input
                                    onChange={(e) => {
                                        setPrivacyPolicy(e.target.checked);
                                    }}
                                    checked={privacyPolicy === true ? "checked" : null}
                                    className="mr-2 accent-black"
                                    type="checkbox"
                                />
                                {t("Pricing.Step4BusinessInput9")}
                                <button
                                    className="text-primary underline"
                                    onClick={() => setIsPolicyOpen(true)}
                                >
                                    {t("Pricing.Step4BusinessInput9Btn")}
                                </button>
                            </label>
                            <ErrorMessage errors={errors.privacy_policy} />
                            <label className="flex items-start">
                                <input
                                    onChange={(e) => {
                                        setTermsConditions(e.target.checked);
                                    }}
                                    checked={termsConditions === true ? "checked" : null}
                                    className="mr-2 accent-black"
                                    type="checkbox"
                                />
                                <p className="leading-tight">
                                    {t("Pricing.Step4BusinessInput10Pt1")}
                                    <button
                                        type="button"
                                        className="text-primary underline"
                                        onClick={() => setIsTermsOpen(true)}
                                    >
                                        {t("Pricing.Step4BusinessInput10Btn")}
                                    </button>
                                    {t("Pricing.Step4BusinessInput10Pt2")}
                                </p>
                            </label>
                            <ErrorMessage errors={errors.terms_conditions} />
                        </div>
                    </div>

                    <button
                        disabled={!privacyPolicy || !termsConditions || isLoading}
                        type="submit"
                        className="text-white btn-primary text-lg lg:text-2xl font-bold px-12 py-2 rounded-lg shadow-lg w-full"
                        onClick={submitForm}
                    >
                        {t("RequestDemo.submit_btn")}
                    </button>
                </form>
            </section>
        </>
    )
}

export default Demo