import { useEffect, useState, useRef } from "react";
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactFlagsSelect from "react-flags-select";
import InputMask from "react-input-mask";
import Select from "../../Inputs/Select.js";

const ClientData = ({ formData, formOptions, onSubmit, isIndividual = null }) => {

    const customSelect = {
        control: (provided, state) => ({
            ...provided,
            borderWidth: "2px",
            borderColor: "#e5e7eb",
            width: "100%",
            borderRadius: "0.375rem",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
        }),
    };

    const { t } = useTranslation();
    const { auth } = useAuth();

    const [firstName, setFirstName] = useState(formData?.firstName ?? "");
    const [lastName, setLastName] = useState(formData?.lastName ?? "");
    const [nationality, setNationality] = useState(formData?.nationality ?? "PT");
    const [phoneIndex, setPhoneIndex] = useState(formData?.phoneIndex ?? "+351");
    const [phoneNumber, setPhoneNumber] = useState(formData?.phoneNumber ?? "");

    const [selectedBuyerType, setSelectedBuyerType] = useState(null);
    const [selectedUrgency, setSelectedUrgency] = useState(null);
    const [selectedInvestmentType, setSelectedInvestmentType] = useState(null);

    const [showPhone, setShowPhone] = useState(formData?.showPhone ?? true);
    const [showEmail, setShowEmail] = useState(formData?.showEmail ?? true);

    const handleSubmit = () => {
        onSubmit({
            firstName: firstName,
            lastName: lastName,
            nationality: nationality,
            phoneIndex: phoneIndex,
            phoneNumber: phoneNumber,
            urgency: selectedUrgency,
            investmentType: selectedInvestmentType,
            buyerType: selectedBuyerType,
            showPhone,
            showEmail
        })
    }
    
    useEffect(() => {
        if (formOptions.buyerTypes?.length > 0) {
            setSelectedBuyerType(formData?.buyerType ?? formOptions.buyerTypes[0]);
        }

        if (formOptions.urgencies?.length > 0) {
            setSelectedUrgency(formData?.urgency ?? formOptions.urgencies[0]);
        }

        if (formOptions.investmentTypes?.length > 0) {
            setSelectedInvestmentType(formData?.investmentType ?? formOptions.investmentTypes[0]);
        }

        if (auth?.is_individual) {
            setFirstName((auth?.first_name).slice(0,3))
            setLastName((auth?.last_name).slice(0,3))
            setNationality(auth?.nationality)
            setPhoneIndex((auth?.phone).slice(0,4))
            setPhoneNumber((auth?.phone).slice(-4))
        }
    }, [formOptions])
    
    return (
        <>
            <h1 className="text-center text-2xl md:text-4xl font-bold mb-2 md:mb-5">
                {t("Dashboard.my_requests.make_a")}{" "}
                {t("Dashboard.my_requests.request")}
            </h1>
            { (!isIndividual ?? !auth?.is_individual) &&
                <h2 className="text-primary md:text-xl font-bold text-center">
                    { t("Dashboard.my_requests.what_is_client_data") }
                </h2>
            }

            <form className="w-[90vw] md:w-[50vw] lg:w-[40vw] 2xl:w-[25vw] mt-4 md:mt-10 lg:mt-14">
                { (!isIndividual ?? !auth?.is_individual) &&
                    <>
                        <div className="grid grid-cols-2 gap-x-2 md:gap-10 my-2">
                            <div>
                                <p className="mb-2 md:text-lg">
                                    {t("Dashboard.my_requests.first_name")}
                                </p>
                                <input
                                    onChange={(e) => {
                                        ((firstName.length < 3 && e.target.value < 4) ||
                                        e.target.value.length < 4) &&
                                        setFirstName(e.target.value.replace(/[0-9]/g, ""));
                                    }}
                                    value={firstName}
                                    type="text"
                                    className="w-full border-2 rounded-md md:py-4 py-2 col-span-1 px-2 placeholder:text-black/60 lg:text-xl focus:outline-none"
                                    placeholder={t("Dashboard.my_requests.first_name")}
                                    disabled={(isIndividual ?? auth?.is_individual)}
                                />
                                <p className="text-xs md:text-sm text-primary mt-1">
                                    {t("Dashboard.my_requests.first_three_letters")}
                                </p>
                            </div>

                            <div>
                                <p className="mb-2 md:text-lg">
                                    {t("Dashboard.my_requests.last_name")}
                                </p>
                                <input
                                    onChange={(e) => {
                                        ((lastName.length < 3 && e.target.value < 4) ||
                                        e.target.value.length < 4) &&
                                        setLastName(e.target.value.replace(/[0-9]/g, ""));
                                    }}
                                    value={lastName}
                                    type="text"
                                    className="w-full border-2 rounded-md md:py-4 py-2 col-span-1 px-2 placeholder:text-black/60 lg:text-xl focus:outline-none"
                                    placeholder={t("Dashboard.my_requests.last_name")}
                                    disabled={(isIndividual ?? auth?.is_individual)}
                                />
                                <p className="text-xs md:text-sm text-primary mt-1">
                                    {t("Dashboard.my_requests.first_three_letters")}
                                </p>
                            </div>
                        </div>
                        <div className="mt-2">
                            <p className="mb-2 md:text-lg">
                                {t("Dashboard.my_requests.nationality")}
                            </p>
                            <ReactFlagsSelect
                                selected={nationality}
                                onSelect={(code) => setNationality(code)}
                                searchable={true}
                                fullHeight={true}
                                className="!text-sm md:!text-base lg:!text-xl"
                                selectButtonClassName="!text-sm md:!text-base lg:!text-xl"
                                disabled={(isIndividual ?? auth?.is_individual)}
                            />
                        </div>
                        <div className="mt-2">
                            <p className="mb-2 md:text-lg">
                                {t("Dashboard.my_requests.phone")}
                            </p>
                            <div className="grid grid-cols-2 md:gap-x-10 gap-x-2 my-2">
                                <PhoneInput
                                    country={nationality.toLowerCase()}
                                    value={phoneIndex}
                                    onChange={() => setPhoneIndex(phoneIndex)}
                                    containerClass="h-full flex"
                                    inputClass="h-full flex"
                                    inputStyle={{ height: "100%", width: "100%" }}
                                    searchClass="h-full flex"
                                    buttonClass="h-full"
                                    enableSearch={(isIndividual ?? auth?.is_individual)}
                                    inputProps={{ readOnly: true }}
                                    disableDropdown={(isIndividual ?? auth?.is_individual)}
                                    disabled={(isIndividual ?? auth?.is_individual)}
                                />
                                
                                <InputMask
                                    mask="\*\*\* \*\*9 999"
                                    placeholder="*** **9 999"
                                    onChange={(e) =>
                                    setPhoneNumber(
                                        e.target.value
                                        .replaceAll("*", "")
                                        .replaceAll("_", "")
                                        .replaceAll(" ", "")
                                    )
                                    }
                                    value={phoneNumber}
                                    className="border-2 rounded-md md:py-4 py-2 col-span-1 px-2 placeholder:text-black/60 lg:text-xl focus:outline-none"
                                    disabled={(isIndividual ?? auth?.is_individual)}
                                />
                                <div className="col-start-2 col-span-1 flex justify-left">
                                    <p className="text-xs md:text-sm  text-primary mt-1">
                                        {t("Dashboard.my_requests.last_four_digits")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                }

                { auth?.is_individual &&
                    <div className="mt-5 mb-10 lg:mt-10 lg:mb-20">
                        <p className="mb-2 text-lg md:text-xl font-bold text-center whitespace-nowrap">
                            {t("Dashboard.my_requests.show_number_or_email")}
                        </p>
                        <div className="w-full flex justify-between items-center mt-5">
                            <p className="mb-2 md:text-lg">
                                {t("Dashboard.my_requests.phone")}
                            </p>
                            <label className="switch mr-5">
                                <input defaultChecked={showPhone} onChange={() => { setShowPhone(!showPhone) }} type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div className="w-full flex justify-between items-center my-5">
                            <p className="mb-2 md:text-lg">
                                {t("Dashboard.my_requests.email")}
                            </p>
                            <label className="switch mr-5">
                                <input defaultChecked={showEmail} onChange={(e) => { setShowEmail(!showEmail) }} type="checkbox" />
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <p className="text-primary text-center">
                            {t("Dashboard.my_requests.hide_phone_email_note")}
                        </p>
                    </div>
                }

                { (isIndividual ?? auth?.is_individual) ? (
                    <div className="text-center my-5 text-primary">
                        <h2 className="text-black font-bold text-2xl lg:text-3xl">
                            {t("Dashboard.my_requests.tell_us_more_about_you")}
                        </h2>
                        <p className="text-primary">
                            {t("Dashboard.my_requests.visible_to_others")}
                        </p>
                    </div>
                ) : (
                    <div className="flex justify-center my-5 text-primary md:text-xl text-base">
                        <p className="text-center">
                            {t("Dashboard.my_requests.data_is_encrypted")}
                        </p>
                    </div>
                )}
                
                <div>
                    <p className="mb-2 md:text-lg">
                        {t("Dashboard.my_requests.buyer_type")}
                    </p>
                    <Select
                        value={selectedBuyerType}
                        options={formOptions?.buyerTypes}
                        onChange={(e) => {
                        setSelectedBuyerType(e);
                        }}
                        style={customSelect}
                    />
                </div>

                <div>
                    <p className="mb-2 md:text-lg mt-5">
                        {t("Dashboard.my_requests.urgency")}
                    </p>
                    <Select
                        value={selectedUrgency}
                        options={formOptions?.urgencies}
                        onChange={(e) => {
                        setSelectedUrgency(e);
                        }}
                        style={customSelect}
                    />
                </div>

                <div>
                    <p className="mb-2 md:text-lg mt-5">
                        {t("Dashboard.my_requests.investment_type")}
                    </p>
                    <Select
                        value={selectedInvestmentType}
                        options={formOptions?.investmentTypes}
                        onChange={(e) => {
                        setSelectedInvestmentType(e);
                        }}
                        style={customSelect}
                    />
                </div>
                
                <button
                    type="button"
                    onClick={ () => handleSubmit() }
                    className="md:mt-20 mt-10 py-2 bg-primary font-bold text-white text-xl rounded-md w-full"
                >
                    {t("Dashboard.my_requests.continue")}
                </button>
            </form>
        </>
    )
}

export default ClientData;