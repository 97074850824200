import { useState, useEffect } from "react";
import { useAlert } from "../../../contexts/AlertContext.js";
import { t } from "i18next";
import useAuth from '../../../hooks/useAuth';
import axios from "../../Config/axios.js";
import BuyerMatchCard from "./BuyerMatchCard.js";
import Pagination from "../../UI/Pagination";
import Modal from "../../Modals/Modal";
import ActionConfirmationModal from "../../Modals/ActionConfirmationModal";
import EditBuyerMatch from "../../Modals/EditBuyerMatch.js";

const BuyerMatches = () => {

	const { auth } = useAuth()
	const { toggleLoader, triggerPopup } = useAlert()

	const [buyerMatches, setBuyerMatches] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [currentPage, setCurrentPage] = useState(1)

	const [openEditModal, setOpenEditModal] = useState(false)
	const [openDeleteModal, setOpenDeleteModal] = useState(false)
	const [selectedBuyerMatch, setSelectedBuyerMatch] = useState(null)
	const [errors, setErrors] = useState(null)

	const getBuyerMatches = async () => {
		setIsLoading(true)
		const response = await axios.get("/api/buyer-matches", {
			params: {
				per_page: 10,
				page: currentPage,
			},
		})
		if (response.status === 200) {
			setBuyerMatches(response.data)
			setIsLoading(false)
		}
	}

	const updateBuyerMatchNotifications = async (data) => {
		await axios.post("/api/buyer-matches/update-notifications", data)
		.then(() => {
			triggerPopup("Popups.changes_saved");
		})
		.catch(() => {
			triggerPopup("Popups.something_went_wrong");
		})
		.finally(() => {
			//
		});
	}

	const updateBuyerMatchDailyNotification = async () => {
		await axios.post("/api/user/update-buyer-match-daily-notifications")
		.then(() => {
			triggerPopup("Popups.changes_saved");
		})
		.catch(() => {
			triggerPopup("Popups.something_went_wrong");
		})
		.finally(() => {
			//
		});
	}

	const onEdit = async (formData) => {
		await axios.put(`/api/buyer-matches/${formData.id}`, { ...formData })
		.then(() => {
			triggerPopup("Popups.changes_saved")
			setOpenEditModal(false)
			getBuyerMatches()
			setSelectedBuyerMatch(null)
			setErrors(null)
		})
		.catch((e) => {
            setErrors(e.response.data.errors)
			triggerPopup("Popups.something_went_wrong")
		})
		.finally(() => {
			//
		})
	}

	const onDelete = async () => {
		await axios.delete(`/api/buyer-matches/${selectedBuyerMatch.id}`)
		.then(() => {
			triggerPopup("Popups.buyer_match_deleted")
			setOpenDeleteModal(false)
			getBuyerMatches()
			setSelectedBuyerMatch(null)
		})
		.catch(() => {
			triggerPopup("Popups.something_went_wrong")
		})
		.finally(() => {
			//
		})
	}

	const handleOnEdit = (buyerMatch) => {
		setOpenEditModal(true)
		setSelectedBuyerMatch(buyerMatch)
	}

	const handleOnDelete = (buyerMatch) => {
		setOpenDeleteModal(true)
		setSelectedBuyerMatch(buyerMatch)
	}

	const handleCloseDeleteModal = () => {
		setOpenDeleteModal(false)
		setSelectedBuyerMatch(null)
	}

	const handleCloseEditModal = () => {
		setOpenEditModal(false)
		setSelectedBuyerMatch(null)
		setErrors(null)
	}

	const handlePageChange = (page) => {
		setCurrentPage(page)
	}

	useEffect(() => {
		toggleLoader(isLoading)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading])

	useEffect(() => {
		getBuyerMatches()
	}, [currentPage])

	return (
		<>
			<Modal isOpen={ openEditModal }>
                <EditBuyerMatch
					buyerMatch={selectedBuyerMatch}
					onClose={() => { handleCloseEditModal() }}
					onSave={(formData) => { onEdit(formData) }}
					errors={errors}
				/>
            </Modal>

			<Modal isOpen={ openDeleteModal }>
				<ActionConfirmationModal
					title={t('Modals.delete_buyer_match_title')}
					description={t('Modals.delete_buyer_match_subtitle')}
					onCancel={ () => { handleCloseDeleteModal(false) } }
					onConfirm={ () => { onDelete() } }
				/>
			</Modal>

			<div className="flex flex-col pb-10">
				<div className="w-full flex justify-between items-center md:py-5 py-2 border-b-2">
					<h1 className="text-3xl font-bold text-black">{t('Dashboard.buyer_match.title')}</h1>
				</div>
			</div>

			{(buyerMatches?.data)?.length > 0 &&
				<div className="flex items-start gap-5 mb-5">
					<label className="switch shrink-0">
						<input defaultChecked={auth?.buyer_match_daily_notification} onChange={() => { updateBuyerMatchDailyNotification() }} type="checkbox" />
						<span className="slider round"></span>
					</label>
					<p className="font-bold md:text-lg">
						{ t("Dashboard.buyer_match.receive_notifications") }
					</p>
				</div>
			}

			<div className="flex flex-col w-full gap-10">
				{(buyerMatches?.data)?.map((buyerMatch, index) => (
					<BuyerMatchCard
						buyerMatch={buyerMatch}
						onNotificationToggle={(e) => { updateBuyerMatchNotifications({ id: buyerMatch.id, ...e }) }} key={index}
						onEdit={() => { handleOnEdit(buyerMatch) }}
						onDelete={() => { handleOnDelete(buyerMatch) }}
					/>
				))}
			</div>

			<Pagination
				itemCount={buyerMatches.last_page}
				itemsPerPage={currentPage}
				onPageChange={(page) => handlePageChange(page)}
			/>

			{ !isLoading && (buyerMatches?.data)?.length === 0 && (
				<p className="w-full flex justify-center text-primary text-3xl font-bold">{t('Dashboard.buyer_match.no_results')}</p>
			)}
		</>
	);
};

export default BuyerMatches;
