import React from 'react';
import DashboardNavbar from '../../components/Dashboard/Navbar';
import DashboardMessages from '../../components/Dashboard/Messages';
import ScrollToTop from '../../components/Router/ScrollToTop';
import ComingSoon from '../ComingSoon';
import useAuth from '../../hooks/useAuth';

const Messages = () => {
    const { auth } = useAuth();
    return (
        <>
        <ScrollToTop />
        <section>
            <DashboardMessages />
        </section>
        </>
    )
}

export default Messages;