import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { MdKeyboardArrowUp, MdLocationOn } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import { AsyncPaginate } from "react-select-async-paginate";
import axios from "../../Config/axios";
import useAuth from "../../../hooks/useAuth.js";
import useAuthContext from "../../Config/AuthContext.js";
import { useAlert } from "../../../contexts/AlertContext";
import LocationIcon from "../../../assets/Dashboard/Requests/RequestLocationIcon.png";
import { useTranslation } from "react-i18next";
import CreatableSelect from "react-select/creatable";
import ErrorMessage from "../../UI/ErrorMessage";
import { currentLanguage } from "../../Config/i18n.js";
import Select from "../../Inputs/Select.js";
import PropertyTypeCard from "../MyRequests/PropertyTypeCard.js";
import AmenityCard from "../MyRequests/AmenityCard.js";
import LocationSearch from "../../Inputs/LocationSearch.js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditRequest = () => {

  const { auth, } = useAuth();
  const { t } = useTranslation();
  const isMounted = useRef(false);
  const { id } = useParams();
  const location = useLocation();
  const { toggleLoader, triggerPopup } = useAlert();
  const { getLocations } = useAuthContext();
  const creatableSelectStyle = {
    control: (provided, state) => ({
      ...provided,
      borderWidth: "2px",
      borderColor: "#e5e7eb",
      width: "100%",
      borderRadius: "0.375rem",
      paddingTop: "0.25rem",
      paddingBottom: "0.25rem",
    }),
  };
  const creatableSelectDisabledStyle = {
    control: (provided, state) => ({
      ...provided,
      borderWidth: "2px",
      borderColor: "#e5e7eb",
      width: "100%",
      borderRadius: "0.375rem",
      paddingTop: "0.25rem",
      paddingBottom: "0.25rem",
      backgroundColor: "#fafafa",
      cursor: "not-allowed",
    }),
  }

  const [request, setRequest] = useState(null);
  const [formOptions, setFormOptions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [minimumPriceRange, setMinimumPriceRange] = useState([]);
  const [priceRange, setPriceRange] = useState([]);
  const [minimumAreaRange, setMinimumAreaRange] = useState([]);
  const [areaRange, setAreaRange] = useState([]);
  const [errors, setErrors] = useState([]);

  const urlHasPath = (path) => {
    if(location.pathname.includes(path)) {
      return location.pathname;
    }
  }  

  const getRequest = async () => {
    setIsLoading(true);
    const response = await axios.get(`/api/requests/${id}`);
    if (response.status === 200) {
      setRequest(response.data);
      setLocations(response.data.searchable_locations);
      response.data.amenities.forEach(amenity => {
        setAmenities(prev => [...prev, amenity.id]);
      });
      if (response.data.property_types) {
        response.data.property_types.forEach(propertyType => {
          setPropertyTypes(prev => [...prev, propertyType.id]);
        });
      } else {
        setPropertyTypes([response.data.property_type_id]);
      }
    }
  };

  const getFormOptions = async () => {
    const response = await axios.get("/api/get-request-form-options");
    if (response.status === 200) {
      setFormOptions(response.data);
    }
    setIsLoading(false);
  };

  const handleChange = (value, field) => {
		setRequest({ ...request, [field]: value });
	}
  
  const submitForm = async () => {
    setIsLoading(true);
    const formData = {
      id: request.id,
      created_by: request.created_by,
      first_name: request.first_name,
      last_name: request.last_name,
      nationality: request.nationality,
      country_code: request.country_code,
      phone: request.phone,
      buyer_type_id: request.buyer_type_id,
      urgency_id: request.urgency_id,
      investment_type_id: request.investment_type_id,
      request_type_id: request.request_type_id,
      price_until: request.price_until,
      area_from: request.area_from,
      condition_id: request.condition_id,
      typology_id: request.typology_id,
      bathroom_id: request.bathroom_id,
      brief_description: request.brief_description,
      detailed_description: request.detailed_description,
      locations: locations,
      amenities: amenities,
      expiration_date: request.expiration_date,
      show_phone: request.show_phone,
      show_email: request.show_email
    }

    try {
      const response = await axios.put(`/api/requests/${formData.id}`, formData);
      if(response.status === 204)
      {
        setErrors([]);
        triggerPopup('Popups.changes_saved');
      }
    } catch (error) {
      triggerPopup('Popups.something_went_wrong');
      setErrors(error.response.data.errors);
    }
    setIsLoading(false);
  }

  const onLocationChange = (selectedOptions) => {
    setLocations(selectedOptions);
  };

  /* PRICE AND AREA */
  const filterPriceAndAreaRange = () => {
    setMinimumPriceRange([]);
    setPriceRange([]);
    setMinimumAreaRange([]);
    setAreaRange([]);
    formOptions?.priceRange?.forEach(price => {
      if (price.type === request?.request_type_id) {
        setMinimumPriceRange(minimumPriceRange => [...minimumPriceRange, price]);
        setPriceRange(priceRange => [...priceRange, price]);
      }
    });
    formOptions?.areaRange?.forEach(area => {
      if (area.type === request?.request_type_id) {
        setMinimumAreaRange(minimumAreaRange => [...minimumAreaRange, area]);
        setAreaRange(areaRange => [...areaRange, area]);
      }
    });
    setMinimumPriceRange(minimumPriceRange => [...minimumPriceRange, {value: null, label: t("Dashboard.my_requests.no_minimum")}]);
    setPriceRange(priceRange => [...priceRange, {value: null, label: t("Dashboard.my_requests.no_limit")}]);
    setMinimumAreaRange(minimumAreaRange => [...minimumAreaRange, {value: null, label: t("Dashboard.my_requests.no_minimum")}]);
    setAreaRange(areaRange => [...areaRange, {value: null, label: t("Dashboard.my_requests.no_limit")}]);
  }

  /* AMENITIES */
  const handleAmenities = (id) => {
    if (amenities.includes(id)) {
      let amenitiesAux = amenities;
      amenitiesAux.splice(amenitiesAux.indexOf(id), 1);
      setAmenities((current) => [...amenitiesAux]);
    } else {
      setAmenities((current) => [...current, id]);
    }
  };

  /* PROPERTY TYPES */
  const handlePropertyTypes = (id) => {
    if (propertyTypes.includes(id)) {
      let propertyTypesAux = propertyTypes;
      propertyTypesAux.splice(propertyTypesAux.indexOf(id), 1);
      setPropertyTypes((current) => [...propertyTypesAux]);
    } else {
      setPropertyTypes((current) => [...current, id]);
    }
  };

  useEffect(() => {
    toggleLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  let request_type_id = request?.request_type_id;
  useEffect(() => {
    if (isMounted.current) {
      filterPriceAndAreaRange();
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formOptions, request_type_id]);

  useEffect(() => {
    getRequest();
    getFormOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Link to={ urlHasPath('/app/my-requests') ? "/app/my-requests" : "/app/admin/requests"}>
        <MdKeyboardArrowUp className="text-primary text-6xl -rotate-90 border-2 rounded-full p-1 border-primary" />
      </Link>
      {request && formOptions && (
        <div className="space-y-10 md:text-lg">
          {/* REQUEST BRIEF */}
          <div className="flex flex-col md:flex-row justify-between md:items-end">
            <div className="flex mt-10 gap-x-2 items-center">
              <img
                className="w-24 md:w-40"
                src={`/svg/properties/${request.property_type_image}`}
                alt={currentLanguage() === 'en' ? request.property_type_name : request.property_type_name_pt}
              />
              <div className="md:space-y-2">
                <p className="font-bold text-xl md:text-2xl text-[#707070]">
                {currentLanguage() === 'en' ? `${request.property_type_name} (${request.typology_name})` : `${request.property_type_name_pt} (${request.typology_name_pt})`}
                </p>
                <div className="flex items-center space-x-2">
                  <img
                    src={LocationIcon}
                    alt="Location Icon"
                    className="text-primary w-6"
                  />
                  <p className="text-primary text-lg">
                    {t('Dashboard.my_requests.in')}{" "}
                    {Object.keys(request.locations).map((key, index) => { 
                        return `${request.locations[key].name}${index != Object.keys(request.locations).length - 1 ? ", " : ""}`
                      })
                    }
                  </p>
                </div>
                <p className="text-lg">ID: {request.id}</p>
              </div>
            </div>
            { auth.is_admin === 1 &&
              <div className="flex flex-col space-y-2">
                <label>{t('Dashboard.admin.expiration_date')}:</label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={new Date(request.expiration_date)}
                  onChange={(e) => { handleChange(e, 'expiration_date')}}
                  className="w-full border-2 rounded-md py-2 pl-2 pr-16 focus:outline-none cursor-pointer"
                />
              </div>
            }
            { (auth.is_individual || (auth.is_admin === 1 && !request.company_id)) &&
              <div>
                <div className="w-full flex justify-between items-center mt-5 gap-10">
                  <p className="mb-2 md:text-lg">
                    {t("Dashboard.my_requests.phone")}
                  </p>
                  <label className="switch">
                    <input defaultChecked={request.show_phone} onChange={(e) => { handleChange(e.target.checked ? 1 : 0, 'show_phone'); }} type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="w-full flex justify-between items-center my-5 gap-10">
                  <p className="mb-2 md:text-lg">
                    {t("Dashboard.my_requests.email")}
                  </p>
                  <label className="switch">
                    <input defaultChecked={request.show_email} onChange={(e) => { handleChange(e.target.checked ? 1 : 0, 'show_email'); }} type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            }
          </div>

          {/* CLIENT DATA */}
          <div className="space-y-5">
            <h2 className="text-primary text-xl md:text-2xl font-bold">
              {t('Dashboard.my_requests.what_is_client_data')}
            </h2>
            <div className="grid lg:grid-cols-3 gap-y-5 gap-x-20">
              { !auth.is_individual &&
                <>
                  <div className="flex flex-col space-y-2">
                    <label>{t('Dashboard.admin.first_name')} {t('Dashboard.admin.first_three_letters')}</label>
                    <input
                      onChange={(e) => {
                          e.target.value.length < 4 &&
                          request.first_name?.length < 4 &&
                          handleChange(e.target.value, 'first_name');
                      }}
                      value={request.first_name}
                      disabled
                      type="text"
                      className="border-2 rounded-md py-2 px-2 placeholder:text-black/60 placeholder:text-xl focus:outline-none text-disabled cursor-not-allowed"
                      placeholder="First Name"
                    />
                    <ErrorMessage errors={errors.first_name} />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label>{t('Dashboard.admin.last_name')} {t('Dashboard.admin.first_three_letters')}</label>
                    <input
                      onChange={(e) => {
                          e.target.value.length < 4 &&
                          request.last_name.length < 4 &&
                          handleChange(e.target.value, 'last_name');
                      }}
                      value={request.last_name}
                      disabled
                      type="text"
                      className="border-2 rounded-md py-2 px-2 placeholder:text-black/60 placeholder:text-xl focus:outline-none text-disabled cursor-not-allowed"
                      placeholder="Last Name"
                    />
                    <ErrorMessage errors={errors.last_name} />
                  </div>
                  <div className="flex flex-col space-y-2">
                    <label>{t('Dashboard.admin.phone')} {t('Dashboard.admin.last_four_digits')}</label>
                    <PhoneInput
                      defaultMask={"*** **. ..."}
                      alwaysDefaultMask
                      value={`${request.country_code} ${request.phone}`}
                      /*onChange={(number, country) => {
                          setRequest({
                              ...request,
                              phone: number.replace(country.dialCode, ""),
                              country_code: `+${country.dialCode}`,
                          });
                      }}*/
                      buttonStyle={{ border: "none", backgroundColor: "#fafafa", cursor: "not-allowed" }}
                      inputStyle={{
                        border: "none",
                        fontSize: "18px",
                        lineHeight: "28px",
                        width: '100%',
                        backgroundColor: "#fafafa",
                        color: "#999"
                      }}
                      containerStyle={{
                        border: "2px solid #e5e7eb",
                        borderRadius: "6px",
                        backgroundColor: "#fafafa"
                      }}
                      className="py-1 px-2 cursor-not-allowed"
                      disableDropdown={true}
                      disabled
                    />
                    <ErrorMessage errors={errors.phone} />
                  </div>
                </>
              }
              <div className="flex flex-col space-y-2">
                <label>{t('Dashboard.admin.buyer_type')}</label>
                <Select
                  value={{id: request.buyer_type_id, name: request.buyer_type_name, name_pt: request.buyer_type_name_pt }}
                  options={formOptions.buyerTypes}
                  onChange={(e) => { setRequest({...request, buyer_type_id: e.id, buyer_type_name: e.name, buyer_type_name_pt: e.name_pt}) }}
                  style={creatableSelectStyle}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label>{t('Dashboard.admin.urgency')}</label>
                <Select
                  value={{id: request.urgency_id, name: request.urgency_name, name_pt: request.urgency_name_pt }}
                  options={formOptions.urgencies}
                  onChange={(e) => { setRequest({...request, urgency_id: e.id, urgency_name: e.name, urgency_name_pt: e.name_pt}) }}
                  style={creatableSelectStyle}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label>{t('Dashboard.admin.investment_type')}</label>
                <Select
                  value={{id: request.investment_type_id, name: request.investment_type_name, name_pt: request.investment_type_name_pt }}
                  options={formOptions.investmentTypes}
                  onChange={(e) => { setRequest({...request, investment_type_id: e.id, investment_type_name: e.name, investment_type_name_pt: e.name_pt}) }}
                  style={creatableSelectStyle}
                />
              </div>
            </div>
          </div>

          {/* PROPERTY TYPE */}
          <div className="space-y-5">
            <h2 className="text-primary text-xl md:text-2xl font-bold">
              {t('Dashboard.my_requests.what_property_looking_for')}
            </h2>
            <div className="flex flex-col space-y-2 lg:w-1/5">
              <label>{t('Dashboard.my_requests.type_of_request')}</label>
              <Select
                value={{id: request.request_type_id, name: request.request_type_name, name_pt: request.request_type_name_pt }}
                options={formOptions.requestTypes}
                onChange={(e) => { setRequest({...request, request_type_id: e.id, request_type_name: e.name, request_type_name_pt: e.name_pt}) }}
                style={creatableSelectDisabledStyle}
                disabled
              />
            </div>
            <div className="grid grid-cols-2 md:grid-cols-3 xl:flex xl:flex-wrap gap-2 md:gap-8 lg:gap-12">
              {formOptions.propertyTypes.map((property, index) => (
                <PropertyTypeCard
                  key={index}
                  property={property}
                  selectedProperties={propertyTypes}
                  onClick={(e) => {/*handlePropertyTypes(e)*/}}
                  disabled
                />
              ))}
            </div>
          </div>

          {/* LOCATION */}
          <div className="space-y-5">
            <h2 className="text-primary text-xl md:text-2xl font-bold">
            {t('Dashboard.my_requests.which_area_is_property_located')}
            </h2>
              <div>
              <LocationSearch
                onChange={ (e) => { onLocationChange(e) } }
                placeholder={t("Dashboard.my_requests.search")}
                value={locations}
              />
              <ErrorMessage errors={errors.locations} />
            </div>
          </div>

          {/* PRICE/AREA RANGE */}
          <div className="space-y-5">
            <h2 className="text-primary text-xl md:text-2xl font-bold">
            {t('Dashboard.my_requests.values_and_area_looking_for')}
            </h2>
            <div className="grid lg:grid-cols-3 gap-10">
              <div className="grid-row-2 space-y-2">
                <label>{t('Dashboard.my_requests.price')} (€)</label>
                <CreatableSelect
                  value={{
                      value: parseFloat(request.price_until) > 0
                        ? request.price_until
                        : null,
                      label: parseFloat(request.price_until) > 0
                        ? request.price_until : 
                        t("Dashboard.my_requests.no_limit")
                    }}
                  options={priceRange}
                  onChange={(e) => handleChange(e.value, 'price_until')}
                  styles={creatableSelectStyle}
                />
              </div>
              <div className="grid-row-2 space-y-2">
                <label>{t('Dashboard.my_requests.area')}</label>
                <CreatableSelect
                  value={{
                    value: parseFloat(request.area_from) > 0
                      ? request.area_from
                      : null,
                    label: parseFloat(request.area_from) > 0 
                      ? request.area_from
                      : t("Dashboard.my_requests.no_minimum")
                  }}
                  options={minimumAreaRange}
                  onChange={(e) => handleChange(e.value, 'area_from')}
                  styles={creatableSelectStyle}
                />
              </div>
            </div>
          </div>

          {/* AMENITIES */}
          <div className="space-y-5">
            <h2 className="text-primary text-xl md:text-2xl font-bold">
            {t('Dashboard.my_requests.what_features_does_it_have')}
            </h2>
            <div className="grid md:grid-cols-3 md:gap-10 gap-5">
              <div className="flex flex-col space-y-2">
                <label>{t('Dashboard.my_requests.condition')}</label>
                <Select
                  value={{id: request.condition_id, name: request.condition_name, name_pt: request.condition_name_pt }}
                  options={formOptions.conditions}
                  onChange={(e) => { setRequest({...request, condition_id: e.id, condition_name: e.name, condition_name_pt: e.name_pt}) }}
                  style={creatableSelectStyle}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label>{t('Dashboard.my_requests.typology')}</label>
                <Select
                  value={{id: request.typology_id, name: request.typology_name, name_pt: request.typology_name_pt }}
                  options={formOptions.typologies}
                  onChange={(e) => { setRequest({...request, typology_id: e.id, typology_name: e.name, typology_name_pt: e.name_pt}) }}
                  style={creatableSelectStyle}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label>{t('Dashboard.my_requests.bathrooms')}</label>
                <CreatableSelect
                  value={{id: request.bathroom_id, name: request.bathroom_name}}
                  getOptionValue={option => option.id}
                  getOptionLabel={option => option.name}
                  options={formOptions.bathrooms}
                  onChange={(e) => { setRequest({...request, bathroom_id: e.id, bathroom_name: e.name}) }}
                  styles={creatableSelectStyle}
                />
              </div>
            </div>
            <h2 className="text-xl md:text-2xl font-bold">{t('Dashboard.my_requests.amenities')}</h2>
            <div className="flex flex-wrap gap-2 md:gap-8 lg:gap-12">
              {formOptions.amenities.map((amenity, index) => 
                <AmenityCard
                  key={index}
                  amenity={amenity}
                  amenities={formOptions.amenities}
                  selectedAmenities={amenities}
                  onClick={(e) => { handleAmenities(e) }}
                />
              )}
            </div>
          </div>

          {/* DESCRIPTION */}
          <div className="space-y-5">
            <h2 className="text-primary text-xl md:text-2xl font-bold">
            {t('Dashboard.my_requests.what_description_for_request')}
            </h2>
            <div>
              <div className="flex items-center relative">
                <input
                  onChange={(e) => {
                    request.brief_description.length <= 250 &&
                    e.target.value.length <= 250 &&
                    handleChange(e.target.value, 'brief_description');
                  }}
                  value={request.brief_description}
                  type="text"
                  className="my-2 w-full border-2 rounded-md md:py-4 py-2 pl-2 pr-16 placeholder:text-black/60 placeholder:text-xl focus:outline-none"
                  placeholder="Brief Description"
                />
                <span
                  className={`${
                    request.brief_description.length < 30 ? "text-red-500" : ""
                  } absolute right-3`}
                >
                  {request.brief_description.length}/250
                </span>
              </div>
              <div className="flex justify-between items-center">
                {errors.brief_description ? (
                    <div className="ml-auto">
                      <ErrorMessage errors={errors.brief_description} />
                    </div>
                  ) : (
                    <p className="text-black/40 ml-auto">{t('Dashboard.my_requests.brief_min_characters')}</p>
                )}
              </div>
            </div>
            <div>
              <div className="flex items-center relative">
                <textarea
                  onChange={(e) => {
                    request.detailed_description.length <= 1000 &&
                    e.target.value.length <= 1000 &&
                    handleChange(e.target.value, 'detailed_description');
                  }}
                  value={request.detailed_description}
                  rows="15"
                  className="my-2 w-full border-2 rounded-md md:py-4 py-5 px-5 placeholder:text-black/60 placeholder:text-xl focus:outline-none"
                  placeholder="Detailed Description"
                />
                <span
                  className={`${
                    request.detailed_description.length < 30 ? "text-red-500" : null
                  } absolute right-5 bottom-3`}
                >
                  {request.detailed_description.length}/1000
                </span>
              </div>
              <div className="flex justify-between items-center">
              </div>
              <div className="flex justify-between items-center">
                {errors.detailed_description ? (
                  <div className="ml-auto">
                    <ErrorMessage errors={errors.detailed_description} />
                  </div>
                ) : (
                  <p className="text-black/40 ml-auto">{t('Dashboard.my_requests.detailed_min_characters')}</p>
                )}
              </div>
            </div>
          </div>

          <button
            onClick={() => { submitForm() }}
            type="button"
            className="px-10 py-2 bg-primary font-bold text-white text-xl rounded-md"
          >
            {t('Dashboard.my_requests.save_changes')}
          </button>
        </div>
      )}
    </>
  );
};

export default EditRequest;
