import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { currentLanguage } from "../Config/i18n";
import { useTranslation } from "react-i18next";

const ShowAmenities = ({ showAmenitiesModal, data }) => {
	const { t } = useTranslation();
	return (
		<div className="w-screen h-screen z-[100] fixed bg-black/10 top-0 left-0 flex items-center justify-center">
			<div className="bg-white rounded-3xl w-4/5 lg:max-w-3xl h-4/5 flex flex-col shadow-custom overflow-auto">
				<div className="w-full flex justify-end">
					<button onClick={() => showAmenitiesModal()} className="text-primary text-4xl m-3">
						<AiOutlineClose />
					</button>
				</div>
				<div className="space-y-5 w-full px-8 md:px-20 pb-10 text-lg md:text-xl">
					<p className="font-bold text-black/60">{t('Dashboard.my_requests.typology')}: 
						{" "}<span className="font-normal">{ currentLanguage() === 'en' ? data?.typology_name : data?.typology_name_pt }</span>
					</p>
					<p className="font-bold text-black/60">{ data?.bathroom_id == 2 ? t('Dashboard.my_requests.bathroom') : t('Dashboard.my_requests.bathrooms')}: 
						{" "}<span className="font-normal">{data?.bathroom_name}</span>
					</p>
					{data?.amenities.length > 0 &&
					<div>
						<p className="font-bold text-black/60">{t('Dashboard.my_requests.amenities')}</p>
						<ul className="text-black/60 list-inside list-disc text-base md:text-lg ml-2 md:ml-5">
							{data?.amenities.map((amenity, index) => (
								<li key={index}>{ currentLanguage() === 'en' ? amenity.name : amenity.name_pt }</li>
							))}
						</ul>
					</div>
					}
				</div>
			</div>
		</div>
	);
};

export default ShowAmenities;
