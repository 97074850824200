import React from 'react';
import ResetPasswordComp from '../components/ResetPassword';


const ResetPassword = () => {
    return (
        <ResetPasswordComp />
    )
}

export default ResetPassword