import React from 'react';
import AdminLogs from '../../components/Dashboard/Admin/Logs';
import ScrollToTop from '../../components/Router/ScrollToTop';

const Logs = () => {
    return (
        <>
            <ScrollToTop />
            <AdminLogs />
        </>
    )
}

export default Logs