import LocationIcon from '../../../assets/Dashboard/Requests/RequestLocationIcon.png';
import { currentLanguage } from "../../Config/i18n";
import { useTranslation } from "react-i18next";

const DuplicateCard = ({ duplicate }) =>
{
    const { t } = useTranslation();
    return (
        <>
            {/* MOBILE */}
            <div className="md:hidden p-5 h-fit rounded-3xl w-full my-5 space-y-4">
                {/*PROPERTY*/}
                <div className="flex items-start gap-1">
                    <img
                        src={`/svg/properties/${duplicate.property_type?.image}`}
                        alt="Property Type Icon"
                        className="w-16"
                    />
                    <div>
                        <h1 className="font-bold text-black/60">
                            {currentLanguage() === 'en' ? `${duplicate.property_type?.name}` : `${duplicate.property_type?.name_pt}`}
                        </h1>
                        <h1 className="font-bold text-black/60">
                            {currentLanguage() === 'en' ? `(${duplicate.typology.name})` : `(${duplicate.typology.name_pt})`}
                        </h1>
                    </div>
                </div>
                <div className="ml-2">
                    <div className="flex text-primary items-start">
                        <img
                            src={LocationIcon}
                            alt=""
                            className="text-primary w-4 mr-2"
                        />
                        <p className="line-clamp-2 text-sm">{t('Dashboard.my_requests.in')} {
                            Object.keys(duplicate.locations).map((key, index) => { 
                                return `${duplicate.locations[key].zone?.name ?? duplicate.locations[key].parish?.name ?? duplicate.locations[key].municipality?.name ?? duplicate.locations[key].district?.name}${index != Object.keys(duplicate.locations).length - 1 ? ", " : ""}`
                            })
                        }</p>
                    </div>
                    <p className="bg-primary font-bold text-white rounded-full text-md md:block hidden text-center w-fit px-2 whitespace-nowrap">
                        {currentLanguage() === 'en' ? duplicate.request_type.name : duplicate.request_type.name_pt}
                    </p>
                    <p className="text-xs">ID: {duplicate.id}</p>
                </div>
                {/*PRICE*/}
                <div>
                    <h1 className="font-bold text-black/60">{t('Dashboard.my_requests.max_budget')}</h1>
                    <div className="flex font-bold justify-between text-sm">
                        {(parseFloat(duplicate.price_until) > 0)
                        ? `${duplicate.price_until} €`
                        : t('Dashboard.my_requests.no_limit')}
                    </div>
                    <h1 className="font-bold text-black/60">{t('Dashboard.my_requests.useful_area')}</h1>
                    <div className="flex font-bold justify-between text-sm">
                        {parseFloat(duplicate.area_from) > 0
                        ? `${duplicate.area_from} m²`
                        : t('Dashboard.my_requests.no_minimum')}
                    </div>
                </div>
                <div className="space-y-1">
                    <div className="flex items-center justify-between">
                        <div className="w-12 h-12 rounded-full overflow-hidden">
                            <img
                                src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${duplicate.user.profile_image}`}
                                alt="User Profile"
                                className="h-full w-full object-cover"
                            />
                        </div>
                        {duplicate.company?.id != null && 
                            <div className="h-14">
                                <img src={`${process.env.REACT_APP_BACKEND_URL_IMG}/companies/${duplicate.company.logo}`} alt={duplicate.company.name} className="w-full h-full object-contain" />
                            </div>
                        }
                    </div>
                    <h1 className="font-bold text-sm">
                        {duplicate.user.first_name} {duplicate.user.last_name}
                    </h1>  
                </div>
            </div>

            {/* DESKTOP */}
            <div className="py-5 xl:py-8 2xl:py-10 h-40 md:h-fit px-5 xl:px-8 2xl:px-10 rounded-[2rem] md:rounded-[3rem] w-full my-5 md:grid md:grid-cols-5 xl:grid-cols-6 hidden">
                <div className="col-span-1 border-r-2">
                    <div className="flex flex-col items-center justify-center gap-y-2">
                        <div className="w-16 h-16 2xl:w-24 2xl:h-24 rounded-full overflow-hidden">
                            <img
                                src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${duplicate.user.profile_image}`}
                                alt="User Profile"
                                className="h-full w-full object-cover"
                            />
                        </div>
                        <h1 className="font-bold xl:text-lg">
                            {duplicate.user.first_name} {duplicate.user.last_name}
                        </h1>                            
                        {duplicate.company?.id != null && 
                            <div className="h-14 mx-auto">
                                <img src={`${process.env.REACT_APP_BACKEND_URL_IMG}/companies/${duplicate.company.logo}`} alt={duplicate.company.trademark} className="w-full h-full object-contain" />
                            </div>
                        }
                    </div>
                </div>

                <div className='col-span-1 h-full flex items-center justify-center'>
                    <img src={`/svg/properties/${duplicate.property_type?.image}`} alt={duplicate.property_type?.name} />
                </div>
                <div className='col-span-2 pr-5 border-r-2'>
                    <div className="flex justify-between">
                        <div className="space-y-4">
                            <h1 className="2xl:text-xl text-base font-bold text-black/60">
                            {currentLanguage() === 'en' ? `${duplicate.property_type?.name} (${duplicate.typology.name})` : `${duplicate.property_type?.name_pt} (${duplicate.typology.name_pt})`}
                            </h1>
                            <div className="flex text-primary items-center">
                                <div className="md:mr-2">
                                    <img
                                        src={LocationIcon}
                                        alt="Location Icon"
                                        className="text-primary lg:w-[24px] w-[18px]"
                                    />
                                </div>
                                <p className="lg:text-base text-sm">{t('Dashboard.my_requests.in')} {
                                    Object.keys(duplicate.locations).map((key, index) => { 
                                        return `${duplicate.locations[key].zone?.name ?? duplicate.locations[key].parish?.name ?? duplicate.locations[key].municipality?.name ?? duplicate.locations[key].district?.name}${index != Object.keys(duplicate.locations).length - 1 ? ", " : ""}`
                                    })
                                }</p>
                            </div>
                            <p className="bg-primary font-bold text-white rounded-full text-md md:text-sm text-center w-fit px-2 whitespace-nowrap">
                                {currentLanguage() === 'en' ? duplicate.request_type.name : duplicate.request_type.name_pt}
                            </p>
                            <p className="text-base">ID: {duplicate.id}</p>
                        </div>

                        <div className="text-right h-full">
                            <h1 className="font-bold text-md">{t('Dashboard.my_requests.max_budget')}</h1>
                            <div className="2xl:text-lg mb-6">
                                <p>
                                    {(parseFloat(duplicate.price_until) > 0)
                                    ? `${duplicate.price_until} €`
                                    : t('Dashboard.my_requests.no_limit')}
                                </p>
                            </div>
                            <h1 className="font-bold text-md">{t('Dashboard.my_requests.useful_area')}</h1>
                            <div className="2xl:text-lg mb-6">
                                <p>
                                    {parseFloat(duplicate.area_from) > 0
                                    ? `${duplicate.area_from} m²`
                                    : t('Dashboard.my_requests.no_minimum')}
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* <div className="flex justify-between">
                        <p className="text-base">ID: {duplicate.id}</p>
                        <button
                            onClick={() => { onViewAmenities() }}
                            className="text-primary underline text-sm xl:text-md whitespace-nowrap"
                        >
                            {t('Dashboard.my_requests.view_all')}
                        </button>
                    </div> */}
                </div>  

                <div className='md:col-span-1 xl:col-span-2 flex flex-col items-center justify-center md:px-2 lg:px-5 space-y-2'>
                    <h1 className="text-primary text-lg xl:text-2xl">{t('Dashboard.admin.published_at')}</h1>
                    <p className='text-base xl:text-xl'>{duplicate.created_at}</p>
                </div>
            </div>
        </>
    )
}
export default DuplicateCard;