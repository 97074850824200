import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuthContext from "../Config/AuthContext";
import { useAlert } from "../../contexts/AlertContext";
import { useTranslation } from "react-i18next";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import ErrorMessage from "../UI/ErrorMessage";

const ForgotPasswordComp = () => {
	const [email, setEmail] = useState("");

	const { forgotPassword } = useAuthContext();
	const { toggleLoader, triggerPopup } = useAlert();

	const { t } = useTranslation();

	const submitForm = async (event) => {
		toggleLoader(true);
		event.preventDefault();
		const response = await forgotPassword({ 
			params: {
				email: email,
			}
		});
		if (response) {
			toggleLoader(false);
			if (response.status === 204) {
				triggerPopup("Authentication.passwordRecoveryEmailSent");
				setTimeout(() => {
					window.location.href = "/login";
				}, 4000);
			} else {
				triggerPopup("Popups.something_went_wrong");
			}
		}
	};

	return (
		<>
			<section className="w-full flex flex-col py-40">
				<div className="w-full flex justify-center items-center py-5 text-center">
					<h1 className="text-black font-bold text-3xl pt-2">{t("Authentication.forgotPassword")}</h1>
				</div>
				<div className="md:grid md:grid-cols-10 flex flex-col md:px-0 px-10">
					<div className="flex flex-col xl:col-start-5 xl:col-end-7 lg:col-start-4 lg:col-end-8 md:col-start-3 md:col-end-9">
						<form className="" onSubmit={(e) => e.preventDefault()}>
							<input
								onChange={(e) => setEmail(e.target.value)}
								value={email}
								type="email"
								className="w-full my-2 p-2 border-[1px] shadow-lg rounded-md border-fifth lg:text-lg placeholder:text-grey"
								placeholder={t("Authentication.email")}
							/>

							<button
								onClick={(e) => { submitForm(e) }}
								type="submit"
								className="w-full font-bold text-white text-xl bg-black p-2 shadow-lg rounded-full my-2"
							>
								{t("Authentication.sendLink")}
							</button>
						</form>
						<div className="w-full my-2 mb-10">
							<Link to="/login">
								<p className="text-black text-md underline text-center">
									{t("Authentication.returnToLogin")}
								</p>
							</Link>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ForgotPasswordComp;
