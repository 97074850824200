import React from 'react'
import { useAlert } from '../../contexts/AlertContext'
import LoaderIcon from '../../assets/loader.svg';

const Loader = () => {
    
    const { isLoading } = useAlert();

    return isLoading ? (
        <div className='fixed z-[150] top-20 left-1/2 -translate-x-1/2'>
            <img src={LoaderIcon} alt='Loading Icon' />
        </div>
    ) : null
}

export default Loader