import AddonsStore from "../../../components/Dashboard/Store/AddonsStore";
import ScrollToTop from "../../../components/Router/ScrollToTop";

const Addons = () =>
{
    return (
        <>
            <ScrollToTop />
            <AddonsStore />
        </>
    )
}

export default Addons;