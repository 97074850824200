import { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { useTranslation } from "react-i18next";

const BudgetArea = ({ formData, formOptions, onSubmit }) => {

    const customSelect = {
        control: (provided, state) => ({
            ...provided,
            borderWidth: "2px",
            borderColor: "#e5e7eb",
            width: "100%",
            borderRadius: "0.375rem",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
        }),
    };

    const { t } = useTranslation();
    
    const [priceRange, setPriceRange] = useState([]);
    const [areaRange, setAreaRange] = useState([]);

    const [priceUntil, setPriceUntil] = useState([]);
    const [areaFrom, setAreaFrom] = useState([]);

    const filterPriceAndAreaRange = () => {

        setPriceRange([]);
        setAreaRange([]);

        formOptions?.priceRange?.forEach(price => {
            if (price.type == formData?.requestType?.id) {
                setPriceRange(priceRange => [...priceRange, price]);
            }
        });
        formOptions?.areaRange?.forEach(area => {
            if (area.type == formData?.requestType?.id) {
                setAreaRange(areaRange => [...areaRange, area]);
            }
        });

        setPriceRange(priceRange => [...priceRange, {value: null, label: t("Dashboard.my_requests.no_limit")}]);
        setAreaRange(areaRange => [ {value: null, label: t("Dashboard.my_requests.no_minimum")}, ...areaRange]);

        setPriceUntil(formData?.priceUntil ?? {value: null, label: t("Dashboard.my_requests.no_limit")});
        setAreaFrom(formData?.areaFrom ?? {value: null, label: t("Dashboard.my_requests.no_minimum")});
    };

    const handlePrice = (selectedOption) => {
        const priceValue =
            selectedOption.value != null
            ? selectedOption.value.toString().replaceAll(",", ".")
            : selectedOption.value;

            setPriceUntil((current) => selectedOption);

            if (Number.isNaN(Number(priceValue))) {
                setPriceUntil({value: null, label: t("Dashboard.my_requests.no_limit")});
            }
        
    };

    const handleArea = (selectedOption) => {
        const areaValue =
            selectedOption.value != null
            ? selectedOption.value.toString().replaceAll(",", ".")
            : selectedOption.value;
        
            setAreaFrom((current) => selectedOption);
            
            if (Number.isNaN(Number(areaValue))) {
                setAreaFrom({value: null, label: t("Dashboard.my_requests.no_minimum")});
            }
        
    };

    const handleSubmit = () => {
        onSubmit({
            priceUntil: priceUntil,
            areaFrom: areaFrom,
        })
    }

    useEffect(() => {
        if (formData?.requestType) {
            filterPriceAndAreaRange();
        }
    }, [formData?.requestType])
    

    return (
        <>
            <h1 className="text-center text-2xl md:text-4xl font-bold mb-2 md:mb-5">
                {t("Dashboard.my_requests.make_a")}{" "}
                {t("Dashboard.my_requests.request")}
            </h1>

            <h2 className="text-primary md:text-xl font-bold text-center">
                {t("Dashboard.my_requests.values_and_area_looking_for")}
            </h2>

            <form className="xl:w-[25vw] lg:w-[40vw] md:w-[50vw] w-full md:mt-14 mt-5">
                <div className="col-start-1 col-span-2 flex justify-start md:mb-5">
                    <p className="text-xl text-black/80 mt-1">
                        {t("Dashboard.my_requests.max_budget")}
                    </p>
                </div>
                <CreatableSelect
                    value={priceUntil}
                    isClearable
                    options={priceRange}
                    onChange={(selectedOption) => {
                        handlePrice(selectedOption);
                    }}
                    formatCreateLabel={(inputValue) => {
                        let formattedValue = inputValue.replaceAll(",", ".");

                        formattedValue = Intl.NumberFormat("en-US")
                        .format(formattedValue)
                        .replaceAll(",", " ");

                        return `Add value: "${formattedValue.replaceAll(
                        ".",
                        ","
                        )}"`;
                    }}
                    styles={customSelect}
                />
                <div className="col-start-1 col-span-2 flex justify-start md:mb-5 md:mt-10 mt-5">
                    <p className="text-xl text-black/80 mt-1">
                        {t("Dashboard.my_requests.useful_area")}
                    </p>
                </div>
                <CreatableSelect
                    value={areaFrom}
                    isClearable
                    options={areaRange}
                    onChange={(selectedOption) => {
                        handleArea(selectedOption);
                    }}
                    formatCreateLabel={(inputValue) => {
                        let formattedValue = inputValue.replaceAll(",", ".");

                        formattedValue = Intl.NumberFormat("en-US")
                        .format(formattedValue)
                        .replaceAll(",", " ");

                        return `Add value: "${formattedValue.replaceAll(
                        ".",
                        ","
                        )}"`;
                    }}
                    styles={customSelect}
                />
            </form>
            
            <button
                type="button"
                onClick={() => {
                    handleSubmit();
                }}
                className="md:mt-20 mt-10 xl:w-[25vw] lg:w-[40vw] md:w-[50vw] w-full py-2 bg-primary font-bold text-white text-xl rounded-md"
            >
                {t("Dashboard.my_requests.continue")}
            </button>
        </>
    )
}

export default BudgetArea;