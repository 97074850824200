import React, { useEffect, useState } from "react";
import SearchForm from "../SearchForm/index.js";
import LocationIcon from "../../../assets/Dashboard/Requests/RequestLocationIcon.png";
import { IoSettingsOutline } from "react-icons/io5";
import { HiOutlineMail } from "react-icons/hi";
import { TbHandClick } from "react-icons/tb";
import { AiOutlineEye } from "react-icons/ai";
import { SlArrowRight } from "react-icons/sl";
import { useAlert } from "../../../contexts/AlertContext.js";

import useAuthContext from "../../Config/AuthContext.js";

import { useTranslation } from "react-i18next";
import { currentLanguage } from "../../Config/i18n.js";
import RequestCard from "./RequestCard.js";
import Modal from "../../Modals/Modal.js";
import ArchiveRequestModal from "../../Modals/ArchiveRequestModal.js";
import RequestInfo from "../../Modals/RequestInfo.js";
import ShowAmenities from "../../Modals/ShowAmenities.js";
import CreateHighlights from "../../Modals/CreateHighlights.js";
import axios from "../../Config/axios.js";
import Pagination from "../../UI/Pagination.js";

const Archived = () => {
    const {
        userRequests,
        unarchiveRequests,
        deleteRequest,
        checkUserAuth
    } = useAuthContext();
    const { toggleLoader, triggerPopup } = useAlert();

    const { t } = useTranslation();

    const [state, setState] = useState(0);

    const [requests, setRequests] = useState([]);
    const [currentPageArchived, setCurrentPageArchived] = useState(1);
    const [totalPagesArchived, setTotalPagesArchived] = useState(null);

    const [showAmenitiesModalClass, setShowAmenitiesModalClass] = useState("hidden");
    const [showAmenitiesModalData, setShowAmenitiesModalData] = useState(null);
    const [createHighlightsModal, setCreateHighlightsModal] = useState("hidden");

    const [step, setStep] = useState(1);

    const [showArchiveRequestModal, setShowArchiveRequestModal] = useState(false);
    const [selectedRequestToUnarchive, setSelectedRequestToUnarchive] = useState(null);
    const [unarchiveRequestModalTranslations, setUnarchiveRequestModalTranslations] = useState({ title: "", description: "" });

    const [unarchiveRequestModal, setUnarchiveRequestModal] = useState(null);

    const [fieldRequired, setFieldRequired] = useState(false);

    const [requestDetailsModal, setRequestDetailsModal] = useState(false);
	const [requestAmenitiesModal, setRequestAmenitiesModal] = useState(false);
	const [requestReportModal, setRequestReportModal] = useState(false);

    const [selectForHighlightRequest, setSelectForHighlightRequest] = useState(null);

	const [selectedRequest, setSelectedRequest] = useState([]);
	const [isVisible, setIsVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
    const [isSubmiting, setIsSubmiting] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);

    const searchRequests = (data) => {
        setIsLoading(true)
        Object.keys(data).forEach((key) => {
        if (data[key] == null || data[key] === "") {
            delete data[key];
        }
        });

        data.archived = 1;

        userRequests({ ...data }).then((response) => {
        if (typeof response != "undefined") {
            if ((response.data.data)?.length > 0) {
                setRequests(response.data);
                setIsLoading(false);
            } else {
                setRequests([]);
                setIsLoading(false);
            }
        }
        });
    };

    const hideModalHighlights = () => {
        setCreateHighlightsModal("hidden");
    };

    const unarchiveRequest = () => {
        if (selectedRequestToUnarchive != null) {
            setIsSubmiting(true);
            axios.put(`/api/toggle-archive-request/${selectedRequestToUnarchive}`)
            .then((response) => {
                setShowArchiveRequestModal(false);
                searchRequests({});
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsSubmiting(false);
                checkUserAuth()
            });
        }
    };

    const handleUnarchiveRequestModal = (value, requestId = null) => {
        setSelectedRequestToUnarchive(requestId);
        setShowArchiveRequestModal((current) => value);
        setUnarchiveRequestModalTranslations({ title: "Modals.unarchive_request_title", description: "Modals.unarchive_request_subtitle" });
    };

    const deleteRequestFunction = async (id) => {
        toggleLoader(true);
        await deleteRequest(id)
        .then(() => searchRequests({}))
        .finally(() => {
            toggleLoader(false);
        });
    };

    const handlePageChange = (page) => {
		searchRequests({page: page})
		setCurrentPage(page)
	}


    const handleArchivedPageChange = (pageNumber) => {
        setCurrentPageArchived(pageNumber);
    };

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (!isVisible) {
        setIsVisible(scrollTop > 100);
        }
    };

    const handleAmenitiesModal = (request) => {
        const data = {
        bathroom_name: request.bathroom_name,
        typology_name: request.typology_name,
        };
        data.amenities = request.amenities;

        setShowAmenitiesModalData(data);
    };

    useEffect(() => {
        setUnarchiveRequestModal(null);
    }, [state, requests]);

    useEffect(() => {
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    useEffect(() => {
        if (fieldRequired) {
        triggerPopup(t("Dashboard.my_requests.fields_required"));
        setTimeout(() => {
            setFieldRequired(false);
            clearTimeout();
        }, 2500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fieldRequired]);

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
        window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>

        <Modal isOpen={showArchiveRequestModal}>
            {showArchiveRequestModal && (
            <ArchiveRequestModal
                title={t(unarchiveRequestModalTranslations.title)}
                description={t(unarchiveRequestModalTranslations.description)}
                onCancel={() => {
                    handleUnarchiveRequestModal(false);
                }}
                onConfirm={() => {
                    unarchiveRequest();
                }}
                isLoading={isSubmiting}
            />
            )}
        </Modal>
        <Modal isOpen={requestDetailsModal}>
            <RequestInfo
                request={selectedRequest}
                onClose={() => { setRequestDetailsModal(false) }}
                onViewAmenities={() => { setRequestAmenitiesModal(true) }}
                onReport={() => { setRequestReportModal(true) }}
            />
        </Modal>
        <Modal isOpen={requestAmenitiesModal}>
            <ShowAmenities
                showAmenitiesModal={() => { setRequestAmenitiesModal(false) }}
                data={selectedRequest}
            />
        </Modal>
        <div className={createHighlightsModal}>
            <CreateHighlights
                modalHighlights={hideModalHighlights}
                requestId={selectForHighlightRequest}
            />
        </div>

        <div className="flex flex-col w-full">
            <div className="flex flex-col pb-20">
                <SearchForm searchRequests={searchRequests} />
            </div>
            {(requests?.data)?.length > 0 ? (
                <>
                    <div className="flex flex-col w-full">
                        {(requests?.data)?.map((request, index) =>
                            request.archived === 1 && (
                                <RequestCard
                                    key={index}
                                    request={request}
                                    onDetails={() => { setSelectedRequest(request); setRequestDetailsModal(true) }}
                                    onViewAmenities={() => { setSelectedRequest(request); setRequestAmenitiesModal(true) }}
                                    onArchive={() => { handleUnarchiveRequestModal(true, request.id) }}
                                    onDelete={() => { deleteRequestFunction(request.id) }}
                                />
                            )
                        )}
                    </div>
                    <Pagination
                        itemCount={requests.last_page}
                        itemsPerPage={currentPage}
                        onPageChange={(page) => handlePageChange(page)}
                    />
                </>
            ) : (
                <div className="w-full flex justify-center text-primary text-3xl font-bold">
                    {t("Dashboard.my_requests.no_archived_requests")}
                </div>
            )}
        </div>
        </>
    );
};

export default Archived;
