import React from 'react';
import Logo from '../assets/Logo-Branco.png';

const ComingSoon = () => {
    return (
        <div className='bg-primary w-screen h-screen flex flex-col justify-center items-center text-white'>
            <div className='flex flex-col justify-center items-center'>
                <img src={Logo} alt='Logo' className='' />
            </div>
            <h1 className='text-2xl'>coming soon...</h1>
        </div>
    )
}

export default ComingSoon