import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineClose } from 'react-icons/ai';

const TermsConditions = ({ onClose }) => {

    const { t } = useTranslation();

    return (
        <div className='relative flex flex-col bg-white md:w-[60vw] w-[90vw] h-fit max-h-96 lg:max-h-[700px] px-8 lg:px-10 py-10 shadow-2xl rounded-3xl'>
            <button onClick={() => { onClose() } } className='absolute top-0 right-0 flex justify-end text-primary text-3xl lg:text-4xl m-3'><AiOutlineClose /></button>
            <div className='flex justify-center'>
                <h1 className='text-primary text-xl lg:text-3xl font-bold'>{t('TermsConditions.modalTitle')}</h1>
            </div>
            <div className='flex flex-col mt-2 overflow-auto'>
                <p className='text-black lg:text-xl whitespace-pre-line'>
                    {t('TermsConditions.brief_text')}
                </p>
                <div className='flex justify-end mt-5 lg:mt-10'>
                    <a href='/terms-and-conditions' target='_blank' className='py-2 px-4 text-white bg-black lg:text-xl font-bold rounded-md mx-2'>{t('TermsConditions.more')}</a>
                </div>
            </div>
        </div>
    )
}

export default TermsConditions;