import React from 'react';
import AdminUsers from '../../components/Dashboard/Admin/Users';
import ScrollToTop from '../../components/Router/ScrollToTop';

const Users = () => {
    return (
        <>
            <ScrollToTop />
            <AdminUsers />
        </>
    )
}

export default Users