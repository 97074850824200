import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { useAlert } from '../../contexts/AlertContext';
import { REPORT_TYPE } from "../../constants/global";
import axios from "../Config/axios";

const ReportRequest = ({ request, onClose }) => {

    const { t } = useTranslation();
    const { toggleLoader, triggerPopup } = useAlert();
    const [step, setStep] = useState(0);
    const [formData, setFormData] = useState({ request_id: request?.id, report_type: null, message: "" });

    const submit = async () =>
    {
        toggleLoader(true);
        const response = await axios.post('api/reports', formData);
        if (response.status === 204) {
            triggerPopup('Popups.report_success');
        }
        else {
            triggerPopup('Popups.something_went_wrong');
        }
        toggleLoader(false);
    }

    return (
        <div className='fixed top-0 left-0 z-[100] h-screen w-screen bg-black/10 flex justify-center items-center'>
            <div className="bg-white rounded-3xl w-4/5 lg:max-w-3xl h-fit max-h-[80%] flex flex-col shadow-custom overflow-auto">
                <button onClick={() => { setStep(0); onClose(); }} className='flex justify-end text-primary text-2xl md:text-4xl m-3'><AiOutlineClose /></button>
                {
                    step === 0 &&

                    <>

                        <div className='w-full px-5 text-center'>
                            <h1 className='font-bold text-primary text-lg md:text-2xl text-center'>{t('Modals.why_report_request')}</h1>
                            <p className='mt-2 md:mt-5 text-sm text-black/70'>{t('Modals.select_a_reason')}</p>
                            <div className='flex flex-col my-5 md:my-10'>
                                <button onClick={() => { setFormData({...formData, report_type: REPORT_TYPE.FAKE_REQUEST}); setStep(1) }} className='text-left md:text-center flex justify-between md:items-center md:px-10 font-bold text-black md:text-xl border-b-[1px] pb-2 md:pb-5'>
                                    <h1>{t('Modals.account_publish_fake_requests')}</h1>
                                    <div className='text-3xl md:text-4xl'><MdKeyboardArrowRight /></div>
                                </button>
                                <button onClick={() => { setFormData({...formData, report_type: REPORT_TYPE.FAKE_IDENTITY}); setStep(1) }} className='text-left md:text-center flex justify-between md:items-center md:px-10 font-bold text-black md:text-xl border-b-[1px] py-2 md:py-5'>
                                    <h1>{t('Modals.account_pretends_to_be_someone')}</h1>
                                    <div className='text-3xl md:text-4xl'><MdKeyboardArrowRight /></div>
                                </button>
                                <button onClick={() => { setFormData({...formData, report_type: REPORT_TYPE.OTHER}); setStep(1) }} className='flex justify-between md:items-center md:px-10 font-bold text-black md:text-xl border-b-[1px] py-2 md:py-5'>
                                    <h1>{t('Modals.something_else')}</h1>
                                    <div className='text-3xl md:text-4xl'><MdKeyboardArrowRight /></div>
                                </button>
                            </div>
                        </div>

                    </>
                }
                {
                    (step === 1 && formData?.report_type === REPORT_TYPE.FAKE_REQUEST) &&

                    <div className='w-full px-10 text-center'>
                        <h1 className='font-bold text-primary text-2xl text-center'>{t('Modals.about_fake_requests')}</h1>
                        <p className='mt-10 text-sm text-black/70'>{t('Modals.fake_request_text')}</p>
                        <button onClick={() => { setStep(2); }} className='my-10 bg-primary text-white font-bold text-center rounded-lg py-2 w-40 md:w-[200px] mx-auto'>{t('Modals.submit')}</button>
                    </div>

                }
                {
                    (step === 1 && formData?.report_type === REPORT_TYPE.FAKE_IDENTITY) &&

                    <div className='w-full px-10 text-center'>
                        <h1 className='font-bold text-primary text-2xl text-center'>{t('Modals.about_impersonating_others')}</h1>
                        <p className='mt-10 text-sm text-black/70'>{t('Modals.account_pretending_text')}</p>
                        <button onClick={() => { setStep(2); }} className='my-10 bg-primary text-white font-bold text-center rounded-lg py-2 w-40 md:w-[200px] mx-auto'>{t('Modals.submit')}</button>
                    </div>

                }
                {
                    (step === 1 && formData?.report_type === REPORT_TYPE.OTHER) &&

                    <div className='w-full px-5 text-center flex flex-col items-center justify-center'>
                        <div className="flex items-center relative w-full">
                            <textarea 
                                className='border-2 w-full p-5 rounded-lg'
                                rows="8"
                                placeholder={t('Modals.something_else_text')}
                                onChange={(e) => {
                                    setFormData({...formData, message: e.target.value});
                                }}
                                value={formData?.message}
                            />
                            <span
                                className={`${
                                formData?.message.length < 50 ? "text-red-500" : null
                                } absolute right-5 bottom-3`}
                            >
                                {formData?.message.length}
                            </span>
                        </div>
                        <p className="text-black/40 ml-auto">{t('Modals.something_else_min_characters')}</p>
                        <button onClick={() => { if(formData?.message.length > 49) setStep(2); }}
                            className={`${formData?.message.length < 50 ? 'bg-lightPrimary' : 'bg-primary'} my-10 bg-primary text-white font-bold text-center rounded-lg py-2 w-40 md:w-[200px] mx-auto`}
                        >
                            {t('Modals.submit')}
                        </button>
                    </div>

                }
                {
                    step === 2 && 

                    <div className='w-full px-5 text-center flex flex-col items-center justify-center pb-10'>
                        <h1 className='font-bold text-primary text-xl md:text-2xl text-center'>{t('Modals.report_request_title')}</h1>
                        <p className='text-black text-lg md:text-xl pt-5 pb-10'>{t('Modals.report_request_subtitle')}</p>
                        <button onClick={() => {setStep(0); onClose()}} className='bg-black text-white font-bold rounded-lg my-3 w-[50%] py-2'>{t('Modals.cancel')}</button>
                        <button onClick={() => { submit(); setStep(0); onClose()}} className='bg-primary text-white font-bold rounded-lg my-3 px-5 md:px-0 w-fit md:w-[50%] py-2 mx-auto'>{t('Modals.confirm')}</button>
                    </div>
                }
            </div>

        </div>
    )
}

export default ReportRequest