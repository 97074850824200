import React from 'react';
import ActiveComponent from '../../../components/Dashboard/MyRequests/Active';
import ScrollToTop from '../../../components/Router/ScrollToTop';

const Active = () => {
    return (
        <>
            <ScrollToTop />
            <ActiveComponent />
        </>
    )
}

export default Active