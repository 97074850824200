const editSVG = () =>
{
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 39.75 39.75">
                <g id="Icon_akar-edit" data-name="Icon akar-edit" transform="translate(-15 -3)">
                    <path id="Path_1615" data-name="Path 1615" d="M36.617,13.349l7.784,7.78M41.623,6.5,20.576,27.542a7.784,7.784,0,0,0-2.132,3.976L16.5,41.25,26.232,39.3a7.775,7.775,0,0,0,3.976-2.128L51.255,16.127A6.811,6.811,0,0,0,41.623,6.5Z" transform="translate(0 0)" fill="none" stroke="#ff6600" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                </g>
            </svg>
        </>
    )
}

export default editSVG