import useAuth from "../../../hooks/useAuth";
import Banner from "../../../assets/Dashboard/Dashboard/duplicates_banner.png"
import NoAccessBg from "../../../assets/Dashboard/Dashboard/duplicates_no_access_background.png"
import DuplicatedRequests from "../../../assets/Dashboard/Dashboard/duplicated_requests.png"
import People from "../../../assets/Dashboard/Dashboard/duplicates_image.png"
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const DuplicatesBanner = () => {

    const { auth } = useAuth();
    const { t } = useTranslation();
    let navigate = useNavigate();

    const changeRoute = () => {
        navigate('/app/duplicates')
    }

    return (
        <>
            <div className='w-full'>
                <div className='w-full flex justify-between items-center py-5 border-b-2'>
                    <h1 className='text-3xl font-bold text-black'>{t('Dashboard.dashboard.duplicates')}</h1>
                </div>
                { auth?.has_duplicates ? (
                    <div
                        className="relative w-full h-full cursor-pointer mt-10"
                        onClick={changeRoute}
                    >
                        <div className="flex flex-col items-center gap-2 absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10">
                            <p className="md:text-xl lg:text-4xl font-bold">{t('Dashboard.dashboard.see_duplicates')}</p>
                            <p className="text-xs lg:text-xl">{t('Dashboard.dashboard.click_to_see_all_duplicates')}</p>
                        </div>
                        <div className="absolute top-0 w-full h-full bg-gradient-to-t from-white to-transparent" />
                        <img src={Banner} alt='Duplicados' />
                    </div>
                    ) : (
                        <div className="relative w-full">
                            <div className="flex items-center justify-center w-full">
                                <img className="hidden lg:block" src={DuplicatedRequests} alt="Pedidos Duplicados" />
                                <div className="flex flex-col items-center gap-2 text-center lg:w-1/2">
                                    <p className="font-bold md:text-xl lg:text-3xl">{t('Dashboard.dashboard.no_access_duplicates')}</p>
                                    <p className="text-primary text-sm md:text-base lg:text-xl">{t('Dashboard.dashboard.subscribed_plan_has_no_access')}</p>
                                    <Link
                                        to=""
                                        className="rounded-full bg-primary text-white px-4 py-1 my-2 font-bold shadow-lg"
                                    >
                                        {t('Dashboard.dashboard.upgrade_plan')}
                                    </Link>
                                </div>
                                <img className="hidden lg:block w-1/3" src={People} alt="Pessoas" />
                            </div>
                            <img className="absolute -z-10 top-0 left-0" src={NoAccessBg} alt='' />
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default DuplicatesBanner