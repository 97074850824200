import { t } from "i18next";
import { useState } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { PERMANENCE_PERIODS } from "../../../constants/global";

const PlanCard = ({ title, options, price, hasVat = false, isPopular = false, permanencePeriodOptions = null, has20Discount = false, has35Discount = false, onSelect, isSelected = false }) =>
{
    const [selectedPermanencePeriod, setSelectedPermanencePeriod] = useState(PERMANENCE_PERIODS.NO_LOYALTY);

    const color = isPopular ? 'customPurple' : 'primary'

    const handlePermanencePeriodSelection = (option) => {
        setSelectedPermanencePeriod(option)
        handlePlanSelection(option)
    }

    const handlePlanSelection = (option = null) => {
        onSelect(option)
    }

    return (
        <>
            <div className='flex flex-col space-y-5'>
                <div className={`${isPopular ? 'bg-customPurple/60' : 'bg-transparent'} rounded-2xl`}>
                    <p className={`${isPopular ? 'opacity-100' : 'opacity-0'} text-white text-center font-bold py-3`}>{t('Pricing.step2.most_popular')}</p>
                    <div className=" bg-white w-60 md:w-80 rounded-2xl text-left shadow-custom">
                        <button onClick={() => { handlePlanSelection() }}
                            className={`${isSelected ? `bg-${color} text-white` : `bg-white text-${color}`} w-full rounded-tl-xl rounded-tr-xl font-bold text-2xl xl:text-3xl h-16 xl:h-20 transition-colors`}>
                            {title}
                        </button>
                        <ul className='xl:text-xl p-5 space-y-4 border-y-2 border-fourth'>
                            { options?.map(option => (
                                <li className="flex items-center gap-2">
                                    <IoMdCheckmark className={`${option.isChecked ? 'opacity-100' : 'opacity-0'} text-${color}`} />
                                    <span className={`${!option.isChecked && 'text-sixth'}`}>{option.name}</span>
                                </li>
                            ))}
                        </ul>
                        <div className={`flex flex-col text-center justify-center text-${color} py-5 space-y-5 relative`}>
                            <div className="font-bold">
                                <p className='text-3xl xl:text-5xl text-center'>{price}</p>
                                { hasVat &&
                                    <p className="text-sixth">+ {t('Pricing.step2.business_plan.vat_per_month')}</p>
                                }
                            </div>
                            { isPopular ? (
                                <>
                                    { has20Discount && selectedPermanencePeriod == PERMANENCE_PERIODS.THREE_MONTHS &&
                                        <img className="absolute -right-2 md:right-2 top-2 xl:top-5 w-20" src="20_discount.svg" alt="20% Discount" />
                                    }
                                    { has35Discount && selectedPermanencePeriod == PERMANENCE_PERIODS.SIX_MONTHS &&
                                        <img className="absolute -right-2 md:right-2 top-2 xl:top-5 w-20" src="35_discount.svg" alt="35% Discount" />
                                    }
                                </>
                            ) : (
                                <>
                                    { has20Discount && selectedPermanencePeriod == PERMANENCE_PERIODS.SIX_MONTHS &&
                                        <img className="absolute -right-2 md:right-2 top-2 xl:top-5 w-20" src="20_discount_orange.svg" alt="20% Discount" />
                                    }
                                </>
                            )}
                            { permanencePeriodOptions &&
                                <div className="space-y-3 px-5">
                                    <div className={`grid grid-cols-${permanencePeriodOptions?.length} border border-${color} bg-white rounded-xl mx-auto`}>
                                        { permanencePeriodOptions?.map(option => (
                                            <button
                                                className={`${selectedPermanencePeriod === option.value ? `bg-${color} text-white` : `text-${color}`}
                                                    px-2 py-1 rounded-lg
                                                `}
                                                onClick={() => { handlePermanencePeriodSelection(option.value) }}
                                            >
                                                {option.name}
                                            </button>
                                        ))}
                                    </div>
                                    <p className="mx-auto">{t('Pricing.step2.business_plan.permanence_period')}</p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <button
                    onClick={() => { handlePlanSelection() }}
                    className={
                        `${isSelected ? `bg-${color} text-white` : `bg-white text-${color}`}
                        w-full border border-${color} h-full rounded-xl font-bold shadow-custom text-xl xl:text-3xl p-4 transition-colors`}
                    >
                    {t('Pricing.step2.get_started')}
                </button>
            </div>
        </>
    )
}

export default PlanCard;