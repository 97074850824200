import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useTranslation } from 'react-i18next';

const ActionConfirmationModal = ({ title, description, onCancel, onConfirm }) => {

  const { t } = useTranslation();

  return (
    <div className='fixed top-0 left-0 h-screen w-screen bg-black/10 flex justify-center items-center'>
      <div className='w-[80%] md:w-[35vw] bg-white rounded-3xl flex flex-col p-5'>
        <button onClick={() => { onCancel() } } className='flex justify-end text-primary text-2xl md:text-4xl ml-auto'><AiOutlineClose /></button>
        <div className='w-full text-center flex flex-col items-center justify-center pb-10'>
            <h1 className='font-bold text-primary text-lg md:text-2xl text-center whitespace-normal'>{title}</h1>
            <p className='text-black md:text-xl py-5 md:pt-5 md:pb-10'>{description}</p>
            <button onClick={() => { onCancel() } } className='bg-black text-white font-bold rounded-lg mb-2 md:my-3 w-fit md:w-1/2 p-2'>{t('Modals.cancel')}</button>
            <button onClick={() => { onConfirm() }} className='bg-primary text-white font-bold rounded-lg md:my-3 w-fit md:w-1/2 p-2'>{t('Modals.confirm')}</button>
        </div>
      </div>
    </div>
  );
};
export default ActionConfirmationModal;
