import React from 'react';
import { useTranslation } from 'react-i18next';
import {Link} from 'react-router-dom';
import PropertyReq from '../../../assets/Index/WhyFortimy.png';
import Logo from '../../../assets/Logo.png';

const WhyFortimy = () => {

  const { t } = useTranslation();

  return (
    <section id="why-fortimy" className='bg-secondary p-12'>
        <h2 className='text-center font-bold md:text-5xl text-4xl flex md:flex-row flex-col justify-center items-center'>{t('Businesses.WhyFortimyTitle')}
          <div className='flex items-center mt-2 md:m-0'>
            <img alt='Fortimy' className='-mt-2 px-2 md:h-[4rem] h-[3.2rem]' src={Logo} />
            ?
          </div>
        </h2>
        <div className='md:grid md:grid-cols-10 md:mt-10 mt-4 items-center flex flex-col md:text-left text-center'>

          <div className='xl:col-start-2 xl:col-span-4 md:col-start-1 md:col-end-6 md:mr-5 xl:mx-14'>
            <h2 className='font-bold lg:text-4xl md:text-3xl text-2xl'>{t('Businesses.WhyFortimySubTitle')}</h2>
            <p className='lg:text-2xl md:text-xl leading-normal text-lg py-10'>{t('Businesses.WhyFortimyText')}</p>
            <button className='bg-primary rounded-full font-bold text-white py-2 px-4 md:py-3 md:px-6 text-lg md:text-xl shadow-lg'><Link to="/product">{t('Businesses.WhyFortimyBtn')}</Link></button>
          </div>
          <img alt='Property Requests' src={PropertyReq} className='md:col-start-6 md:col-end-11 mt-4 md:mt-0' />
        </div>
    </section>
  )
}

export default WhyFortimy