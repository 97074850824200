import React, { useEffect, useState } from "react";
import BtnClose from "../../../assets/Faq/BtnCloseModal.png";
import WhatisFortimyIcon from "../../../assets/Faq/whatisFortimyIcon.png";
import ToWhoIsFortimyIcon from "../../../assets/Faq/toWhoIsFortimyIcon.png";
import WhatProductsIcon from "../../../assets/Faq/whatProductsIcon.png";
import DoesFortimyIcon from "../../../assets/Faq/doesFortimyIcon.png";
import WhatAreTheMainBenefitsIcon from "../../../assets/Faq/whatAreTheMainBenefitsIcon.png";
import WithACompanyProfileIcon from "../../../assets/Faq/withACompanyProfileIcon.png";
import InWhichCountriesIcon from "../../../assets/Faq/inWhichCountriesIcon.png";
import HowCanIGetIcon from "../../../assets/Faq/howCanIGetIcon.png";
import WhatAreTheSubscriptionIcon from "../../../assets/Faq/whatAreTheSubscriptionIcon.png";
import WhatAreTheAvailableIcon from "../../../assets/Faq/whatAreTheAvailableIcon.png";
import WhatCostsDoISaveIcon from "../../../assets/Faq/whatCostsDoISaveIcon.png";
import CanIHaveAFreeDemoIcon from "../../../assets/Faq/canIHaveAFreeDemoIcon.png";
import BusinessPlan from "../../../assets/Faq/BusinessPlanIcon.png";
import IndividualPlan from "../../../assets/Faq/IndividualPlanIcon.png";
import FaqModalHeaderImg from "../../../assets/Faq/FaqModalHeader.png";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Modal = (props) => {
  const { t } = useTranslation();

  const [visible, setVisible] = useState("hidden");
  const [text, setText] = useState("");
  const [icon, setIcon] = useState("");

  useEffect(() => {
    switch (props.handleClick) {
      case 1:
        setVisible("");
        setText( 
            <p className="text-xl text-center">
              {t("Faq.what_is_fortimy.description")}
            </p>
        );
        setIcon(
          <img
            alt="What is Fortimy"
            className="w-[50%] m-auto"
            src={WhatisFortimyIcon}
          />
        );
        break;
      case 2:
        setVisible("");
        setText(
            <p className="text-xl text-center">
              {t("Faq.to_who_is_fortimy_addressed.description")}
            </p>
        );
        setIcon(
          <img
            alt="To Who Is Fortimy"
            className="w-[50%] m-auto"
            src={ToWhoIsFortimyIcon}
          />
        );
        break;
      case 3:
        setVisible("");
        setText(
            <p className="text-xl text-center">{t("Faq.Faq3Text")}</p>
        );
        setIcon(
          <img
            alt="What Products"
            className="w-[60%] m-auto"
            src={WhatProductsIcon}
          />
        );
        break;
      case 4:
        setVisible("");
        setText(
          <p className="text-xl text-center">
            {t("Faq.Faq4Text")}
          </p>
        );
        setIcon(
          <img
            alt="Does Fortimy"
            className="w-[65%] m-auto"
            src={DoesFortimyIcon}
          />
        );
        break;
      case 5:
        setVisible("");
        setText(
          <div className="text-xl text-left">
            <img
              alt="Business Plan"
              className="m-auto pb-5 w-[30%]"
              src={BusinessPlan}
            />
            <div className="">
              <h1 className="text-primary font-bold py-5">
                {t("Faq.Faq5Text1")}
              </h1>
              <ul className="list-disc list-inside">
                <li className="py-3">{t("Faq.Faq5Text2")}</li>
                <li className="py-3">{t("Faq.Faq5Text3")}</li>
                <li className="py-3">{t("Faq.Faq5Text4")}</li>
                <li className="py-3">{t("Faq.Faq5Text5")}</li>
                <li className="py-3">{t("Faq.Faq5Text6")}</li>
                <li className="py-3">{t("Faq.Faq5Text7")}</li>
                <li className="py-3">{t("Faq.Faq5Text8")}</li>
                <li className="py-3">{t("Faq.Faq5Text9")}</li>
                <li className="py-3">{t("Faq.Faq5Text10")}</li>
                <li className="py-3">{t("Faq.Faq5Text11")}</li>
              </ul>
            </div>
          </div>
        );
        setIcon(
          <img
            alt="What are the main benefits"
            className="w-[32%] m-auto"
            src={WhatAreTheMainBenefitsIcon}
          />
        );
        break;
      case 6:
        setVisible("");
        setText(
          <p className="text-xl text-center">
            {t("Faq.Faq6Text")}
          </p>
        );
        setIcon(
          <img
            alt="Company Profile"
            className="w-[45%] m-auto"
            src={WithACompanyProfileIcon}
          />
        );
        break;
      case 7:
        setVisible("");
        setText(
          <p className="text-xl text-center">
            {t("Faq.Faq7Text")}
          </p>
        );
        setIcon(
          <img
            alt="Fortimy Countries"
            className="w-[50%] m-auto"
            src={InWhichCountriesIcon}
          />
        );
        break;
      case 8:
        setVisible("");
        setText(
          <p className="text-xl text-center">
            {t("Faq.Faq8Text1")}
            <Link to="/pricing" target="_blank" className="text-primary underline">
              {t("Faq.Faq8Text2")}
            </Link>
            .
          </p>
        );
        setIcon(
          <img
            alt="How can you get"
            className="w-[50%] m-auto"
            src={HowCanIGetIcon}
          />
        );
        break;
      case 9:
        setVisible("");
        setText(
          <div className="text-xl text-center">
            <img
              alt="Business Plan"
              className="m-auto pb-5 w-[30%]"
              src={BusinessPlan}
            />
            <h1 className="text-primary font-bold text-3xl">
              {t("Faq.Faq9Text1")}
            </h1>
            <p>{t("Faq.Faq9Text2")}</p>
            <p>{t("Faq.Faq9Text3")}</p>
            <p>{t("Faq.Faq9Text4")}</p>
          </div>
        );
        setIcon(
          <img
            alt="Subscriptions"
            className="w-[40%] m-auto"
            src={WhatAreTheSubscriptionIcon}
          />
        );
        break;
      case 10:
        setVisible("");
        setText(
          <div className="text-xl text-center">
            <img
              alt="Business Plan"
              className="m-auto pb-5 w-[30%]"
              src={BusinessPlan}
            />
            <h1 className="text-primary font-bold pb-5">
              {t("Faq.Faq10Text1")}
            </h1>
            <p>{t("Faq.Faq10Text2")}</p>
          </div>
        );
        setIcon(
          <img
            alt="What are the available"
            className="w-[50%] m-auto"
            src={WhatAreTheAvailableIcon}
          />
        );
        break;
      case 11:
        setVisible("");
        setText(
          <p className="text-xl text-center">
            {t("Faq.Faq11Text")}
          </p>
        );
        setIcon(
          <img
            alt="Costs to save"
            className="w-[50%] m-auto"
            src={WhatCostsDoISaveIcon}
          />
        );
        break;
      case 12:
        setVisible("");
        setText(
          <p className="text-xl text-center">
            {t("Faq.Faq12Text1")}
            <Link to="/request-demo" target="_blank" className="text-primary underline">
              {t("Faq.Faq12Text2")}
            </Link>
            {t("Faq.Faq12Text3")}
          </p>
        );
        setIcon(
          <img
            alt="Free Demo"
            className="w-[55%] m-auto"
            src={CanIHaveAFreeDemoIcon}
          />
        );
        break;
      default:
        setVisible("hidden");
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.handleClick]);

  return (
    <div
      className={`${visible} bg-black/30 h-screen w-full fixed z-50 top-0 flex justify-center items-center`}
    >
      <div className="mx-4 sm:w-2/3 xl:w-1/3 lg:h-[700px] md:h-[600px] h-[450px] overflow-clip rounded-[3.8rem]">
        <div className="faq-scrollbar overflow-y-auto bg-white rounded-[3.8rem] relative h-full w-full">
          <button
            className="absolute right-6 top-6 z-50"
            onClick={() => {
              props.onClick();
            }}
          >
            <img alt="Close" className="w-7" src={BtnClose} />
          </button>

          <div className="relative">
            <img className="w-full" src={FaqModalHeaderImg} alt="faq" />
            <div className="bottom-16 left-1/2 -translate-x-1/2 absolute w-2/3">
              {icon}
            </div>
          </div>
          <div className="px-8 py-3">{text}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
