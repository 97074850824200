import { t } from "i18next";
import { AiOutlinePlusCircle } from "react-icons/ai";
import Modal from "../../../Modals/Modal";
import ManageTheme from "../../../Modals/ManageTheme";
import { useEffect, useState } from "react";
import axios from '../../../Config/axios';
import { useAlert } from "../../../../contexts/AlertContext";
import ThemeCard from './ThemeCard';
import ActionConfirmationModal from "../../../Modals/ActionConfirmationModal";

const Themes = () =>
{
    const { toggleLoader, triggerPopup } = useAlert();
    const [isLoading, setIsLoading] = useState(true);
    const [openManageThemeModal, setOpenManageThemeModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [themes, setThemes] = useState([]);
    const [selectedTheme, setSelectedTheme] = useState(null);
    const [errors, setErrors] = useState([]);
    
    const getThemes = async () => 
    {
        setIsLoading(true);
        const response = await axios.get('/api/theme');
        if (response.status === 200)
        {
            setThemes(response.data.data);
        }
        setIsLoading(false);
    }

    const handleEditTheme = (theme) => {
        setSelectedTheme(theme);
        setOpenManageThemeModal(true);
    }

    const handleDeleteTheme = (theme) => {
        setSelectedTheme(theme);
        setOpenDeleteModal(true);
    }

    const handleClose = () =>
    {
        setOpenManageThemeModal(false);
        setSelectedTheme(null);
        setErrors([]);
    }

    const submitForm = async (formData) =>
    {
        setIsLoading(true);
        try {
            const response = await axios.post('/api/theme', { id: formData.id, name: formData.name });
            if (response.status === 200) {
                triggerPopup( formData.id === 0 ? 'Popups.theme_created' : 'Popups.changes_saved');
                setOpenManageThemeModal(false);
                setSelectedTheme(null);
                getThemes();
            }   
        } catch (error) {
            setErrors(error.response.data.errors);
        }
        setIsLoading(false);
    }

    const deleteTheme = async (theme) =>
    {
        setIsLoading(true);
        try {
            const response = await axios.delete('/api/theme/destroy', {
                params: {
                    id: theme.id
                }
            });
            if (response.status === 204)
            {
                triggerPopup('Popups.theme_deleted');
                setOpenDeleteModal(false);
                getThemes();
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        getThemes();
    }, []);
    

    useEffect(() => {
        toggleLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]);
    

    return (
        <>
            <Modal isOpen={openManageThemeModal}>
                <ManageTheme theme={selectedTheme} onClose={() => { handleClose() }} onSubmit={(formData) => { submitForm(formData) }} errors={errors} />
            </Modal>

            <Modal isOpen={ openDeleteModal }>
                <ActionConfirmationModal
                    title={t('Modals.delete_theme_title')}
                    description={t('Modals.delete_theme_subtitle')}
                    onCancel={ () => { setOpenDeleteModal(false) } }
                    onConfirm={ () => { deleteTheme(selectedTheme) } }
                />
            </Modal>

            <button onClick={() => { setOpenManageThemeModal(true) }} className="w-full rounded-2xl bg-primary/20 text-primary  hover:bg-primary hover:text-white flex items-center px-8 py-6 shadow-custom my-3 transition-all">
                <span className="text-5xl md:mr-5 mr-3">
                    <AiOutlinePlusCircle />
                </span>
                <span className="font-bold md:text-3xl text-xl">{t('Dashboard.admin.add_theme')}</span>
            </button>
            {themes.map((theme, index) => (
                <ThemeCard key={index} theme={theme} onEdit={(theme) => { handleEditTheme(theme) }} onDelete={(theme) => { handleDeleteTheme(theme) }} />
            ))}
        </>
    )
}

export default Themes;