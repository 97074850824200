import { currentLanguage } from "../../Config/i18n";

const PropertyTypeCard = ({ property, selectedProperties, onClick, disabled = false }) => 
{
    return (
        <div
            className={`${
                selectedProperties.includes(property.id)
                ? "border-primary bg-lightPrimary border-2 rounded-xl"
                : "grayscale border-transparent"
            } p-2`}
        >
            <button
                onClick={() => { onClick(property.id) }}
                type="button"
                className={`focus:outline-none mx-auto flex items-center flex-col ${disabled && 'cursor-not-allowed'}`}
            >
                <div className="flex justify-center items-center bg-white border-primary border-2 rounded-full lg:p-0 p-2 w-24 h-24 md:w-32 md:h-32 overflow-hidden">
                    <img
                        src={`/svg/properties/${property.image}`}
                        alt="Property Type Option"
                        loading="lazy"
                    />
                </div>
                <p
                    className={`text-lg md:text-xl ${
                        selectedProperties.includes(property.id)
                        ? "text-primary font-bold"
                        : "text-black/60"
                    } mt-2 md:mt-5`}
                >
                    {currentLanguage() === 'en' ? property.name : property.name_pt}
                </p>
            </button>
        </div>
    )
}

export default PropertyTypeCard;