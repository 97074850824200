
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper";
import alti from '../../assets/CompanyLogos/alti.png';
import century21 from '../../assets/CompanyLogos/century-21.png';
import coldwell from '../../assets/CompanyLogos/coldwell.png';
import engel from '../../assets/CompanyLogos/engel.png';
import exp from '../../assets/CompanyLogos/exp.png';
import fineCountry from '../../assets/CompanyLogos/fine-country.png';
import habisale from '../../assets/CompanyLogos/habisale.png';
import jll from '../../assets/CompanyLogos/jll.png';
import keller from '../../assets/CompanyLogos/keller.png';
import maisconsultores from '../../assets/CompanyLogos/maisconsultores.png';
import portaDaFrente from '../../assets/CompanyLogos/porta-da-frente.png';
import remax from '../../assets/CompanyLogos/remax.png';
import "../../css/custom-swiper.css"

const CompanyLogoSlider = () => {

    const { t } = useTranslation();

    const swiperRef = useRef(null)

    // const handlePrev = useCallback(() => {
    //     if (!swiperRef.current) return;
    //     swiperRef.current.swiper.slidePrev();
    // }, []);

    // const handleNext = useCallback(() => {
    //     if (!swiperRef.current) return;
    //     swiperRef.current.swiper.slideNext();
    // }, []);

    const logos = [alti, century21, coldwell, engel, exp, fineCountry, habisale, jll, keller, maisconsultores, portaDaFrente, remax]

    return (
        <section className="mx-auto py-10 space-y-10">
            <h2 className='font-bold text-lg md:text-2xl leading-tight text-center'>{t('Homepage.company_logos.title')}</h2>
            <Swiper
                autoplay={{
                    delay: 0,
                    disableOnInteraction: false,
                }}
                freeMode
                loop
                speed={20000}
                spaceBetween={1}
                ref={swiperRef}
                className='mySwiper'
                modules={[Navigation, Autoplay]}
                slidesPerView={5}
                slidesPerGroup={5}
                breakpoints={{
                    320: {
                        slidesPerView: 1.5,
                        slidesPerGroup: 1.5,
                    },
                    768: {
                        slidesPerView: 2.5,
                        slidesPerGroup: 2.5,
                    },
                    1024: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                    }
                }}
            >
                { logos.map((logo, index) => {
                    return (
                        <SwiperSlide key={index} className='flex items-center'>
                            <img className='max-w-40 max-h-20' src={logo} />
                        </SwiperSlide>
                    )})
                }
            </Swiper>
        </section>
    )
}

export default CompanyLogoSlider;