import React, { useState, useEffect } from 'react';
import NextStepArrow from '../../../assets/Pricing/NextStepArrow.png';
import NextStepArrowUnselected from '../../../assets/Pricing/NextStepArrowUnselected.png';
import useAuthContext from '../../Config/AuthContext';
import ErrorMessage from '../../UI/ErrorMessage';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import InputMask from "react-input-mask";

const StepBusinessForm = ({ nextStep, prevStep, setData }) => {

    const { errors } = useAuthContext();

    const { t } = useTranslation();

    const [trademark, setTrademark] = useState('');
    const [corporateName, setCorporateName] = useState('');
    const [ami, setAmi] = useState('');
    const [email, setEmail] = useState('');
    const [phoneIndex, setPhoneIndex] = useState('+351');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [billingCorporateName, setBillingCorporateName] = useState('');
    const [billingNif, setBillingNif] = useState('');
    const [billingHeadquartersAddress, setBillingHeadquartersAddress] = useState('');
    const [billingCity, setBillingCity] = useState('');
    const [billingPostalCode, setBillingPostalCode] = useState('');
    const [billingEmail, setBillingEmail] = useState('');

    const [sameAddress, setSameAddress] = useState(false);

    const submitForm = () => {
        let formData = {
            trademark: trademark,
            corporate_name: corporateName,
            ami: ami,
            company_email: email,
            company_phone_country_code: `${phoneIndex}`,
            company_phone: phoneNumber.replace(/\s/g, ''),
            address: companyAddress,
            city: city,
            postal_code: postalCode,
            billing_corporate_name: billingCorporateName,
            billing_nif: billingNif.replace(/\s/g, ''),
            billing_address: billingHeadquartersAddress,
            billing_city: billingCity,
            billing_postal_code: billingPostalCode,
            billing_email: billingEmail,
            //TODO criar campo para submissão do logo da empresa
            logo: 'placeholder.png',
        }
        setData(formData);
    }

    useEffect(() => {
        if(sameAddress){
            setBillingCity(city);
            setBillingHeadquartersAddress(companyAddress);
            setBillingPostalCode(postalCode);
        }
    }, [sameAddress])

    return (

        <>
            <section>
                {<div className='flex justify-center mt-2 lg:mt-20 mb-2 mx-auto'>
                    <button className='w-20 lg:w-full rotate-180' onClick={() => { prevStep() }}><img className='mx-auto' src={NextStepArrow} alt="Previous Step" /></button>
                </div> }
                <div className='flex flex-col mt-10'>
                    <div className='flex justify-center'>
                        <h1 className='text-primary lg:text-lg'><span className='font-bold'>{t('Pricing.Step3BusinessTitleHighlight')}</span>{t('Pricing.Step3BusinessTitle')}</h1>
                    </div>
                    <div>
                        <div className='md:grid md:grid-cols-10 flex flex-col justify-center my-5'>
                            <div className='flex md:flex-row flex-col lg:col-start-4 lg:col-end-8 md:col-start-3 md:col-end-9 items-center justify-between'>
                                <h1 className='text-black font-bold text-2xl lg:text-3xl'>{t('Pricing.Step3BusinessSubTitle1')}</h1>
                                <p className='text-primary lg:text-lg font-bold'>{t('Pricing.Step3BusinessSubTitle2')}</p>
                            </div>
                        </div>
                        <div className='md:grid md:grid-cols-10 flex flex-col justify-center md:p-0 px-10'>
                            <form className='flex flex-col xl:col-start-5 lg:col-start-4 md:col-start-3 xl:col-end-7 lg:col-end-8 md:col-end-9'>
                                <input onChange={(e) => { setTrademark(e.target.value) }} value={trademark} className='my-2 p-2 border-[1px] shadow-md rounded-md border-fifth lg:text-lg placeholder:text-grey' type='text' placeholder={t('Pricing.Step3BusinessInput1Trademark')} required />
                                <ErrorMessage errors={errors.trademark} />
                                <input onChange={(e) => { setCorporateName(e.target.value) }} value={corporateName} className='my-2 p-2 border-[1px] shadow-md rounded-md border-fifth lg:text-lg placeholder:text-grey' type='text' placeholder={t('Pricing.Step3BusinessInput2CorpName')} required />
                                <ErrorMessage errors={errors.corporate_name} />
                                <input onChange={(e) => { setAmi(e.target.value) }} value={ami} className='my-2 p-2 border-[1px] shadow-md rounded-md border-fifth lg:text-lg placeholder:text-grey' type='number' placeholder={t('Pricing.Step3BusinessInput3Ami')} required />
                                <ErrorMessage errors={errors.ami} />
                                <input onChange={(e) => { setEmail(e.target.value) }} value={email} className='my-2 p-2 border-[1px] shadow-md rounded-md border-fifth lg:text-lg placeholder:text-grey' type='email' placeholder={t('Pricing.Step3BusinessInput4CompEmail')} required />
                                <ErrorMessage errors={errors.company_email} />
                                <div className='flex my-2'>
                                    <PhoneInput
                                        country={'pt'}
                                        value={phoneIndex}
                                        onChange={(code) => setPhoneIndex(code)}
                                        containerClass='shadow-md !w-2/5 !md:w-1/5'
                                        inputStyle={{ height: '100%', width: '100%' }}
                                        enableSearch={true}
                                        disableDropdown={false}
                                        inputProps={{ readOnly: true }}
                                    />
                                    <InputMask mask="999 999 999" onChange={(e) => { setPhoneNumber(e.target.value) }} value={phoneNumber} className='ml-2 w-2/3 p-2 border-[1px] shadow-md rounded-md border-fifth lg:text-lg placeholder:text-grey' type='text' placeholder={t('Pricing.Step3BusinessInput6CompPhone')} required />
                                </div>
                                <ErrorMessage errors={errors.company_phone} />
                                <input onChange={(e) => { setCompanyAddress(e.target.value) }} value={companyAddress} className='my-2 p-2 border-[1px] shadow-lg rounded-md border-fifth lg:text-lg placeholder:text-grey' type='text' placeholder={t('Pricing.Step3BusinessInput7CompAddress')} required />
                                <ErrorMessage errors={errors.address} />
                                <div className='flex my-2 space-x-4'>
                                    <div className='w-1/2'>
                                        <input onChange={(e) => { setCity(e.target.value) }} value={city} className='w-full p-2 border-[1px] shadow-md rounded-md border-fifth lg:text-lg placeholder:text-grey' type='text' placeholder={t('Pricing.Step3BusinessInput8CompCity')} required />
                                        <ErrorMessage errors={errors.city} />
                                    </div>
                                    <div className='w-1/2'>
                                        <InputMask mask="9999-999" onChange={(e) => { setPostalCode(e.target.value) }} value={postalCode} className='w-full p-2 border-[1px] shadow-md rounded-md border-fifth lg:text-lg placeholder:text-grey' type='text' placeholder={t('Pricing.Step3BusinessInput9CompPostCode')} required />
                                        <ErrorMessage errors={errors.postal_code} />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='mt-10 lg:mt-28'>
                        <div className='md:grid md:grid-cols-10 flex flex-col justify-center my-5'>
                            <div className='flex md:flex-row flex-col lg:col-start-4 lg:col-end-8 md:col-start-3 md:col-end-9 items-center justify-between'>
                                <h1 className='text-black font-bold text-2xl lg:text-3xl'>{t('Pricing.Step3BusinessBillingTitle')}</h1>
                                <p className='text-primary lg:text-lg font-bold'>{t('Pricing.Step3BusinessBillingSubTitle')}</p>
                            </div>
                        </div>
                        <div className='md:grid md:grid-cols-10 flex flex-col md:p-0 px-10 justify-center'>
                            <form className='flex flex-col xl:col-start-5 lg:col-start-4 md:col-start-3 xl:col-end-7 lg:col-end-8 md:col-end-9'>
                                <input onChange={(e) => { setBillingCorporateName(e.target.value) }} value={billingCorporateName} className='my-2 p-2 border-[1px] shadow-md rounded-md border-fifth lg:text-lg placeholder:text-grey' type='text' placeholder={t('Pricing.Step3BusinessBillingInput1BillCorpName')} required />
                                <ErrorMessage errors={errors.billing_corporate_name} />
                                <InputMask mask="999 999 999" onChange={(e) => { setBillingNif(e.target.value) }} value={billingNif} className='my-2 p-2 border-[1px] shadow-md rounded-md border-fifth lg:text-lg placeholder:text-grey' type='text' placeholder={t('Pricing.Step3BusinessBillingInput2BillNipc')} required />
                                <ErrorMessage errors={errors.billing_nif} />
                                <div className='text-sm lg:text-base my-2 lg:my-0'>
                                    <label>
                                        <input className='mr-2 accent-current' type="checkbox" onClick={(e) => {e.target.checked ? setSameAddress(true) : setSameAddress(false)}} />{t('Pricing.Step3BusinessBillingInput3BillCheck')}
                                    </label>
                                </div>
                                <input onChange={(e) => { setBillingHeadquartersAddress(e.target.value) }} value={sameAddress ? companyAddress : billingHeadquartersAddress} className='my-2 p-2 border-[1px] shadow-md rounded-md border-fifth lg:text-lg placeholder:text-grey' type='text' placeholder={t('Pricing.Step3BusinessBillingInput4BillHqAddress')}  required />
                                <ErrorMessage errors={errors.billing_address} />
                                <div className='flex space-x-4'>
                                    <div className='w-1/2'>
                                        <input onChange={(e) => { setBillingCity(e.target.value) }} value={sameAddress ? city : billingCity} className='w-full my-2 p-2 border-[1px] shadow-md rounded-md border-fifth lg:text-lg placeholder:text-grey' type='text' placeholder={t('Pricing.Step3BusinessBillingInput5BillCity')} required />
                                        <ErrorMessage errors={errors.billing_city} />
                                    </div>
                                    <div className='w-1/2'>
                                        <InputMask mask="9999-999" onChange={(e) => { setBillingPostalCode(e.target.value) }} value={sameAddress ? postalCode : billingPostalCode} className='w-full my-2 p-2 border-[1px] shadow-md rounded-md border-fifth lg:text-lg placeholder:text-grey' type='text' placeholder={t('Pricing.Step3BusinessBillingInput6BillPostCode')} required />
                                        <ErrorMessage errors={errors.billing_postal_code} />
                                    </div>
                                </div>
                                <input onChange={(e) => { setBillingEmail(e.target.value) }} value={billingEmail} className='my-2 p-2 border-[1px] shadow-md rounded-md border-fifth lg:text-lg placeholder:text-grey' type='email' placeholder={t('Pricing.Step3BusinessBillingInput7BillEmail')} required />
                                    <ErrorMessage errors={errors.billing_email} />
                            </form>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center my-10 items-center mx-auto'>
                    <button className='w-20 lg:w-full' type='submit' onClick={ submitForm } ><img className='mx-auto' src={NextStepArrowUnselected} alt="Next Step" /></button>
                </div>
            </section>

        </>
    )
}


export default StepBusinessForm