import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
import { SlArrowLeft, SlArrowRight } from "react-icons/sl"

const Pagination = ({ itemCount, itemsPerPage, onPageChange }) => {

    const items = [...Array(itemCount).keys()].map(page => page + 1);

    const [itemOffset, setItemOffset] = useState(itemsPerPage ?? 0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = itemCount;

    const handlePageChange = (event) => {
        window.scrollTo(0, 0)
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
        onPageChange(event.selected + 1)
    }

    return (
        <>
        { itemCount > 1 &&
            <ReactPaginate
                breakLabel="..."
                nextLabel={
                    <button
                        className='flex items-center justify-center ml-5 text-xl transition-colors duration-200 ease-in-out'
                    >
                        <SlArrowRight />
                    </button>
                }
                onPageChange={handlePageChange}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel={
                    <button
                        className='flex items-center justify-center mr-5 text-xl transition-colors duration-200 ease-in-out'
                    >
                        <SlArrowLeft />
                    </button>
                }
                renderOnZeroPageCount={null}
                className='w-full flex items-center justify-center mt-10'
                activeClassName='bg-primary text-white'
                pageClassName='flex items-center justify-center rounded-full w-8 h-8 text-center text-lg hover:bg-disabled hover:text-white transition-all duration-200 ease-in-out'
                disabledClassName='text-disabled'
            />
        }
        </>
    )
};

export default Pagination;