import React, { useState, useEffect } from "react";
import { API_PATHS, COMPANY_ROLES } from "../../../constants/global";
import { AiOutlineEye } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import CreatableSelect from "react-select/creatable";
import { currentLanguage } from "../../Config/i18n";
import axios from "../../Config/axios";
import { useAlert } from "../../../contexts/AlertContext";

const TableViewCard = ({ currentUser, member, isAdmin, onEdit, onDelete, roleOptions }) => {

	const { toggleLoader, triggerPopup } = useAlert();

	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);
	const [role, setRole] = useState({id: member.company_role?.id, title: member.company_role?.title, title_pt: member.company_role?.title_pt ?? member.company_role?.title });

	const customSelect = {
		control: (provided, state) => ({
			...provided,
			borderWidth: "2px",
			borderColor: "#e5e7eb",
			width: "100%",
			borderRadius: "0.375rem"
		})
	};

	const onChange = async (e) => {
		setIsLoading(true);
		let newRole = {
			'id': e?.id,
			'title': e?.title,
			'title_pt': e?.title_pt
		}
		setRole(newRole)

		await axios
			.post('api/company/change-user-role', {
				user_id: member.id,
				role_id: newRole.id
			})
			.then((response) => {
				triggerPopup('Popups.changes_saved');
			})
			.catch((error) => {
				triggerPopup('Popups.something_went_wrong');
				console.log(error)
			})
			.finally(() => {
				setIsLoading(false);
			})
	}

	useEffect(() => {
		toggleLoader(isLoading);
	}, [isLoading]);

	return (
		<>
			{
				<div
					key={member.id}
					className={`${
						member.id === currentUser.id ? "bg-black/5" : ""
					} rounded-2xl shadow-custom min-w-72 p-5 pb-10`}
				>
					<div className="flex items-center justify-between w-full mb-5">
						{isAdmin && (
							<CreatableSelect
								getOptionValue={option => option.id}
								getOptionLabel={option => currentLanguage() === 'en' ? option.title : option.title_pt ?? option.title }
								options={roleOptions}
								onChange={(e) => { onChange(e) }}
								styles={customSelect}
								value={role}
								isDisabled={currentUser?.id == member?.id}
							/>
						)}

						{isAdmin && (
							<button
								disabled={currentUser?.id == member?.id}
								onClick={onDelete}
								className={`${currentUser?.id == member?.id ? 'text-primary/40' : 'text-primary'} text-xl 2xl:text-2xl`}
							>
								<BsTrashFill />
							</button>
							)
						}
					</div>

					<div className="w-14 h-14 md:w-16 md:h-16 lg:w-20 lg:h-20 rounded-full mx-auto">
						<img
							src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${member.profile_image}`}
							alt="User Profile"
							className="w-full h-full rounded-full object-cover"
						/>
					</div>
					<p className="text-lg md:text-xl lg:text-2xl font-bold text-center">{member.first_name}</p>
					<p className="break-all text-center text-sm lg:text-base">
						<span>{member.email}</span>
					</p>
					<p className="break-all text-center text-sm lg:text-base">
						{t('Dashboard.team.phone')} <span>{member.phone}</span>
					</p>
					<div className="flex justify-around items-center w-full my-1 xl:my-2">
						{(isAdmin || currentUser.id === member.id) && (
							<button onClick={onEdit} className="flex text-primary items-center text-sm lg:text-base">
								<p className="text-lg lg:text-2xl mx-2">
									<AiOutlineEye />
								</p>
								{t('Dashboard.team.view')}
							</button>
						)}
					</div>
				</div>
			}
		</>
	);
};

export default TableViewCard;
