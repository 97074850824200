import { useParams } from "react-router";
import { currentLanguage } from '../../Config/i18n';

const ChatCard = ({ data, authUser, onClick, isSelected }) => {
	const { id } = useParams();

	const date = new Date();
	const yyyy = date.getFullYear();
	let mm = date.getMonth() + 1; // Months start at 0!
	let dd = date.getDate();
	
	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;
	
	const today = dd + '/' + mm + '/' + yyyy;

	return (
		<>
			<button
				onClick={() => { onClick() }}
				className={`${ isSelected === data.id && "font-bold bg-primary/10 border-primary"
					// ? "font-bold bg-primary/10 border-primary"
					// : (!data.has_messages && id != data.request_id) && "hidden"
				} grid grid-cols-4 px-3 xl:px-4 2xl:px-6 border-b-2 h-28`}
			>
				<div className="relative col-span-1 my-auto">
					{(data.consultant || (data.buyer && data.buyer?.id != authUser?.id)) &&
						<div className="absolute top-0 left-0 overflow-hidden rounded-full w-6 h-6 xl:w-8 xl:h-8 2xl:h-10 2xl:w-10">
							<img
								src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${data.consultant?.image ?? data.buyer?.image}`}
								alt=""
								className="object-cover w-full h-full"
							/>
						</div>
					}
					<div className={`${data.consultant || (data.buyer && data.buyer?.id != authUser?.id) ? 'mx-auto' : ''} overflow-hidden rounded-full w-10 h-10 lg:w-12 lg:h-12 2xl:w-16 2xl:h-16`}>
						<img
							src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${data.owner.image}`}
							alt=""
							className="object-cover w-full h-full"
						/>
					</div>
				</div>

				<div className="col-span-2 my-auto">
					<h1 className=" 2xl:text-2xl text-left">
						{ data.owner.name }
					</h1>
					<div className="px-1 lg:px-0 text-start text-black/60 text-xs lg:text-md">
						<p>
							{currentLanguage() === 'en' ? data.property_name : data.property_name_pt}{" "}
						</p>
						<p  className="line-clamp-1">
							{data.location === "" || data.location === undefined
							? ""
							: "in " + data.location}
						</p>
						<p className="text-xs">ID {data.request_id}</p>
					</div>
				</div>

				<div className="col-span-1 text-primary items-end my-auto">
					<div className="text-end text-xs lg:text-md 2xl:text-base">
						<p>{ data.updated_at_date !== today && data.updated_at_date }</p>
						<p>{ data.updated_at_hour }</p>
						{data.unread_messages > 0 && data.latest_message_creator != authUser.id && isSelected !== data.id &&
							<div className="flex items-center justify-center rounded-full bg-primary text-white text-xs w-4 h-4  ml-auto">
								<p>{ data.unread_messages }</p>
							</div>
						}
					</div>
					<div className="flex items-center justify-end text-xs lg:text-md xl:text-base">
						{/* <p className="bg-primary shadow-md text-white flex justify-center items-center w-4 h-4 xl:w-5 xl:h-5 rounded-full">
							3
						</p> */}
						{/* <div className="text-lg">
							<BsPaperclip />
						</div>
						<p>1</p> */}
					</div>
				</div>
			</button>
		</>
	);
};

export default ChatCard;
