import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { currentLanguage } from '../Config/i18n';
import { useAlert } from "../../contexts/AlertContext";
import axios from "../Config/axios";
import ErrorMessage from "../UI/ErrorMessage";
import LocationIcon from "../../assets/Dashboard/Requests/RequestLocationIcon.png";

const SaveBuyerMatchModal = ({ search, onClose }) =>
{

    const { t } = useTranslation()
    const { toggleLoader, triggerPopup } = useAlert();
    const [name, setName] = useState(null)
    const [errors, setErrors] = useState(null)
    const [locations, setLocations] = useState(null)
    const [propertyIcon, setPropertyIcon] = useState('multiple.svg')
    const [isLoading, setIsLoading] = useState(false)

    const saveSearch = async () => {
        // setIsLoading(true)
        const formData = {
            name,
            ...search
        }

        await axios.post("/api/buyer-matches", {
            ...formData
        })
        .then((response) => {
            // setIsLoading(false)
            triggerPopup('Popups.changes_saved')
            onClose()
        })
        .catch((e) => {
            setErrors(e.response.data.errors)
            if (search.priceFrom.value > 999999999999999) {
                triggerPopup('Popups.budget_above_quadrilion')
            } else if (search.areaFrom.value > 999999999999999) {
                triggerPopup('Popups.area_above_quadrilion');
            } else {
                triggerPopup('Popups.something_went_wrong')
            }
        })
        .finally(() => {
            // setIsLoading(false)
        });
    }

    useEffect(() => {
        if (search && search?.locations) {
            let locations = []
            if (search?.locations?.districts?.length > 0) {
                search?.locations?.districts?.map((location) => {
                    locations.push(location?.name)
                })
            }
            if (search?.locations?.municipalities?.length > 0) {
                search?.locations?.municipalities?.map((location) => {
                    locations.push(location?.name)
                })
            }
            if (search?.locations?.parishes?.length > 0) {
                search?.locations?.parishes?.map((location) => {
                    locations.push(location?.name)
                })
            }
            if (search?.locations?.zones?.length > 0) {
                search?.locations?.zones?.map((location) => {
                    locations.push(location?.name)
                })
            }
            setLocations(locations.join(', '))
        }
        if (search && search?.propertyType) {
            if ((search?.propertyType?.name)?.toLowerCase() == "new construction")
            {
                setPropertyIcon("construction.png")
            }
            else if ((search?.propertyType?.name)?.toLowerCase() == "commercial space")
            {
                setPropertyIcon("store.svg")
            } else {
                setPropertyIcon((search?.propertyType?.name)?.toLowerCase()+'.svg')
            }
        }
    }, [search])

	useEffect(() => {
		toggleLoader(isLoading)
	}, [isLoading])

    return (
        <>
            <div className='fixed top-0 left-0 h-screen w-screen bg-black/10 flex justify-center items-center'>
                <div className='w-[80%] md:w-[35vw] bg-white rounded-3xl flex flex-col p-6 lg:p-10 gap-5'>
                    
                    <div className="text-lg md:text-2xl mx-auto font-bold">
                        {t('Modals.buyer_match.save_search')}
                    </div>

                    <div className="space-y-2">
                        <input
                            onChange={(e) => {
                                setName(e.target.value);
                            }}
                            value={name}
                            className="w-full p-2 border shadow-lg rounded-md border-primary lg:text-lg"
                            type="text"
                            placeholder={t("Modals.buyer_match.name_placeholder")}
                        />
                        <ErrorMessage errors={errors?.name} />
                    </div>

                    <div className="text-sm">
                        {t('Modals.buyer_match.check_if_correct')}
                    </div>

                    <div className="p-4 border shadow-lg rounded-md border-primary space-y-5">
                        <div className="flex flex-col items-center justify-center">
                            <img
                                src={`/svg/properties/${propertyIcon}`}
                                alt="Property Type Icon"
                                className="max-w-40"
                            />

                            <div className="bg-primary text-white rounded-full text-sm w-fit px-2 mb-2">
                                {currentLanguage() === 'en' ? search?.requestType?.name : search?.requestType?.name_pt}
                            </div>

                            <div className="flex items-center text-primary">
                                <img
                                    src={LocationIcon}
                                    alt=""
                                    className="text-primary w-4 mr-2"
                                />
                                <div className="flex items-center gap-5">
                                    <div className="line-clamp-2">
                                        {locations ? (
                                            t('Dashboard.my_requests.in') + " " + locations
                                        ) : (
                                            t('Modals.buyer_match.any_location')
                                        )}
                                    </div>
                                    <div className="text-black">
                                        {search?.typology?.name ?? t('Modals.buyer_match.any_typology')}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="flex flex-col lg:flex-row items-center justify-center lg:gap-10 border-t border-b py-4">
                            <div className="text-zinc-400">
                                {t('Modals.buyer_match.min_budget')}
                                {search?.priceFrom?.label ? search?.priceFrom?.label + '€' : t('Modals.buyer_match.any_price')}
                            </div>
                            <div className="text-zinc-400">
                                {t('Modals.buyer_match.min_area')}
                                {search?.areaFrom?.label ? search?.areaFrom?.label + 'm2' : t('Modals.buyer_match.any_area')}
                            </div>
                        </div>
                        <div className="text-sm">
                            {t('Modals.buyer_match.warning')}
                        </div>
                    </div>


                    <div className='flex flex-col lg:flex-row items-center justify-center lg:justify-between text-center'>
                        <button onClick={() => { onClose() } } className='bg-black text-white font-bold rounded-lg mb-2 md:my-3 w-fit md:w-2/5 p-2'>{t('Modals.cancel')}</button>
                        <button onClick={() => { saveSearch() }} className='bg-primary text-white font-bold rounded-lg md:my-3 w-fit md:w-2/5 p-2'>{t('Modals.buyer_match.save_search')}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SaveBuyerMatchModal