import PlansStore from "../../../components/Dashboard/Store/PlansStore";
import ScrollToTop from "../../../components/Router/ScrollToTop";

const Plans = () =>
{
    return (
        <>
            <ScrollToTop />
            <PlansStore />
        </>
    )
}

export default Plans;