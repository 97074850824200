import AdminNotifications from '../../components/Dashboard/Admin/Notifications';
import ScrollToTop from '../../components/Router/ScrollToTop';

const Notifications = () => {
    return (
        <>
            <ScrollToTop />
            <AdminNotifications />
        </>
    )
}

export default Notifications;