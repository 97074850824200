import { MdLocationOn } from "react-icons/md";
import { AsyncPaginate } from "react-select-async-paginate"
import { useTranslation } from "react-i18next";
import useAuthContext from "../Config/AuthContext";
import { components } from 'react-select';

const LocationSearch = (props) => 
{
    const { t } = useTranslation();

    const { getLocations } = useAuthContext();

    const loadLocations = async (inputValue, prevOptions, { page }) => {
      if (!inputValue) {
          return { options: [], hasMore: false };
      }

      const response = await getLocations({ search: inputValue, page: page });

      return {
        options: response.data.data,
        hasMore:
        response.data?.meta?.current_page != response.data?.meta?.last_page &&
        response.data?.data.length > 0,
        additional: {
        page: page + 1,
        },
      };
    };

    const formatOptionLabel = (option) => (
        <div>
          {option.parish?.name || option.name
            ? (<strong>
              {option.name ? option.name+', ' : ''}{option.parish?.name}
            <br />
            </strong>)
            : ''
          }
          <p className="flex items-center gap-x-1">
            <MdLocationOn />
            {option.municipality?.name ? option.municipality?.name+', ' : ''}{option.district?.name}
          </p>
          <p className="font-bold text-black/40">
            {option.name 
                ? t('Dashboard.requests.zone')
                : option.parish?.name
                    ? t('Dashboard.requests.parish')
                    : option.municipality?.name
                        ? t('Dashboard.requests.municipality')
                        : t('Dashboard.requests.district')
            }
          </p>
        </div>
    );

    const Control = ({ children, ...provided }) => (
      <components.Control {...provided}>
        <MdLocationOn className={`${!props.showIcon && 'hidden'} text-primary ml-2`} />{children}
      </components.Control>
    );

    return (
        <AsyncPaginate
            isMulti
            loadOptions={ loadLocations }
            closeMenuOnSelect={false}
            debounceTimeout={1000}
            formatOptionLabel={ formatOptionLabel }
            value={ props.value }
            onChange={ props.onChange }
            getOptionValue={(option) => option.id ?? option.parish.id ?? option.municipality.id ?? option.district.id}
            additional={{ page: 1 }}
            placeholder={ props.placeholder }
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
              Control,
            }}
            styles={props?.style}
        />
    )
}

export default LocationSearch