import React, { useState, useEffect } from "react";
import { API_PATHS, COMPANY_ROLES } from "../../../constants/global";
import { AiOutlineEye } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { useTranslation } from 'react-i18next';
import { useAlert } from "../../../contexts/AlertContext";
import CreatableSelect from "react-select/creatable";
import { currentLanguage } from "../../Config/i18n";
import axios from "../../Config/axios";

const ListViewCard = ({ currentUser, member, isAdmin, onEdit, onDelete, roleOptions }) => {

	const { toggleLoader, triggerPopup } = useAlert();

	const { t } = useTranslation();

	const [isLoading, setIsLoading] = useState(false);
	const [role, setRole] = useState({id: member.company_role?.id, title: member.company_role?.title, title_pt: member.company_role?.title_pt ?? member.company_role?.title });

	const customSelect = {
		control: (provided, state) => ({
			...provided,
			borderWidth: "2px",
			borderColor: "#e5e7eb",
			width: "100%",
			borderRadius: "0.375rem"
		})
	};

	const onChange = async (e) => {
		setIsLoading(true);
		let newRole = {
			'id': e?.id,
			'title': e?.title,
			'title_pt': e?.title_pt
		}
		setRole(newRole)

		await axios
			.post('api/company/change-user-role', {
				user_id: member.id,
				role_id: newRole.id
			})
			.then((response) => {
				triggerPopup('Popups.changes_saved');
			})
			.catch((error) => {
				triggerPopup('Popups.something_went_wrong');
				console.log(error)
			})
			.finally(() => {
				setIsLoading(false);
			})
	}

	useEffect(() => {
		toggleLoader(isLoading);
	}, [isLoading]);

	return (
		<>
			{
				<div
					key={member.id}
					className={`${
						member.id === currentUser.id ? "bg-black/5 shadow-xl" : ""
					} flex w-full items-center justify-between px-4 md:px-8 border-b-2 py-5`}
				>
					<div className="flex items-center">
						<img
							src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${member.profile_image}`}
							className="w-10 h-10 md:w-14 md:h-14 mr-3 md:mr-5 rounded-full object-cover"
							alt="User Profile"
						/>
						<div className="flex flex-col md:flex-row md:items-center">
							<p className="font-bold md:text-3xl text-xl text-black mr-5">{member.first_name}</p>
							{isAdmin && (
								<CreatableSelect
									getOptionValue={option => option.id}
									getOptionLabel={option => currentLanguage() === 'en' ? option.title : option.title_pt ?? option.title }
									options={roleOptions}
									onChange={(e) => { onChange(e) }}
									styles={customSelect}
									value={role}
									isDisabled={currentUser?.id == member?.id}
								/>
							)}
						</div>
					</div>
					<div className="flex items-center">
						{(isAdmin || currentUser.id === member.id) && (
							<button onClick={onEdit} className="flex items-center text-primary md:mr-10">
								<p className="text-2xl mx-2">
									<AiOutlineEye />
								</p>
								<span className="hidden md:block">{t('Dashboard.team.view')}</span>
							</button>
						)}
						{isAdmin && member.company_role_id !== COMPANY_ROLES.ADMIN && (
							<button onClick={onDelete} className="flex items-center text-primary">
								<p className="text-xl">
									<BsTrashFill />
								</p>
							</button>
						)}
					</div>
				</div>
			}
		</>
	);
};

export default ListViewCard;
