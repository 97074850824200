import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../../../css/custom-swiper.css"
import { Navigation, Pagination } from "swiper";
import NunoFontouraImg from "../../../assets/Testimonials/nuno-fontoura.png";
import RosaPiresImg from "../../../assets/Testimonials/rosa-pires.png";
import HalynaImg from "../../../assets/Testimonials/halyna.png";
import EngelVolkerImg from "../../../assets/Testimonials/engel-volker.png";
import PortaDaFrenteImg from "../../../assets/Testimonials/porta-da-frente.png";
import XLBusinessImg from "../../../assets/Testimonials/xl-business.png";
import { useTranslation } from "react-i18next";
import TestimonialSwiperSlide from "./TestimonialSwiperSlide";
import { SlArrowLeft } from "react-icons/sl";

export default function App() {
  const { t } = useTranslation();

  return (
    <section id="testimonials" className="md:py-36 py-20 bg-white">
      <div className="text-center pb-10 md:pb-36">
        <h2 className="font-bold md:text-5xl text-3xl md:mx-0">
          {t("Businesses.CommunityTitle")}
        </h2>
      </div>
      <div className="flex justify-between items-center gap-5 md:gap-10 mx-2 lg:mx-20 xl:mx-40 text-black">
        <button className="custom-swiper-button-prev w-1/4 md:w-1/6 md:h-1/6 lg:w-1/12 lg:h-1/12">
          <SlArrowLeft 
            className="w-full h-full"
          />
        </button>
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          slidesPerGroup={3}
          loop={true}
          loopFillGroupWithBlank={true}
          navigation={{
            disabledClass: 'swiper-button-disabled',
            nextEl: '.custom-swiper-button-next',
            prevEl: '.custom-swiper-button-prev'
          }}
          modules={[Navigation]}
          className="mySwiper px-40"
          breakpoints={{
            0: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
            },
            1280: {
              slidesPerView: 2,
              slidesPerGroup: 3,
            },
            1440: {
              slidesPerView: 3,
              slidesPerGroup: 3,
            },
          }}
        >
          <SwiperSlide>
            <TestimonialSwiperSlide
              text={t("Testimonials.1.text")}
              name={t("Testimonials.1.name")}
              role={t("Testimonials.1.role")}
              img={NunoFontouraImg}
              logo={EngelVolkerImg}
            />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialSwiperSlide
              text={t("Testimonials.2.text")}
              name={t("Testimonials.2.name")}
              role={t("Testimonials.2.role")}
              img={RosaPiresImg}
              logo={XLBusinessImg}
            />
          </SwiperSlide>
          <SwiperSlide>
            <TestimonialSwiperSlide
              text={t("Testimonials.3.text")}
              name={t("Testimonials.3.name")}
              role={t("Testimonials.3.role")}
              img={HalynaImg}
              logo={PortaDaFrenteImg}
            />
          </SwiperSlide>
        </Swiper>
        <button className="custom-swiper-button-next w-1/4 md:w-1/6 md:h-1/6 lg:w-1/12 lg:h-1/12 rotate-180">
          <SlArrowLeft 
            className="w-full h-full"
          />
        </button>
      </div>
    </section>
  );
}
