import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Faq/Banner";
import Footer from "../components/Footer";
import FAQ1 from "../assets/Faq/FAQ1.png";
import FAQ2 from "../assets/Faq/FAQ2.png";
import FAQ3 from "../assets/Faq/FAQ3.png";
import FAQ4 from "../assets/Faq/FAQ4.png";
import FAQ5 from "../assets/Faq/FAQ5.png";
import FAQ7 from "../assets/Faq/FAQ7.png";
import FAQ8 from "../assets/Faq/FAQ8.png";
import FAQ9 from "../assets/Faq/FAQ9.png";
import FAQ10 from "../assets/Faq/FAQ10.png";
import FAQ11 from "../assets/Faq/FAQ11.png";
import FAQ12 from "../assets/Faq/FAQ12.png";
import NextStepArrow from "../assets/Faq/NextStepArrow.png";
import ScrollToTop from "../components/Router/ScrollToTop";
import Modal from "../components/Faq/Modal";
import { useTranslation } from "react-i18next";
import FaqCard from "../components/Faq/FaqCard";
import { Helmet } from "react-helmet";

const Faq = () => {
  const { t } = useTranslation();

  const [selectedModal, setSelectedModal] = useState(0);

  const hideModal = () => {
    setSelectedModal(0);
  };

  return (
    <>
      <Helmet>
        <title>Fortimy - FAQs</title>
        <meta name="description" content="O Portal dos pedidos imobiliários com acesso exclusivo a profissionais do ramo imobiliário" />
        <meta name="keywords" content="Fortimy, Imobiliário, Imóvel, Comprador, Inquilino, Propriedades, Consultor imobiliário" />
        <meta property="og:title" content="Fortimy - FAQs" />
        <meta property="og:description" content="O Portal dos pedidos imobiliários com acesso exclusivo a profissionais do ramo imobiliário" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Fortimy" />
        <meta property="og:locale" content="pt_PT" />
        <meta property="og:url" content="https://fortimy.com/faq" />
        <meta property="og:image" content="https://fortimy.com/logo2.png" />
        <meta property="og:image:secure_url" content="https://fortimy.com/logo2.png" />
        <meta property="article:publisher" content="https://www.facebook.com/fortimypt" />
        <meta name="publisher" content="https://www.facebook.com/fortimypt" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Fortimy - FAQs" />
        <meta name="twitter:description" content="O Portal dos pedidos imobiliários com acesso exclusivo a profissionais do ramo imobiliário" />
        <meta name="twitter:image" content="https://fortimy.com/logo2.png" />
      </Helmet>

      <ScrollToTop />
      <Navbar />
      <Modal
        handleClick={selectedModal}
        onClick={() => {
          hideModal();
        }}
      />
      <Banner />
      <section className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 md:justify-around gap-10 2xl:gap-x-16 px-10">
        <FaqCard
          index={1}
          imagePath={FAQ1}
          title={t("Faq.what_is_fortimy.title")}
          setSelectedModal={(index) => {
            setSelectedModal(index);
          }}
        />
        <FaqCard
          index={2}
          imagePath={FAQ2}
          title={t("Faq.to_who_is_fortimy_addressed.title")}
          setSelectedModal={(index) => {
            setSelectedModal(index);
          }}
        />
        <FaqCard
          index={3}
          imagePath={FAQ3}
          title={t("Faq.Faq3Title")}
          setSelectedModal={(index) => {
            setSelectedModal(index);
          }}
        />
        <FaqCard
          index={4}
          imagePath={FAQ4}
          title={t("Faq.Faq4Title")}
          setSelectedModal={(index) => {
            setSelectedModal(index);
          }}
        />
        <FaqCard
          index={5}
          imagePath={FAQ5}
          title={t("Faq.Faq5Title")}
          setSelectedModal={(index) => {
            setSelectedModal(index);
          }}
        />
        <FaqCard
          index={7}
          imagePath={FAQ7}
          title={t("Faq.Faq7Title")}
          setSelectedModal={(index) => {
            setSelectedModal(index);
          }}
        />
        <FaqCard
          index={8}
          imagePath={FAQ8}
          title={t("Faq.Faq8Title")}
          setSelectedModal={(index) => {
            setSelectedModal(index);
          }}
        />
        <FaqCard
          index={9}
          imagePath={FAQ9}
          title={t("Faq.Faq9Title")}
          setSelectedModal={(index) => {
            setSelectedModal(index);
          }}
        />
        <FaqCard
          index={10}
          imagePath={FAQ10}
          title={t("Faq.Faq10Title")}
          setSelectedModal={(index) => {
            setSelectedModal(index);
          }}
        />
        <FaqCard
          index={11}
          imagePath={FAQ11}
          title={t("Faq.Faq11Title")}
          setSelectedModal={(index) => {
            setSelectedModal(index);
          }}
        />
        <FaqCard
          index={12}
          imagePath={FAQ12}
          title={t("Faq.Faq12Title")}
          setSelectedModal={(index) => {
            setSelectedModal(index);
          }}
        />    
      </section>
      <div className="flex justify-center mt-20 mb-40">
        <button>
          <img alt="arrow" src={NextStepArrow} />
        </button>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
