import { useTranslation } from "react-i18next";
import useAuth from "../../../hooks/useAuth";
import { useState } from "react";
import StoreInfo from "../../Modals/StoreInfo";
import Highlights from '../../../assets/Dashboard/Store/Highlights.png';
import RequestsIcon from '../../../assets/Dashboard/Store/Requests.png';
import Users from '../../../assets/Dashboard/Store/Users.png';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from "react-router-dom";
import StoreItem from "./StoreItem";
import Modal from "../../Modals/Modal";
import { STORE_ITEMS } from "../../../constants/global";

const AddonsStore = () => 
{
    const { t } = useTranslation();
    const { auth } = useAuth();

    const [topHighlights, setTopHighlights] = useState(auth.top_highlights_number);
    const [colourHighlights, setColourHighlights] = useState(auth.colour_highlights_number);
    const [myRequests, setMyRequests] = useState(auth.request_number);
    const [users, setUsers] = useState(auth.users_number);
    const [openModal, setOpenModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const handleStoreInfo = (item) => {
        setOpenModal(true)
        setSelectedItem(item)
        console.log(item)
    }
    
    return (
        <>
            <Modal isOpen={ openModal }>
                <StoreInfo
                    selectedItem={selectedItem}
                    onClose={ () => { setOpenModal(false) } }
                />
            </Modal>

            <div className='bg-white shadow-custom rounded-2xl md:py-10 py-5 md:px-10 px-5 space-y-8'>
                <div className='flex justify-start border-b-2 md:pb-10 pb-5'>
                    <h1 className='text-3xl font-bold'>{t('Dashboard.store.store')}</h1>
                </div>

                <StoreItem
                    image={Highlights}
                    name={t('Dashboard.store.highlights')}
                    quantity={topHighlights}
                    minLimit={auth.top_highlights_number}
                    onChange={(e) => console.log(e)}
                    onInfo={() => handleStoreInfo(STORE_ITEMS.HIGHLIGHT)}
                />
                <StoreItem
                    image={Highlights}
                    name={t('Dashboard.store.colour_highlights')}
                    quantity={colourHighlights}
                    minLimit={auth.colour_highlights_number}
                    onChange={(e) => console.log(e)}
                    onInfo={() => handleStoreInfo(STORE_ITEMS.COLOUR_HIGHLIGHT)}
                />
                <StoreItem
                    image={RequestsIcon}
                    name={t('Dashboard.store.my_requests')}
                    quantity={myRequests}
                    minLimit={auth.request_number}
                    isRequest
                    onChange={(e) => console.log(e)}
                    onInfo={() => handleStoreInfo(STORE_ITEMS.REQUEST)}
                />
                <StoreItem
                    image={Users}
                    name={t('Dashboard.store.users')}
                    quantity={users}
                    minLimit={auth.users_number}
                    onChange={(e) => console.log(e)}
                    onInfo={() => handleStoreInfo(STORE_ITEMS.USER)}
                />

                <div className='w-full flex justify-end mt-5'>
                    <Link to="/app/store/cart" className="bg-primary py-2 px-10 rounded-full text-white font-bold text-lg">{t('Dashboard.store.go_to_cart')}</Link>
                    {/* <button onClick={() => { changeState(2) }} className='bg-primary py-2 px-10 rounded-full text-white font-bold text-lg'>Go to cart</button> */}
                </div>

            </div>
        </>
    )
}

export default AddonsStore;