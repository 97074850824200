import { useTranslation } from 'react-i18next'
import React from 'react'
import Logo from '../../../assets/Logo.png'

const FixedHeader = () => { 

  const {t} = useTranslation();

  return (
    <section className='flex justify-center items-center md:mt-16 mt-32'>
        <h1 className='flex text-2xl lg:text-3xl font-bold pt-2 pr-3'>{t('Pricing.Header')}</h1>
        <img alt='Fortimy Logo' className='w-28 lg:w-[8rem]' src={Logo} />
    </section>
  )
}

export default FixedHeader