import React from 'react';
import DashboardBuyerMatches from '../../components/Dashboard/BuyerMatches';
import ScrollToTop from '../../components/Router/ScrollToTop';
import useAuth from "../../hooks/useAuth";

const BuyerMatches = () => {
    const { auth } = useAuth();

    return (
        <>
            <ScrollToTop />
            <section>
                <DashboardBuyerMatches />
            </section>
        </>
    )
}

export default BuyerMatches