import React from 'react';
import Banner from '../../assets/Dashboard/Banner.png';
import DashboardNavbar from '../../components/Dashboard/Navbar';
import DashboardLearning from '../../components/Dashboard/Learning';
import ScrollToTop from '../../components/Router/ScrollToTop';
import ComingSoon from '../ComingSoon';
import useAuth from '../../hooks/useAuth';

const Learning = () => {
    const {auth} = useAuth();
    return (
        <>
        <ScrollToTop />
        <section>
            <DashboardLearning />
        </section>
        </>
    )
}

export default Learning