import React, { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { useAlert } from '../../contexts/AlertContext'

const Popup = () => {
    
    const { value, clearPopup } = useAlert()
    const { t } = useTranslation();

    useEffect(() => {
      const timer = setTimeout(() => {
        clearPopup()
      }, 4000)
    
      return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])
    

    return value ? (
        <div className='fixed w-screen z-[150] top-1/4 right-2 md:top-10 md:right-10 flex justify-end'>
            <div className='bg-primary text-white font-bold text-sm md:text-lg px-5 py-2 rounded-xl max-w-sm border-2 border-white'>
                {t(value)}
            </div>
        </div>
    ) : null
}

export default Popup