import { useEffect, useRef, useState } from "react";
import axios from "../../Config/axios";
import useAuth from "../../../hooks/useAuth";
import { useAlert } from "../../../contexts/AlertContext";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../../UI/ErrorMessage";

const BillingSettings = () => 
{
    const { auth } = useAuth();
    const { t } = useTranslation();
    const { toggleLoader, triggerPopup } = useAlert()
    const isMounted = useRef(false);

    const [company, setCompany] = useState({ id: auth.company_id })
    const [formData, setFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [errors, setErrors] = useState([]);

    const getCompany = async () =>
    {
        setIsLoading(true);
        await axios.get("/api/companies", { params: {id: auth.company_id} })
        .then((response) => {
            setCompany(response.data?.data[0]);
        })
        .catch((error) => {
            triggerPopup('Popups.something_went_wrong');
        })
        .finally(() => {
            setIsLoading(false);
        })
    }

    const submit = async () =>
    {
        const fd = new FormData();
        for (let key in formData) {
            fd.append(key, formData[key]);
        }

        setIsLoading(true);
        await axios.post(`/api/companies/${company?.id}`, fd)
        .then((response) => {
            setHasChanges(false);
            triggerPopup('Popups.changes_saved');
        })
        .catch((e) => {
            setErrors(e.response.data.errors);
            triggerPopup('Popups.something_went_wrong');
        })
        .finally(() => {
            setIsLoading(false);
        });
    }

    const handleChange = (e, field) => {
		setFormData({ ...formData, [field]: e.target.value });
	};

    useEffect(() => {
        toggleLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    useEffect(() => {
        if(isMounted.current) {
            setHasChanges(true);
        } else {
            isMounted.current = true;
        }
        toggleLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    useEffect(() => {
        getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className='bg-white shadow-custom rounded-2xl md:py-10 py-5 md:px-10 px-5 flex flex-col'>
                <div className='flex flex-col'>
                    <div className='flex justify-start border-b-2'>
                        <h1 className='text-2xl font-bold pb-5'>{t('Dashboard.settings.billing')}</h1>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center py-5 md:gap-x-14 md:gap-y-2 xl:gap-28'>
                        <div className='col-span-1 flex flex-col'>
                            <p className='text-lg py-2'>{t('Dashboard.settings.corporate_name')}</p>
                            <input onChange={(e) => handleChange(e, "corporate_name")} defaultValue={company?.corporate_name} type="text" className='border-2 shadow-sm rounded-md p-2' />
                            <ErrorMessage errors={errors.corporate_name} />
                        </div>
                        <div className='col-span-1 flex flex-col'>
                            <p className='text-lg py-2'>{t('Dashboard.settings.billing_nif')}</p>
                            <input onChange={(e) => handleChange(e, "billing_nif")} defaultValue={company?.billing_nif} type="text" className='border-2 shadow-sm rounded-md p-2' />
                            <ErrorMessage errors={errors.billing_nif} />
                        </div>
                        <div className='col-span-1 flex flex-col'>
                            <p className='text-lg py-2'>{t('Dashboard.settings.email')}</p>
                            <input onChange={(e) => handleChange(e, "billing_email")} defaultValue={company?.billing_email} type="text" className='border-2 shadow-sm rounded-md p-2' />
                            <ErrorMessage errors={errors.billing_email} />
                        </div>
                    </div>
                    <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center py-5 md:gap-x-14 md:gap-y-2 xl:gap-28'>
                        <div className='col-span-1 flex flex-col'>
                            <p className='text-lg py-2'>{t('Dashboard.settings.billing_address')}</p>
                            <input onChange={(e) => handleChange(e, "billing_address")} defaultValue={company?.billing_address} type="text" className='border-2 shadow-sm rounded-md p-2' />
                            <ErrorMessage errors={errors.billing_address} />
                        </div>
                        <div className='col-span-1 flex flex-col'>
                            <p className='text-lg py-2'>{t('Dashboard.settings.billing_city')}</p>
                            <input onChange={(e) => handleChange(e, "billing_city")} defaultValue={company?.billing_city} type="text" className='border-2 shadow-sm rounded-md p-2 px-3' />
                            <ErrorMessage errors={errors.billing_city} />
                        </div>
                        <div className='col-span-1 flex flex-col'>
                            <p className='text-lg py-2'>{t('Dashboard.settings.postal_code')}</p>
                            <input onChange={(e) => handleChange(e, "billing_postal_code")} defaultValue={company?.billing_postal_code} type="text" className='border-2 shadow-sm rounded-md p-2 px-3' />
                            <ErrorMessage errors={errors.billing_postal_code} />
                        </div>
                    </div>
                    
                    <div className='md:col-start-4 col-start-3 md:col-span-1 col-span-2 flex justify-end items-center md:mt-0 mt-5'>
                        <button onClick={() => { submit() }}
                            disabled={!hasChanges || isLoading}
                            className='btn-primary text-white font-bold rounded-md md:py-3 py-2 px-5 md:text-xl text-lg'
                        >
                            {t('Dashboard.settings.save_changes')}
                        </button>
                    </div>
                </div>
            </div>

            {/* <div className='flex flex-col mt-20'>
                <div className='flex justify-start border-b-2'>
                    <h1 className='text-3xl font-bold pb-3'>Invoices</h1>
                </div>
                <>
                    {
                        bills.map((element, index) => (
                            <div key={index} className='py-5 flex justify-between items-center border-b-2 hover:bg-black/5 px-5 group'>
                                <div className='flex items-center justify-between w-full'>
                                    <button className='flex shadow-lg bg-primary md:w-[110px] w-[80px] md:h-[50px] h-[35px] md:px-3 px-2 md:text-xl text-lg text-white group-hover:text-primary rounded-2xl justify-between items-center group-hover:bg-white'>
                                        PDF
                                        <div className='md:text-2xl text-xl text-white font-bold group-hover:text-primary'><BsDownload /></div>
                                    </button>
                                    <div className='flex flex-col'>
                                        <h1 className='text-black/60 font-bold md:text-xl text-lg'>Value</h1>
                                        <p className='md:text-xl text-lg text-black/60'>€ XXX</p>
                                    </div>
                                    <div className='flex flex-col items-center md:mx-5'>
                                        <h1 className='text-black/60 font-bold md:text-xl text-lg'>15 Dez 2022</h1>
                                        <p className='md:text-xl text-lg text-black/60'>F392048293</p>
                                    </div>
                                    <div className='md:flex flex-col items-center hidden'>
                                        <h1 className='text-black/60 font-bold md:text-xl text-lg'>Document Type</h1>
                                        <p className='md:text-xl text-lg text-black/60'>Invoice</p>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </>
            </div>
            <div className='grid grid-cols-3 mt-10'>
                <div className='col-start-2 col-span-1 text-2xl flex justify-center'>
                    Page 1 of 1
                </div>
                <div className='col-start-3 col-span-1 text-xl flex items-center justify-end'>
                    <h1 className='text-2xl'>Items per page</h1>
                    <select className='border-2 rounded-md px-5 ml-10' defaultdefaultValue={0}>
                        <option defaultValue={0}>10</option>
                        <option defaultValue={1}>25</option>
                        <option defaultValue={2}>50</option>
                        <option defaultValue={3}>100</option>
                    </select>
                </div>
            </div>
            <div className='bg-white shadow-custom rounded-2xl py-10 px-10 flex mt-20 justify-between'>
                <div className='flex flex-col items-center'>
                    <h1 className='text-2xl font-bold'>Next Payment</h1>
                    <p className='text-xl'>13 January 2023</p>
                </div>
                <div className='flex flex-col items-center'>
                    <h1 className='text-2xl font-bold'>**** **** **** 3729</h1>
                    <Link className='text-md underline text-primary'>Update method of payment</Link>
                </div>
                <div className='flex flex-col items-center'>
                    <h1 className='text-xl'>Your Plan <span className='text-2xl font-bold'>Plus</span></h1>
                    <Link className='text-md underline text-primary'>Upgrade Plan</Link>
                </div>
            </div> */}
        </>
    )
}

export default BillingSettings;