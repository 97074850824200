import React from 'react';
import AdminLearning from '../../components/Dashboard/Admin/Learning';
import ScrollToTop from '../../components/Router/ScrollToTop';

const Companies = () => {
    return (
        <>
            <ScrollToTop />
            <AdminLearning />
        </>
    )
}

export default Companies