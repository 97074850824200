import React from "react";

const PrivacyPolicy = () => {
    return (
    <>
        <section className="w-full flex flex-col p-10 pt-20 md:pt-10 space-y-10 md:px-40">
        <div className="space-y-10">
            <h1 className="text-primary text-4xl md:text-5xl font-bold leading-tight">
            Política de Privacidade
            </h1>
            <div className="space-y-4">
            <p>
                Fortimy (coletivamente, “Fortimy”, “Nós” ou “Nosso”) respeita a
                sua privacidade e está empenhada em proteger os seus dados
                pessoais. Esta política explica como tratamos os seus dados
                pessoais, protegemos a sua privacidade quando utiliza os nossos
                serviços e também fornece informações sobre os seus direitos ao
                abrigo da legislação aplicável. Leia atentamente esta política
                antes de nos fornecer qualquer informação pessoal. Ao utilizar os
                nossos Serviços, concorda que a Fortimy pode utilizar esses dados
                de acordo com a nossa política de privacidade.
            </p>
            <p>
                Esta política aplica-se a quem utilize qualquer
                website, aplicação, produto, software ou serviço da Fortimy (os
                nossos “Serviços”).
            </p>
            <p>
                Privacidade das crianças. Os nossos Serviços não são geralmente
                dirigidos a crianças e não se destinam a ser utilizados por
                crianças ou menores (menores de 18 anos), por isso não recolhemos
                intencionalmente os respetivos dados.
            </p>
            </div>
        </div>
        <div className="space-y-4">
            <h2 className="text-primary text-2xl md:text-3xl font-bold mb-2">
            Nome do controlador e informações de contato:
            </h2>
            <p>
            O Controlador dentro do Regulamento Geral de Proteção de Dados e do
            regulamento nacional de proteção de dados é:
            </p>
            <p>Fortimy, Lda.</p>
            <p>Morada: Rua do Mirante, Casa das Flores 25,</p>
            <p>Quinta da Marinha, 2750-004, Portugal</p>
            <p>
            E-mail:{" "}
            <a
                className="text-primary underline"
                href="mailto:privacy@fortimy.com"
            >
                privacy@fortimy.com
            </a>
            </p>
        </div>
        <div className="space-y-4">
            <h2 className="text-primary text-2xl md:text-3xl font-bold mb-2">Quem somos:</h2>
            <p>
            Fortimy é uma plataforma imobiliária que promove e organiza pedidos
            do mercado imobiliário, que lhe são inseridos, através de um
            Software como um Serviço (SaaS).
            </p>
        </div>
        <div className="space-y-4">
            <h2 className="text-primary text-2xl md:text-3xl font-bold mb-2">
            Como recolhemos as suas informações:
            </h2>
            <p>As suas informações são obtidas:</p>
            <ul className="list-disc list-inside text-black text-base font-normal">
            <li>
                <span className="font-bold">
                Através das suas interações connosco e com os nossos serviços,{" "}
                </span>
                como quando compra ou utiliza os nossos serviços, cria uma conta,
                preenche formulários de contacto, solicita informações ou
                nos contacta para suporte (note que podemos gravar ou monitorizar
                as nossas chamadas/ conversas de chat para fins de conformidade e
                garantia de qualidade);
            </li>
            <li>
                <span className="font-bold">
                Através do seu sistema/dispositivo e utilização dos Nossos
                Serviços.{" "}
                </span>
                Nossos servidores, logs e outras tecnologias coletam
                automaticamente informações de sistema/dispositivo e uso para nos
                ajudar a administrar, proteger e melhorar nossos Serviços,
                analisar o uso e melhorar a experiência dos usuários;
            </li>
            <li>
                <span className="font-bold">Através do seu endereço IP, </span>a
                fim de diagnosticar problemas com o Nosso servidor, administrar o
                site e acompanhar as estatísticas de utilização. Se chegou ao
                Nosso site clicando num link ou anúncio em outro site, então nós
                também registamos essa informação - isso ajuda-nos a maximizar a
                nossa exposição na Internet, e perceber os interesses dos
                utilizadores;
            </li>
            <li>
                <span className="font-bold">
                Através de cookies e tecnologias similares incluídas nos nossos
                Serviços,{" "}
                </span>
                à medida que interage com o nosso site, podemos recolher
                automaticamente dados técnicos sobre o seu equipamento, ações e
                padrões de navegação. Recolhemos estes dados através de cookies e
                tecnologias similares, como explicado mais detalhadamente na
                secção de Cookies e tecnologias similares.
            </li>
            </ul>
            <p>
            Também são recolhidas informações de terceiros, como por exemplo:
            </p>
            <ul className="list-disc list-inside text-black text-base font-normal">
            <li>
                <span className="font-bold">
                A(s) pessoa(s) que providenciou/aram o acesso aos Nossos
                Serviços{" "}
                </span>
                (por exemplo, o seu empregador ou o assinante do Serviço) a fim de
                criar uma conta de utilizador;
            </li>
            <li>
                <span className="font-bold">
                Uma organização à qual pertence, onde essa organização lhe dá
                acesso aos Nossos Serviços;
                </span>
            </li>
            <li>
                <span className="font-bold">
                Fornecedores/processadores de serviços que trabalham connosco em
                relação ao Nosso Serviço;
                </span>
            </li>
            <li>
                <span className="font-bold">
                Fontes disponíveis publicamente,{" "}
                </span>
                tais como websites públicos, bases de dados governamentais abertas
                ou outros dados no domínio público, para nos ajudar a manter a
                precisão dos dados e a fornecer e melhorar os Serviços.
            </li>
            </ul>
            <p>
            Pode escolher, se assim o desejar a possibilidade de não nos
            fornecer informações pessoais, mas, se optar por não o fazer, pode
            suceder que não consiga obter todas as funcionalidades dos Serviços.
            </p>
        </div>
        <div className="space-y-4">
            <h2 className="text-primary text-2xl md:text-3xl font-bold mb-2">
            Que informações recolhemos:
            </h2>
            <p>A informação pessoal que recolhemos consiste do seguinte:</p>
            <ul className="list-disc list-inside text-black text-base font-normal">
            <li>
                <span className="font-bold">Nome e dados de contacto, </span>
                tais como nome e apelido, endereço de e-mail, endereço postal,
                número de telefone, cargo, empregador e outros dados de contacto
                semelhantes;
            </li>
            <li>
                <span className="font-bold">Credenciais da conta, </span>como
                senhas e outras informações de segurança para autenticação e
                acesso;
            </li>
            <li>
                <span className="font-bold">Conteúdo do usuário, </span> tais como
                comunicações e arquivos fornecidos por si em relação à sua
                utilização dos Serviços;
            </li>
            <li>
                <span className="font-bold">
                Informações de pagamento/faturação,{" "}
                </span>
                tais como o endereço de faturação da sua empresa, número de IVA, e
                restantes informações de faturação;
                <ul className="list-disc list-inside text-black text-base font-normal ml-6">
                <li className="list-circle">
                    <span className="font-bold">Dados financeiros </span>
                    incluindo os detalhes da conta bancária;
                </li>
                </ul>
            </li>
            <li>
                <span className="font-bold">Informações do dispositivo, </span>
                tais como informações sobre o seu dispositivo, como endereço IP,
                localização ou provedor;
            </li>
            <li>
                <span className="font-bold">
                Informações de utilização e histórico de navegação,{" "}
                </span>
                tais como informações sobre a sua navegação dentro dos Nossos
                Serviços, o seu histórico de navegação e quais os elementos dos
                Nossos Serviços que mais utiliza;
            </li>
            <li>
                <span className="font-bold">Dados de localização, </span> para
                Serviços com recursos aprimorados de localização, como localização
                derivada do seu endereço IP ou dados que indicam onde está
                localizado com menos precisão, como no nível de uma cidade ou
                código postal;
            </li>
            <li>
                <span className="font-bold">Informação demográfica, </span> como o
                seu país e idioma de preferência.
            </li>
            </ul>
        </div>
        <div className="space-y-4">
            <p>Como e porque é que processamos as suas informações:</p>
            <p>
            Temos diferentes propósitos e fundamentos legais ao abrigo dos quais
            usamos e processamos as suas informações pessoais.
            </p>
            <p>
            Algumas leis exigem que expliquemos a nossa razão legal para
            processar as suas informações pessoais. Nós processamos as suas
            informações pessoais com base no seguinte:
            </p>
            <ul className="list-disc list-inside text-black text-base font-normal">
            <li>
                <span className="font-bold">
                O necessário para a execução do contrato:{" "}
                </span>{" "}
                Processaremos as suas informações pessoais para cumprir com o
                contrato que temos consigo (ou seja, para lhe prestarmos
                serviços);
            </li>
            <li>
                <span className="font-bold">
                No Nosso interesse ou em interesses legítimos de terceiros,{" "}
                </span>{" "}
                tais como a prestação de Serviços que somos contratualmente
                obrigados por terceiros;
            </li>
            <li>
                <span className="font-bold">
                Quando nos dá o seu consentimento:{" "}
                </span>{" "}
                Processamos informações pessoais com base no consentimento que nos
                dá expressamente no momento em que recolhemos tais informações;
            </li>
            <li>
                <span className="font-bold">
                Para o cumprimento de uma obrigação legal{" "}
                </span>{" "}
                (i.e., ordem judicial).
            </li>
            </ul>
            <h3 className="font-bold text-primary">
            Interesses Legítimos de Utilização
            </h3>
            <p>
            A base legal para processar suas informações sob interesse legítimo,
            são:
            </p>
            <ul className="list-disc list-inside text-black text-base font-normal">
            <li>
                Para o registar como novo cliente, gerir a sua conta, fornecer
                apoio técnico e formação, verificar a sua identidade e enviar-lhe
                informações importantes de Serviço;
            </li>
            <li>
                Para gerir a Nossa relação consigo, o Nosso negócio e os Nossos
                fornecedores (ou seja, para enviar faturas, cobrar e
                recuperar valores devidos a Nós, gerenciar pagamentos, taxas e
                encargos, etc.);
            </li>
            <li>
                Para melhorar a sua experiência de utilizador. Analisamos a forma
                como utiliza os Nossos Serviços para lhe fornecer sugestões de
                funcionalidades ou Serviços nos quais acreditamos que também
                estará interessado;
            </li>
            <li>
                Para fornecer a qualquer terceiro, que tenha disponibilizado os
                Nossos Serviços para si (ou seja, seu empregador ou nosso
                assinante), insights sobre o uso dos Serviços;
            </li>
            <li>
                Para fins de pesquisa e desenvolvimento interno e para melhorar,
                testar e aprimorar as características e funções dos Nossos
                Serviços. Toda a informação é recolhida e utilizada apenas no
                agregado. Por exemplo, podemos combinar as informações que nos
                forneceu diretamente com as informações coletadas automaticamente.
                Esta informação agregada é então introduzida na Nossa base de
                dados, onde podemos utilizá-la para gerar relatórios globais sobre
                os Nossos visitantes, mas não relatórios individuais que o
                identifiquem pessoalmente. Isto é necessário para os fins dos
                Nossos legítimos interesses para assegurar que lhe fornecemos as
                ofertas mais apropriadas e para personalizar a sua experiência;
            </li>
            <li>
                Onde aplicável, também agregaremos as suas informações pessoais
                com as de outros indivíduos, para criar relatórios abrangentes
                sobre como os clientes utilizam os Nossos serviços e a experiência
                da Nossa marca;
            </li>
            <li>
                Para entender como o nosso negócio está a funcionar, e
                considerando como melhorar o nosso desempenho;
            </li>
            <li>Para lhe fornecer marketing conforme permitido por lei;</li>
            <li>
                Para cumprir os Nossos requisitos de auditoria interna e externa,
                incluindo as Nossas obrigações de segurança da informação (e se o
                seu empregador ou o Nosso subscritor fornece o seu acesso aos
                Nossos Serviços, para cumprir os seus requisitos de auditoria
                interna e externa);
            </li>
            <li>
                Para fazer cumprir os Nossos termos e condições e com a finalidade
                de utilizar certos recursos de pagamento;
            </li>
            <li>
                Para proteger os Nossos direitos, privacidade, segurança, redes,
                sistemas e propriedade, ou os de outras pessoas
            </li>
            <li>
                Para cumprir as solicitações dos tribunais, agências de aplicação
                da lei, agências reguladoras e outras autoridades públicas e
                governamentais, incluindo quando estão fora do seu país de
                residência;
            </li>
            <li>
                A fim de exercer os Nossos direitos, e para nos defendermos de
                reclamações e cumprir as leis e regulamentos que se aplicam a Nós
                ou a terceiros com quem trabalhamos.
            </li>
            </ul>
            <p>
            Não recolhemos nem processamos as chamadas “categorias especiais de
            dados pessoais”, que de acordo com a GDPR incluem detalhes sobre a
            sua raça ou etnia, crenças religiosas, orientação sexual, opiniões
            políticas, saúde, dados biométricos, nem recolhemos qualquer
            informação sobre condenações criminais e delitos.
            </p>
            <p>
            Quando confiamos em interesses legítimos como base legal para
            processar as suas informações pessoais, equilibramos esses
            interesses com os seus interesses, direitos e liberdades
            fundamentais.
            </p>
        </div>
        <div className="space-y-4">
            <h2 className="text-primary text-2xl md:text-3xl font-bold mb-2">Marketing:</h2>
            <p>
            Entregamos-lhe comunicações de marketing via e-mail e online. Sempre
            que exigido por lei, pediremos o seu consentimento explícito para
            receber comunicações de marketing da nossa parte. Se lhe enviarmos
            uma comunicação de marketing, ela incluirá instruções sobre como
            optar por não receber essas comunicações no futuro, através de um
            link “unsubscribe” dentro de qualquer e-mail que receber de Nós -
            ficaremos tristes em vê-lo partir, mas respeitamos a sua
            privacidade.
            </p>
            <p>Quando optar por entrar:</p>
            <ul className="list-disc list-inside text-black text-base font-normal">
            <li>
                Enviaremos e-mails e mensagens de retenção adaptados às suas
                preferências e interesses individuais. Usamos a análise para
                refinar o nosso marketing - estes determinarão o conteúdo das
                mensagens e ofertas que receberá;
            </li>
            <li>
                Re-direcionamento. Com o seu consentimento, isto permite-nos
                mostrar aos visitantes já interessados nos Nossos Serviços,
                anúncios de Nós em sites parceiros. As tecnologias de Re-targeting
                analisam as informações que coletamos sobre suas interações
                connosco, incluindo seus cookies, e exibem anúncios com base em
                seu comportamento de navegação na web no passado;
            </li>
            <li>
                Utilizaremos as suas informações pessoais (inclusive
                anonimizando-as e agregando-as com as informações pessoais de
                outros clientes) para fins de vendas, cadeia de suprimentos,
                financeiros e de análise, para determinar como estamos atuando e
                onde podem ser feitas melhorias e, quando necessário, para relatar
                às empresas controladoras ou afiliadas do nosso grupo.
            </li>
            </ul>
            <p>
            Honrar as suas preferências de marketing é importante para nós. Como
            tal, tem o direito de optar por não receber marketing direto e
            publicidade online direcionada.
            </p>
            <p>
            Mesmo que opte por não receber comunicações de marketing por e-mail,
            podemos ainda assim enviar-lhe comunicações de serviço ou
            informações importantes relacionadas com as suas
            contas e subscrições (para tais fins, como a prestação de apoio ao
            cliente).
            </p>
        </div>
        <div className="space-y-4">
            <h2 className="text-primary text-2xl md:text-3xl font-bold mb-2">
            Os seus direitos
            </h2>
            <p>
            Pode atualizar, remover e/ou exercer outros direitos relacionados
            aos seus dados pessoais entrando em contato connosco pelo e-mail{" "}
            <a
                className="text-primary underline"
                href="mailto:privacy@fortimy.com"
            >
                privacy@fortimy.com.
            </a>
            </p>
            <p>
            O nosso objetivo final é assegurar os seus direitos ao abrigo das
            leis de proteção de dados aplicáveis:
            </p>
            <ul className="list-disc list-inside text-black text-base font-normal">
            <li>
                <span className="font-bold">Pedido de acesso aos dados:</span> o
                direito de solicitar e obter detalhes dos seus dados pessoais e
                receber uma cópia dos dados pessoais em processamento;
            </li>
            <li>
                <span className="font-bold">Direito de retificação:</span>{" "}
                atualizar os seus dados pessoais - o direito de obter sem demora
                indevida a retificação de quaisquer dados pessoais incompletos ou
                inexatos;
            </li>
            <li>
                <span className="font-bold">
                Direito de apagamento (direito a ser esquecido):
                </span>{" "}
                o direito a que certos dados pessoais sobre si sejam apagados e
                não mais processados quando os dados pessoais não forem mais
                necessários em relação às finalidades para as quais foram
                inicialmente coletados; quando o consentimento tiver sido retirado
                ou se opuser ao processamento; ou quando tal processamento não
                estiver de acordo com a legislação aplicável;
            </li>
            <li>
                <span className="font-bold">
                Direito à restrição de processamento:
                </span>{" "}
                o direito de solicitar que suas informações pessoais sejam
                utilizadas apenas para fins restritos;
            </li>
            <li>
                <span className="font-bold">
                Direito de não participar no marketing:
                </span>{" "}
                pode gerir as suas preferências de marketing anulando os links
                encontrados nas comunicações que recebe de Nós ou visitando o
                centro de preferências aplicável;
            </li>
            <li>
                <span className="font-bold">
                Direito à portabilidade dos dados:
                </span>{" "}
                o direito de solicitar que as informações pessoais que nos
                disponibilizou sejam transferidas para si ou para terceiros em
                formato legível por máquina (ou seja, ficheiro eletrónico);
            </li>
            <li>
                <span className="font-bold">Direito de objeção:</span> o direito
                de objeção ao processamento de suas informações pessoais nos casos
                em que nosso processamento se baseia no desempenho de uma tarefa
                realizada no interesse público ou informamos que o processamento é
                necessário para o nosso interesse legítimo ou de um terceiro.
            </li>
            </ul>
            <p>
            Em resposta a um pedido, pedimos-lhe para verificar a sua
            identidade, se necessário, e para fornecer informações que nos
            ajudem a compreender melhor o seu pedido. Se não atendermos ao seu
            pedido, no todo ou em parte, forneceremos uma explicação razoável e
            apropriada.
            </p>
        </div>
        <div className="space-y-4">
            <h2 className="text-primary text-2xl md:text-3xl font-bold mb-2">
            Com quem compartilhamos informações pessoais:
            </h2>
            <p>
            Partilhamos as suas informações para os fins estabelecidos nesta
            Política, com as seguintes categorias de destinatários:
            </p>
            <ul className="list-disc list-inside text-black text-base font-normal">
            <li>
                A pessoa que lhe dá acesso aos nossos Serviços (ou seja, o seu
                empregador ou o nosso subscritor);
            </li>
            <li>Terceiros, quando nos dá o seu consentimento para o fazer.</li>
            </ul>
            <h3 className="font-bold text-primary">Processadores</h3>
            <p>
            Para apoiar a prestação dos Nossos serviços, a Fortimy conta com
            prestadores de serviços. Qualquer terceiro contratado pela Fortimy
            que possa ter acesso ou processar dados que possam conter Dados
            Pessoais é considerado um processador. A Fortimy realiza uma revisão
            de segurança e privacidade das práticas de qualquer processador
            antes de se envolver com eles. Os nossos processadores atuais são:
            </p>
            <ul className="list-disc list-inside text-black text-base font-normal">
            <li>
                <span className="font-bold">Fortimy, Lda - </span> Prestadora de
                serviços
            </li>
            <li>
                <span className="font-bold">Invoice Express - </span>Software de
                faturação online
            </li>
            <li>
                <span className="font-bold">Stripe - </span>Serviços de pagamento
            </li>
            <li>
                <span className="font-bold">Flodesk - </span>Envio de campanhas por e-mail
            </li>
            <li>
                <span className="font-bold">Goggle Workspace - </span>Serviços de e-mail e envio de campanhas por e-mail.
            </li>
            <li>
                <span className="font-bold">Zoom Phone - </span>Sistema telefónico
                em cloud
            </li>
            <li>
                <span className="font-bold">Hubspot - </span> CRM de vendas
            </li>
            <li>
                <span className="font-bold">Zoom - </span> Sistema de reuniões e
                de mensagens
            </li>
            </ul>
            <p>
            Qualquer processador e/ou subcontratado utilizado pela Fortimy é
            submetido a um exame minucioso para avaliar as suas políticas de
            segurança, confidencialidade e privacidade.
            </p>
        </div>
        <div className="space-y-4">
            <h2 className="text-primary text-2xl md:text-3xl font-bold mb-2">
            Segurança dos dados:
            </h2>
            <p>
            A Fortimy encara a segurança das informações pessoais de forma séria e
            utilizamos tecnologias e procedimentos adequados para proteger as
            informações pessoais dos clientes, através de:
            </p>
            <ul className="list-disc list-inside text-black text-base font-normal">
            <li>
                Controlo de acesso: Todos os acessos aos produtos e serviços da
                Fortimy são criptografados e protegidos por firewall.
            </li>
            <li>
                Criptografia: todos os dados e comunicações estão sob rigorosos
                padrões de segurança, sendo transportados por um canal
                criptografado e seguro. Os dados também são criptografados em
                repouso, o que significa que os dados são armazenados em bancos de
                dados criptografados que seguem um alto nível de segurança de
                acesso;
            </li>
            <li>
                Acordo de não divulgação e treinamento de segurança: Todos os
                nossos membros e funcionários da Comunidade estão vinculados pela
                NDA e sujeitos a uma contínua consciencialização de conscientização de
                segurança.
            </li>
            </ul>
        </div>
        <div className="space-y-4">
            <h2 className="text-primary text-2xl md:text-3xl font-bold mb-2">
            Por quanto tempo guardamos informações pessoais?
            </h2>
            <p>
            Em geral, manteremos as suas informações pessoais pelo tempo
            necessário para o Serviço relevante, ou pelo tempo estabelecido em
            qualquer contrato relevante que tenha connosco e por um período de
            tempo razoável depois, por exemplo, para perseguir os nossos
            legítimos interesses comerciais, conduzir auditorias, cumprir com as
            nossas obrigações legais, resolver disputas e fazer cumprir os
            nossos acordos.
            </p>
            <p>
            Calculamos os períodos de retenção das suas informações pessoais
            de acordo com os seguintes critérios:
            </p>
            <ul className="list-disc list-inside text-black text-base font-normal">
            <li>
                O período de tempo necessário para cumprir os objetivos para os
                quais as recolhemos;
            </li>
            <li>
                Quando você ou o seu empregador (ou outro assinante que forneça o
                seu acesso aos nossos Serviços) deixar de utilizar os Nossos
                Serviços;
            </li>
            <li>
                A duração do tempo razoável para manter registos que demonstrem
                que cumprimos os nossos deveres e obrigações;
            </li>
            <li>Qualquer período de retenção prescrito por lei;</li>
            <li>A existência de qualquer procedimento relevante.</li>
            </ul>
            <p>
            Poderemos guardar as suas informações pessoais por um período mais
            curto se nos pedir para apagarmos essas informações. Nessa
            circunstância, a Fortimy procurará apagar as suas informações
            pessoais num período máximo de um mês a partir da confirmação de
            receção desse pedido.
            </p>
        </div>
        <div className="space-y-4">
            <h2 className="text-primary text-2xl md:text-3xl font-bold mb-2">
            Cookies e tecnologias similares:
            </h2>
            <p>
            Também colocamos um pequeno ficheiro de texto conhecido como
            “cookie” no disco rígido do seu computador. O uso de cookies e
            outras tecnologias de rastreamento é padrão entre sites e
            aplicativos através dos quais são coletadas informações sobre as
            suas atividades online entre aplicativos, sites ou outros serviços.
            </p>
            <p>
            Um cookie pode conter informações que nos permitem rastrear o seu
            caminho através do Site. Os cookies não prejudicam o seu computador
            ou quaisquer arquivos no seu computador; não podem ser usados para
            ler dados do seu disco rígido e não podem recuperar informações de
            quaisquer outros cookies criados por outros sites.
            </p>
            <p>
            Pode gerenciar os cookies do site nas preferências do seu navegador
            - terá sempre a opção de aceitar, rejeitar ou excluir cookies. No
            entanto, se optar por alterar as suas configurações, isso pode resultar
            em uma perda de funcionalidade para o seu navegador, não só no Nosso
            site, mas também em outros. Portanto, para fins de funcionalidade
            aprimorada e porque os cookies não nos fornecem qualquer informação a
            partir da qual possamos identificá-lo, sugerimos que nos permita
            colocá-los no seu computador.
            </p>
            <p>Nós usamos cookies para os seguintes fins:</p>
            <ul className="list-disc list-inside text-black text-base font-normal">
            <li>
                <span className="font-bold">Autenticação/Status:</span>{" "}
                identifique quando visitar o nosso website, se estiver logged in,
                e à medida que navega através dele.
            </li>
            <li>
                <span className="font-bold">
                Cookies analíticos/de desempenho:
                </span>{" "}
                permitem-nos compreender a forma como utiliza o nosso website, ou
                seja, sistemas operativos utilizados, número de visitas, duração
                média das visitas, páginas visualizadas, etc. Em geral, estes
                cookies são utilizados para melhorar a forma como o nosso website
                funciona e melhorar a sua experiência de utilizador.
            </li>
            <li>
                <span className="font-bold">Cookies de funcionalidade:</span>{" "}
                estes cookies permitem que o site se lembre das escolhas que faz e
                fornecem recursos aprimorados e mais pessoais, ou seja,
                cumprimentá-lo pelo seu nome de usuário escolhido e lembrar suas
                preferências.
            </li>
            <li>
                <span className="font-bold">Segurança:</span> Utilizamos cookies
                como elemento de medidas de segurança utilizadas para proteger as
                contas dos utilizadores, incluindo a prevenção do uso fraudulento
                de credenciais de login, e para proteger o nosso website e
                serviços em geral.
            </li>
            </ul>
            <div className='overflow-x-auto max-w-xs md:max-w-none'>
                <table className="border">
                    <thead className="border font-bold text-primary">
                        <th className="border-2 border-fifth py-1 px-2">Cookies</th>
                        <th className="border-2 border-fifth py-1 px-2">Descrição</th>
                        <th className="border-2 border-fifth py-1 px-2">Cookie name</th>
                    </thead>
                    <tbody>
                        <tr className="border">
                            <td className="font-bold border-2 border-fifth py-1 px-2">Fortimy</td>
                            <td className="border-2 border-fifth py-1 px-2">Usado para armazenar dados da sessão</td>
                            <td className="border-2 border-fifth py-1 px-2">fortimy_session</td>
                        </tr>
                        <tr className="border">
                            <td className="font-bold border-2 border-fifth py-1 px-2">Fortimy</td>
                            <td className="border-2 border-fifth py-1 px-2">Usado para subscrever pedidos de clientes</td>
                            <td className="border-2 border-fifth py-1 px-2">XSRF-TOKEN</td>
                        </tr>
                        <tr className="border">
                            <td className="font-bold border-2 border-fifth py-1 px-2">CookieScript</td>
                            <td className="border-2 border-fifth py-1 px-2">Usado para guardar preferência de cookies</td>
                            <td className="border-2 border-fifth py-1 px-2">CookieScriptConsent</td>
                        </tr>
                        {/* <tr className="border">
                            <td className="font-bold border-2 border-fifth py-1 px-2">Fortimy</td>
                            <td className="border-2 border-fifth py-1 px-2">Utilizado para identificar o utilizador que regressa</td>
                            <td className="border-2 border-fifth py-1 px-2">XSRF-TOKEN</td>
                        </tr>
                        <tr className="border">
                            <td className="font-bold border-2 border-fifth py-1 px-2">Fortimy</td>
                            <td className="border-2 border-fifth py-1 px-2">
                                Usado para armazenar o idioma selecionado da Interface de
                                Usuário (IU)
                            </td>
                            <td className="border-2 border-fifth py-1 px-2">xxxx</td>
                        </tr>
                        <tr className="border">
                            <td className="font-bold border-2 border-fifth py-1 px-2">Google analytics</td>
                            <td className="border-2 border-fifth py-1 px-2">xxxx</td>
                            <td className="border-2 border-fifth py-1 px-2">xxxx</td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
            <h3 className="font-bold text-primary">
            Utilizamos alguma tecnologia que não seja de rastreamento de
            cookies?
            </h3>
            <p>
            Embora nem sempre, também usamos web beacons (incluindo pixels de
            conversão) ou outras tecnologias para fins semelhantes aos descritos
            nesta seção, e podemos incluí-los no Nosso site, e-mails de
            marketing ou newsletter, para determinar se as mensagens foram
            abertas e os links clicados. Os Web beacons não colocam informações
            no seu dispositivo, mas podem funcionar em conjunto com cookies para
            monitorar a atividade do site.
            </p>
            <h3 className="font-bold text-primary">
            Sinais de Rastreamento e de Não-Track
            </h3>
            <p>
            Enquanto os navegadores permitem que você desactive o uso de
            cookies, não alteramos as nossas práticas em resposta a um sinal
            “Não Rastrear” no cabeçalho HTTP do seu navegador. Não iremos, para
            quaisquer fins de marketing, carregar cookies/web beacons ou
            qualquer outro tipo de software que rastreia seu comportamento geral
            enquanto está visitando sites de terceiros. Rastreamos, no
            entanto, se clicar em anúncios de serviços Fortimy em plataformas de
            terceiros, tais como motores de busca e redes sociais e podemos usar
            a análise para rastrear o que faz em resposta a esses anúncios.
            </p>
        </div>
        <div className="space-y-4">
            <h2 className="text-primary text-2xl md:text-3xl font-bold mb-2">
            Escudo de privacidade EU-US
            </h2>
            <p>
            O escudo de privacidade é um quadro para o intercâmbio
            transatlântico de dados pessoais para fins comerciais que protege os
            direitos fundamentais dos indivíduos quando os seus dados são
            transferidos para os Estados Unidos e garante a segurança jurídica
            das empresas.
            </p>
            <p>
            Pode ocorrer que alguns dos nossos processadores estejam sediados
            fora do Espaço Económico Europeu (EEE), pelo que o processamento dos
            seus dados pessoais envolve uma transferência de dados para fora do
            EEE. Se isso acontecer, asseguramos que existem salvaguardas
            adequadas e um grau de segurança semelhante é aplicado, incluindo
            confiar no Privacy Shield, Cláusulas Modelo aprovadas pela Comissão
            Europeia, entre outras opções legais, caso contrário, a
            transferência só ocorre quando permitida pela lei aplicável, dado
            que o objetivo final é assegurar que os seus direitos de privacidade
            continuam a ser protegidos de acordo com esta Política.
            </p>
        </div>
        <div className="space-y-4">
            <h2 className="text-primary text-2xl md:text-3xl font-bold mb-2">
            Divulgação dos dados
            </h2>
            <p>
            A Fortimy não vende, fornece ou divulga qualquer tipo de dados
            pessoais. Todos os dados que armazenamos são mantidos em bancos de
            dados criptografados e transportados em canais seguros e não serão
            acessíveis para nenhum outro fim que não seja o previsto, manutenção
            e melhoria dos nossos Serviços. A Fortimy só divulga dados a
            terceiros quando a divulgação é absolutamente necessária para
            fornecer os serviços que os nossos clientes solicitem ou em resposta
            a um pedido legal de uma autoridade de acreditação.
            </p>
        </div>
        <div className="space-y-4">
            <h2 className="text-primary text-2xl md:text-3xl font-bold mb-2">
            Actualizações a esta política
            </h2>
            <p>
            Esta Política pode estar sujeita a atualizações. Quaisquer
            alterações materiais futuras ou adições ao processamento de
            informações pessoais, conforme descrito nesta Política, que o
            afetem, ser-lhe-ão comunicadas através de um canal apropriado.
            </p>
            <p>
            Desde 25 de Outubro 2023, a Fortimy está em cumprimento com o RGPD.
            </p>
        </div>
        </section>
    </>
    );
};

export default PrivacyPolicy;
