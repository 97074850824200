import React, { useEffect, useState } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
import { MdKeyboardArrowDown } from "react-icons/md";
import CreateUser from "../../Modals/CreateUser";
import EditUser from "../../Modals/EditUser";
import ActionConfirmationModal from "../../Modals/ActionConfirmationModal";
import useAuthContext from "../../Config/AuthContext";
import Modal from "../../Modals/Modal";
import TableViewCard from "./TableViewCard";
import ListViewCard from "./ListViewCard";
import AuthUserCard from "./AuthUserCard";
import { useAlert } from "../../../contexts/AlertContext";
import useAuth from "../../../hooks/useAuth";
import { COMPANY_ROLES } from "../../../constants/global";
import { useTranslation } from 'react-i18next';
import axios from "../../Config/axios";

const Team = () => {

	const { t } = useTranslation();
	const { usersTeam, removeUserFromCompany } = useAuthContext();
	const { auth } = useAuth();

	const [isLoading, setIsLoading] = useState(true);
	const [team, setTeam] = useState([]);
	const [teamAdmin, setTeamAdmin] = useState({});
	const [view, setView] = useState(0);
	const [viewIcon, setViewIcon] = useState(<BsFillGrid3X3GapFill />);
	const [viewModal, setViewModal] = useState("hidden");
	const [viewClassList, setViewClassList] = useState("flex items-center text-4xl mt-4");
	const [viewClassTable, setViewClassTable] = useState("flex items-center text-4xl my-2");

	const [createUserModal, setCreateUserModal] = useState(false);
	const [editUserModal, setEditUserModal] = useState(false);
	const [deleteUserModal, setDeleteUserModal] = useState(false);
	const [selectRemoveUser, setSelectRemoveUser] = useState(null);
	const [selectEditUser, setSelectEditUser] = useState(null);

	const [companyRoles, setCompanyRoles] = useState([]);

	const { toggleLoader, triggerPopup } = useAlert();

	const getUsersTeam = async () => {
		setIsLoading(true);
		await usersTeam().then((response) => {
			setTeam(response.data?.data);
			setIsLoading(false);
		});
	};

	const removeUser = async (user) => {
		setIsLoading(true);
		setDeleteUserModal(false);
		const response = await removeUserFromCompany(user);
		if (response.status === 204) {
			triggerPopup('Popups.removed_user');
			getUsersTeam();
		}
	};

	const getCompanyRoles = async () => {
		await axios
			.get('api/get-company-roles')
			.then((response) => {
				setCompanyRoles(response.data)
			})
			.catch((e) => {
				console.log(e)
			})
	}

	useEffect(() => {
		if (auth.has_team) {
			setTeamAdmin(auth);
			getUsersTeam();
			getCompanyRoles();
		} else {
			setIsLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		toggleLoader(isLoading);
	}, [isLoading]);

	useEffect(() => {
		if (view === 0) {
			setViewIcon(<BsFillGrid3X3GapFill />);
			setViewClassTable("flex items-center text-4xl my-2 text-primary");
			setViewClassList("flex items-center text-4xl mt-4");
		} else {
			setViewIcon(<FiMenu />);
			setViewClassList("flex items-center text-4xl mt-4 text-primary");
			setViewClassTable("flex items-center text-4xl my-2");
		}
	}, [view]);

	return (
		<>
			<Modal isOpen={createUserModal}>
				<CreateUser
					onClose={() => {
						setCreateUserModal(false);
					}}
					teamAdmin={teamAdmin}
					updateTeam={() => {
						getUsersTeam();
					}}
				/>
			</Modal>
			<Modal isOpen={editUserModal}>
				<EditUser
					onClose={() => {
						setEditUserModal(false);
					}}
					user={selectEditUser}
					member={auth}
					updateTeam={() => {
						getUsersTeam();
					}}
				/>
			</Modal>
			<Modal isOpen={deleteUserModal}>
				<ActionConfirmationModal
					title={t('Modals.delete_modal_title')}
					description={t('Modals.delete_modal_subtitle')}
					onCancel={() => {
						setDeleteUserModal(false);
					}}
					onConfirm={() => {
						removeUser(selectRemoveUser);
					}}
				/>
			</Modal>

			<section>
				<div className="flex flex-col pb-10">
					<div className="w-full flex justify-between items-center py-5 border-b-2">
						<h1 className="text-xl md:text-3xl font-bold text-black">{t('Dashboard.team.title')}</h1>
						<div className="relative">
							<button
								onClick={() => {
									viewModal === "hidden"
										? setViewModal(
												"absolute top-10 right-0 bg-white shadow-2xl rounded-xl p-5 flex flex-col items-start w-[140px] text-black/20 font-bold z-50"
										)
										: setViewModal("hidden");
								}}
								className="flex text-4xl items-center text-black/20 relative"
							>
								{viewIcon}
								<span className="text-md">
									<MdKeyboardArrowDown />
								</span>
							</button>
							<div className={viewModal}>
								<p className="text-md font-bold">{t('Dashboard.team.display')}</p>
								<button
									onClick={() => {
										setView(1);
										setViewModal("hidden");
									}}
									className={viewClassList}
								>
									<FiMenu /> <div className="text-sm ml-2">{t('Dashboard.team.list')}</div>
								</button>
								<button
									onClick={() => {
										setView(0);
										setViewModal("hidden");
									}}
									className={viewClassTable}
								>
									<BsFillGrid3X3GapFill /> <span className="text-sm ml-2">{t('Dashboard.team.table')}</span>
								</button>
							</div>
						</div>
					</div>
				</div>

				{view === 0 && (
					<div className="flex flex-wrap justify-center gap-10 max-w-5xl 2xl:max-w-7xl mx-auto">
						{auth.has_team && auth.company_role_id === COMPANY_ROLES.ADMIN && (
							<button
								onClick={() => setCreateUserModal(true)}
								className="rounded-2xl bg-primary/20 shadow-custom focus:outline-none relative min-h-72 min-w-72"
							>
								<div className="absolute flex flex-col items-center justify-center h-full w-full top-0 left-0">
									<h1 className="text-5xl md:text-6xl 2xl:text-8xl text-primary">
										<AiOutlinePlusCircle />
									</h1>
									<h1 className="md:text-xl 2xl:text-2xl text-primary font-bold">{t('Dashboard.team.add_new_user')}</h1>
								</div>
							</button>
						)}

						{auth.has_team &&
							team.map((member, index) => (
								<TableViewCard
									key={index}
									currentUser={auth}
									member={member}
									roleOptions={companyRoles}
									isAdmin={auth.company_role_id === COMPANY_ROLES.ADMIN ? true : false}
									onEdit={() => {
										setSelectEditUser(member);
										setEditUserModal(true);
									}}
									onDelete={() => {
										setSelectRemoveUser(member.id);
										setDeleteUserModal(true);
									}}
								/>
							))}
					</div>
				)}

				{view === 1 && (
					<div className="col-span-12 flex flex-col">
						{auth.has_team && auth.company_role_id === COMPANY_ROLES.ADMIN && (
							<button
								onClick={() => setCreateUserModal(true)}
								className="w-full rounded-2xl bg-primary/20 flex items-center px-4 md:px-8 py-5 shadow-xl my-3"
							>
								<h1 className="md:text-6xl text-4xl text-primary md:mr-5 mr-3">
									<AiOutlinePlusCircle />
								</h1>
								<h1 className="font-bold text-lg md:text-3xl text-primary">{t('Dashboard.team.add_new_user')}</h1>
							</button>
						)}

						{auth.has_team &&
							team.map((member, index) => (
								<ListViewCard
									key={index}
									currentUser={auth}
									member={member}
									roleOptions={companyRoles}
									isAdmin={auth.company_role_id === COMPANY_ROLES.ADMIN ? true : false}
									onEdit={() => {
										setSelectEditUser(member);
										setEditUserModal(true);
									}}
									onDelete={() => {
										setSelectRemoveUser(member.id);
										setDeleteUserModal(true);
									}}
								/>
							))}
					</div>
				)}

				{!isLoading && team.length === 0 && (
					<p className="w-full flex justify-center text-primary text-3xl font-bold">{t('Dashboard.team.no_results')}</p>
				)}

				<AuthUserCard currentUser={auth} />
			</section>
		</>
	);
};

export default Team;
