import { useEffect, useState, useRef } from "react";
import useAuth from "../../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import LocationSearch from "../../Inputs/LocationSearch.js";

const Location = ({ formData, onSubmit }) => {

    const { t } = useTranslation();
    const { auth } = useAuth();
    const [zone, setZone] = useState(formData?.zone ?? []);

    const onChange = (selectedOptions) => {
        setZone(selectedOptions);
    };

    const handleSubmit = () => {
        onSubmit({
            zone: zone
        })
    }

    return (
        <>
            <h1 className="text-center text-2xl md:text-4xl font-bold mb-2 md:mb-5">
                {t("Dashboard.my_requests.make_a")}{" "}
                {t("Dashboard.my_requests.request")}
            </h1>

            <h2 className="text-primary md:text-xl font-bold text-center">
                {t("Dashboard.my_requests.which_area_is_property_located")}
            </h2>

            <form className="xl:w-[25vw] lg:w-[40vw] md:w-[50vw] w-full md:mt-14 mt-5">
                <LocationSearch 
                    onChange={ (e) => { onChange(e) } }
                    placeholder={t("Dashboard.my_requests.search")}
                    value={zone}
                />
            </form>

            <button
                type="button"
                onClick={() => {
                    handleSubmit();
                }}
                className="md:mt-20 mt-10 xl:w-[25vw] lg:w-[40vw] md:w-[50vw] w-full py-2 bg-primary font-bold text-white text-xl rounded-md"
            >
                {t("Dashboard.my_requests.continue")}
            </button>
        </>
    )
}

export default Location