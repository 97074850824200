import React, { useState } from 'react'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { IoSettingsOutline } from "react-icons/io5";
import InputMask from "react-input-mask";
import PhoneInput from 'react-phone-input-2';
import ErrorMessage from '../../UI/ErrorMessage';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { currentLanguage } from '../../Config/i18n';

const RequestCard = ({ request, disable, onVerify, onEdit, onArchive, onDelete }) => {
    const { t } = useTranslation();
    const [openCard, setOpenCard] = useState(false);
    const [formData, setFormData] = useState({ id: request.id });
	const [hasChanges, setHasChanges] = useState(false);
	const [errors, setErrors] = useState([]);
    const [isOpenSettings, setIsOpenSettings] = useState(false);

    const handleChange = (e, field) => {
        if (!hasChanges) {
            setHasChanges(true);
        }
        setFormData({ ...formData, [field]: e.target.value });
	}

    const saveChanges = async () => {
        setErrors([]);
        const response = await onEdit(formData);
        if (response?.status === 422) {
            setErrors(response.data.errors);
        }
    }

    return (
        <>
        <div className="flex flex-col">
            <div
                onClick={() => { setOpenCard( prevOpenCard => !prevOpenCard ) }}
                className={`bg-white shadow-custom relative rounded-2xl flex items-center justify-between w-full h-[200px] p-10 hover:cursor-pointer ${
                    openCard && "border-2 border-primary border-b-0"
                }`}
            >
                <div className="flex items-start gap-x-3">
                    <img
                        src={`/svg/properties/${request.property_type_image}`}
                        alt="Property Type"
                        className='max-w-32'
                    />
                    <h1 className="text-primary text-3xl font-bold">FO{request.id}</h1>
                    <div className="flex flex-col items-start w-full gap-y-2">
                        <h1 className="text-primary text-3xl font-bold">
                        {currentLanguage() === 'en' ? `${request.property_type_name} (${request.typology_name})` : `${request.property_type_name_pt} (${request.typology_name_pt})`} {t('Dashboard.admin.in')} {request.district_name}
                        </h1>
                        <h2 className="text-primary text-2xl flex items-center">
                            {t('Dashboard.admin.by')} {request.company_name ?? request.created_by_name}
                        </h2>
                        <h1 className="text-black text-xl">{t('Dashboard.admin.published_at')} {request.created_at}</h1>
                        <h1 className="text-black text-xl">{t('Dashboard.admin.expires_at')} {request.expires_at}</h1>
                    </div>
                </div>
                <div className="flex items-center gap-10">
                    { request.verified ? (
                        <span>{t('Dashboard.admin.verified')}</span>
                    ) : (
                        <span className='text-red-500'>{t('Dashboard.admin.to_verify')}</span>
                    )}
                    <div className='relative'>
                        <IoSettingsOutline
                            onClick={ (e) => {
                                e.stopPropagation();
                                setIsOpenSettings(!isOpenSettings) 
                            }}
                            className='text-primary text-xl md:text-4xl'
                        />
                        {isOpenSettings &&
                            <div className="flex flex-col items-start text-primary text-lg absolute top-12 bg-white rounded-lg w-32 shadow-2xl z-[100] overflow-hidden">
                                <button
                                    onClick={(e) => { onArchive(e, request.id) }}
                                    className="w-full px-4 py-2 text-start hover:bg-secondary"
                                >
                                    {request.archived ? t('Dashboard.admin.unarchive') : t('Dashboard.admin.archive')}
                                </button>
                                {request.archived === 1 &&    
                                    <button
                                        onClick={(e) => { onDelete(e, request.id) }}
                                        className="w-full px-4 py-2 text-start hover:bg-secondary"
                                    >
                                        {t('Dashboard.admin.delete')}
                                    </button>
                                }
                                <Link
                                    to={`edit/${request.id}`}
                                    className="w-full px-4 py-1 hover:bg-secondary"
                                >
                                    {t('Dashboard.admin.edit')}
                                </Link>
                            </div>
                        }
                    </div>
                    <div className="text-primary md:text-3xl text-xl border-2 rounded-full p-1 border-primary">
                        {openCard ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
                    </div>
                </div>
            </div>
            {openCard && (
                <div
                    key={request.id}
                    className="grid grid-cols-4 gap-5 rounded-b-2xl items-center bg-white border-2 border-t-0 border-primary z-50 -mt-5 p-10"
                >
                    <h1 className="col-span-4 text-2xl text-primary font-bold">{t('Dashboard.admin.client_info')}</h1>
                    <div className="flex flex-col gap-2 text-primary text-lg">
                        <label className="text-black/80">{t('Dashboard.admin.first_name')} {t('Dashboard.admin.first_three_letters')}</label>
                        <InputMask
                            mask="aaa"
                            maskChar={' '}
                            defaultValue={request.first_name}
                            type="text"
                            name="first_name"
                            className="border-primary border-2 p-2 rounded-xl"
                            onChange={(e) => handleChange(e, 'first_name')}
                        />
                        <ErrorMessage errors={errors.first_name} />
                    </div>
                    <div className="flex flex-col gap-2 text-primary text-lg">
                        <label className="text-black/80">{t('Dashboard.admin.last_name')} {t('Dashboard.admin.first_three_letters')}</label>
                        <InputMask
                            mask="aaa"
                            maskChar={' '}
                            defaultValue={request.last_name}
                            type="text"
                            name="last_name"
                            className="border-primary border-2 p-2 rounded-xl"
                            onChange={(e) => handleChange(e, 'last_name')}
                        />
                        <ErrorMessage errors={errors.last_name} />
                    </div>
                    <div className="flex flex-col gap-2 text-primary text-lg">
                        <label className="text-black/80">{t('Dashboard.admin.nationality')}</label>
                        <input
                            type="text"
                            defaultValue={request.nationality}
                            name="nationality"
                            className="border-primary border-2 p-2 rounded-xl"
                            onChange={(e) => handleChange(e, 'nationality')}
                        />
                        <ErrorMessage errors={errors.nationality} />
                    </div>
                    <div className="flex flex-col gap-2 text-primary text-lg">
                        <label className="text-black/80">{t('Dashboard.admin.phone')} {t('Dashboard.admin.last_four_digits')}</label>
                        <PhoneInput
                            defaultMask={'*** **. ...'}
                            alwaysDefaultMask
                            value={`${request.country_code} ${request.phone}`}
                            onChange={(number, country) => {
                                if (!hasChanges) {
                                    setHasChanges(true);
                                }
                                setFormData({
                                    ...formData,
                                    phone: number.replace(country.dialCode, ""),
                                    country_code: `+${country.dialCode}`,
                                });
                            }}
                            buttonStyle={{ border: "none", backgroundColor: "white" }}
                            inputStyle={{
                                height: "100%",
                                width: "100%",
                                border: "none",
                                fontSize: "18px",
                                lineHeight: "28px",
                            }}
                            containerStyle={{
                                fontSize: "18px",
                                lineHeight: "28px",
                                padding: "8px",
                                border: "2px solid #ff6600",
                                borderRadius: "12px",
                            }}
                            enableSearch={true}
                            disableDropdown={false}
                        />
                        <ErrorMessage errors={errors.phone} />
                    </div>
                    <div className="flex flex-col gap-2 text-primary text-lg">
                        <label className="text-black/80">{t('Dashboard.admin.buyer_type')}</label>
                        <input
                            type="text"
                            value={currentLanguage() === 'en' ? request.buyer_type_name : request.buyer_type_name_pt}
                            name="buyer_type"
                            disabled
                            className="border-primary border-2 p-2 rounded-xl"
                            onChange={(e) => handleChange(e, 'buyer_type_id')}
                        />
                    </div>
                    <div className="flex flex-col gap-2 text-primary text-lg">
                        <label className="text-black/80">{t('Dashboard.admin.urgency')}</label>
                        <input
                            type="text"
                            value={currentLanguage() === 'en' ? request.urgency_name : request.urgency_name_pt}
                            name="urgency"
                            disabled
                            className="border-primary border-2 p-2 rounded-xl"
                            onChange={(e) => handleChange(e, 'urgency_id')}
                        />
                    </div>
                    <div className="flex flex-col gap-2 text-primary text-lg">
                        <label className="text-black/80">{t('Dashboard.admin.investment_type')}</label>
                        <input
                            type="text"
                            value={currentLanguage() === 'en' ? request.investment_type_name : request.investment_type_name_pt}
                            name="investment_type"
                            disabled
                            className="border-primary border-2 p-2 rounded-xl"
                            onChange={(e) => handleChange(e, 'investment_type_id')}
                        />
                    </div>
                    
                    <h1 className="col-span-4 text-2xl text-primary font-bold">{t('Dashboard.admin.request_info')}</h1>
                    <div className="flex flex-col gap-2 text-primary text-lg">
                        <label className="text-black/80">{t('Dashboard.admin.request_type')}</label>
                        <input
                            type="text"
                            value={ currentLanguage() === 'en' ? request.request_type_name : request.request_type_name_pt }
                            name="request_type"
                            disabled
                            className="border-primary border-2 p-2 rounded-xl"
                            onChange={(e) => handleChange(e, 'request_type_id')}
                        />
                        <ErrorMessage errors={errors.request_type} />
                    </div>
                    <div className="flex flex-col gap-2 text-primary text-lg">
                        <label className="text-black/80">{t('Dashboard.admin.property_type')}</label>
                        <input
                            type="text"
                            value={currentLanguage() === 'en' ? request.property_type_name : request.property_type_name_pt}
                            name="property_type"
                            disabled
                            className="border-primary border-2 p-2 rounded-xl"
                            onChange={(e) => handleChange(e, 'property_type_id')}
                        />
                        <ErrorMessage errors={errors.property_type} />
                    </div>
                    <div className="flex flex-col gap-2 text-primary text-lg">
                        <label className="text-black/80">{t('Dashboard.admin.location')}</label>
                        <input
                            type="text"
                            value={request.district_name}
                            name="district"
                            className="border-primary border-2 p-2 rounded-xl"
                            onChange={(e) => handleChange(e, 'district_id')}
                        />
                        <ErrorMessage errors={errors.district} />
                    </div>
                    <div className="flex flex-col gap-2 text-primary text-lg">
                        <label className="text-black/80">{t('Dashboard.admin.price_range')}</label>
                        <div className='flex items-center justify-between'>
                            <input
                                type="text"
                                value={request.price_from}
                                name="price_from"
                                disabled
                                className="border-primary border-2 p-2 rounded-xl w-2/5"
                                onChange={(e) => handleChange(e, 'price_from')}
                            />
                            <span>{t('Dashboard.admin.to')}</span>
                            <input
                                type="text"
                                value={request.price_until}
                                name="price_until"
                                disabled
                                className="border-primary border-2 p-2 rounded-xl w-2/5"
                                onChange={(e) => handleChange(e, 'price_until')}
                            />
                        </div>
                        <ErrorMessage errors={errors.price_from} />
                    </div>
                    <div className="flex flex-col gap-2 text-primary text-lg">
                        <label className="text-black/80">{t('Dashboard.admin.area_range')}</label>
                        <div className='flex items-center justify-between'>
                            <input
                                type="text"
                                value={request.area_from}
                                name="area_from"
                                disabled
                                className="border-primary border-2 p-2 rounded-xl w-2/5"
                                onChange={(e) => handleChange(e, 'area_from')}
                            />
                            <span>{t('Dashboard.admin.to')}</span>
                            <input
                                type="text"
                                value={request.area_until}
                                name="area_until"
                                disabled
                                className="border-primary border-2 p-2 rounded-xl w-2/5"
                                onChange={(e) => handleChange(e, 'area_until')}
                            />
                        </div>
                        <ErrorMessage errors={errors.area_from} />
                    </div>
                    <div className="flex flex-col gap-2 text-primary text-lg">
                        <label className="text-black/80">{t('Dashboard.admin.typology')}</label>
                        <input
                            type="text"
                            value={currentLanguage() === 'en' ? request.typology_name : request.typology_name_pt}
                            name="typology"
                            disabled
                            className="border-primary border-2 p-2 rounded-xl"
                            onChange={(e) => handleChange(e, 'typology_id')}
                        />
                        <ErrorMessage errors={errors.typology} />
                    </div>
                    <div className="flex flex-col gap-2 text-primary text-lg">
                        <label className="text-black/80">{t('Dashboard.admin.condition')}</label>
                        <input
                            type="text"
                            value={currentLanguage() === 'en' ? request.condition_name : request.condition_name_pt}
                            name="condition"
                            disabled
                            className="border-primary border-2 p-2 rounded-xl"
                            onChange={(e) => handleChange(e, 'condition_id')}
                        />
                        <ErrorMessage errors={errors.condition} />
                    </div>
                    
                    {!request.verified &&
                        <div className="col-span-4 flex w-full justify-end">
                            <button
                                disabled={disable}
                                onClick={onVerify}
                                className="btn-primary text-white font-bold text-xl p-5 rounded-xl shadow-xl"
                            >
                                {t('Dashboard.admin.verify_request')}
                            </button>
                        </div>
                    }
                    {/* {hasChanges &&
                        <div className='col-span-4 flex w-full justify-end'>
                            <button onClick={ () => saveChanges() } className='bg-primary text-white font-bold text-xl p-5 rounded-xl shadow-xl'>{t('Dashboard.admin.save_changes')}</button>
                        </div>
                    } */}
                </div>
            )}
        </div>
        </>
    )
}

export default RequestCard