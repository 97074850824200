import { Link } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import { COMPANY_ROLES } from "../../../../constants/global";
import { useTranslation } from "react-i18next";

const InboxCard = ({ chat }) => 
{
    const { auth } = useAuth();
    const { t } = useTranslation();

    const date = new Date();
	const yyyy = date.getFullYear();
	let mm = date.getMonth() + 1; // Months start at 0
	let dd = date.getDate();
	
	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;
	
	const today = dd + '/' + mm + '/' + yyyy;

    return (
        <>
        <Link
            to={`/app/messages/${chat.request_id}`}
            className='flex bg-white shadow-custom rounded-3xl 2xl:rounded-[2rem] w-full items-center h-24 px-5 2xl:px-10 my-5'
        >
            <div className="relative my-auto mr-7">
                {(chat.consultant || chat.buyer) &&
                    <div className="absolute -left-2 -top-2 2xl:-left-5 overflow-hidden rounded-full w-6 h-6 xl:w-8 xl:h-8 2xl:h-10 2xl:w-10">
                        <img
                            src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${chat.consultant?.image ?? chat.buyer?.image}`}
                            alt=""
                            className="object-cover w-full h-full"
                        />
                    </div>
                }
                <div className={`${chat.consultant || chat.buyer ? 'mx-auto' : ''} overflow-hidden rounded-full w-10 h-10 lg:w-12 lg:h-12 2xl:w-16 2xl:h-16`}>
                    <img
                        src={`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${chat.owner.image}`}
                        alt=""
                        className="object-cover w-full h-full"
                    />
                </div>
            </div>
            <div>
                <h1 className=" 2xl:text-2xl text-left">
                    {chat.owner.name}
                </h1>
                <div className="px-1 lg:px-0 text-start text-black/60 text-xs lg:text-md">
                    <p>{chat.property}</p>
                    <p  className="line-clamp-1">
                        {chat.location === "" || chat.location === undefined
                        ? ""
                        : t('Dashboard.admin.in') +" "+ chat.location}
                    </p>
                    <p className="text-xs">ID {chat.request_id}</p>
                </div>
            </div>
            <div className="text-end text-xs lg:text-md 2xl:text-base text-primary ml-auto">
                <p>{ chat.updated_at_date !== today && chat.updated_at_date }</p>
                <p>{ chat.updated_at_hour }</p>
                {chat.unread_messages > 0 &&
                    <div className="flex items-center justify-center rounded-full bg-primary text-white text-xs w-4 h-4  ml-auto">
                        <p>{ chat.unread_messages }</p>
                    </div>
                }
            </div>
        </Link>
        </>
    )
}

export default InboxCard;