import { t } from "i18next";
import { AiOutlineClose } from "react-icons/ai";
import ErrorMessage from '../UI/ErrorMessage'
import { useState } from "react";

const ManageTheme = ({ theme = null, onClose, onSubmit, errors }) => 
{

    const [formData, setFormData] = useState({ id: theme?.id ?? 0, name: theme?.name ?? null });
    
    return (
        <>
            <div className="rounded-3xl bg-white w-1/4 shadow-custom px-10 py-5 space-y-6">
                <div className="w-full flex justify-between items-center">
                    <h1 className="text-2xl text-primary">{ theme ? t('Dashboard.admin.edit_theme') : t('Dashboard.admin.add_theme')}</h1>
                    <button onClick={onClose} className="text-primary text-4xl mx-3">
                        <AiOutlineClose />
                    </button>
                </div>
                
                <div className="space-y-2">
                    <label className="w-full text-black/50 text-lg">{t('Dashboard.admin.theme_name')}</label>
                    <input
                        onChange={(e) => {
                            setFormData({
                            ...formData,
                            name: e.target.value,
                            });
                        }}
                        defaultValue={theme?.name ?? ''}
                        type="text"
                        className="w-full border-2 rounded-lg p-1 px-2"
                    />
                    <ErrorMessage errors={errors?.name} />
                </div>
                <div className="flex w-full justify-center mt-5">
                    <button
                        onClick={() => { onSubmit(formData) }}
                        className="py-2 px-10 bg-primary text-white text-xl rounded-full ml-auto"
                    >
                        { theme ? t('Dashboard.admin.save_changes') : t('Dashboard.admin.create')}
                    </button>
                </div>
            </div>
        </>
    )
}

export default ManageTheme;