import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Hero from "../components/Businesses/Hero";
import Tutorial from "../components/Businesses/Tutorial";
import WhyFortimy from "../components/Businesses/WhyFortimy";
import Community from "../components/Businesses/Community";
import Footer from "../components/Footer";
import { AnimationOnScroll } from "react-animation-on-scroll";
import ScrollToTop from "../components/Router/ScrollToTop";
import { useLocation, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import useScript from "../hooks/useScript";
import { Helmet } from "react-helmet";
import { useAlert } from "../contexts/AlertContext";

const Home = () => {
  const { toggleLoader, triggerPopup } = useAlert();
  const [verifiedModal, setVerifiedModal] = useState("hidden");
  const [unverifiedModal, setUnVerifiedModal] = useState("hidden");
  const [searchParams] = useSearchParams();
  const location = useLocation();

  useScript('https://cdn.cookie-script.com/s/41eed63aa273ca735efe730c8c60594f.js');

  useEffect(() => {
    let param = searchParams.get("verified");
    if (param === 1) {
      setVerifiedModal(
        "w-full fixed flex justify-center h-screen z-[100] top-0 bg-black/20 items-center"
      );
    }
  }, [searchParams]);

  useEffect(() => {
    let param = searchParams.get("unverified");
    if (param === 1) {
      setUnVerifiedModal(
        "w-full fixed flex justify-center h-screen z-[100] top-0 bg-black/20 items-center"
      );
    }
  }, [searchParams]);

  useEffect(() => {
    if (location.hash !== null && location.hash !== "") {
      let element = document.getElementById(location.hash.replace("#", ""));
      if (element != null) {
        element.scrollIntoView({
          block: "start",
          behavior: "smooth",
          inline: "start",
        });
      }
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <title>Fortimy - Empresas</title>
        <meta name="description" content="A Fortimy é uma plataforma com acesso exclusivo aos mediadores imobiliários. Damos prioridade aos pedidos imobiliários e não às listagens de imóveis." />
        <meta name="keywords" content="Fortimy, Imobiliário, Imóvel, Comprador, Inquilino, Propriedades, Consultor imobiliário" />
        <meta property="og:title" content="Fortimy - Empresas" />
        <meta property="og:description" content="A Fortimy é uma plataforma com acesso exclusivo aos mediadores imobiliários. Damos prioridade aos pedidos imobiliários e não às listagens de imóveis." />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Fortimy" />
        <meta property="og:locale" content="pt_PT" />
        <meta property="og:url" content="https://fortimy.com/businesses" />
        <meta property="og:image" content="https://fortimy.com/logo2.png" />
        <meta property="og:image:secure_url" content="https://fortimy.com/logo2.png" />
        <meta property="article:publisher" content="https://www.facebook.com/fortimypt" />
        <meta name="publisher" content="https://www.facebook.com/fortimypt" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Fortimy - Empresas" />
        <meta name="twitter:description" content="A Fortimy é uma plataforma com acesso exclusivo aos mediadores imobiliários. Damos prioridade aos pedidos imobiliários e não às listagens de imóveis." />
        <meta name="twitter:image" content="https://fortimy.com/logo2.png" />
      </Helmet>

      <section className="bg-secondary">
        <ScrollToTop />
        <Navbar />
        <Hero />
        <Tutorial />
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce="true">
          <WhyFortimy />
          <Community />
        </AnimationOnScroll>
        <Footer />
      </section>
    </>
  );
};

export default Home;
