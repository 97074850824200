import React from 'react';
import Banner from '../../assets/Dashboard/Banner.png';
import DashboardNavbar from '../../components/Dashboard/Navbar';
import DashboardHomepage from '../../components/Dashboard/Homepage';
import ScrollToTop from '../../components/Router/ScrollToTop';
import ComingSoon from '../ComingSoon';
import useAuth from '../../hooks/useAuth';

const Dashboard = () => {
    const {auth} = useAuth();
    return (
        <>
            <ScrollToTop />
            <section>
                <DashboardHomepage />
            </section>
        </>
    )
}

export default Dashboard