import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuthContext from "../../components/Config/AuthContext";
import useAuth from "../../hooks/useAuth";
import { useAlert } from "../../contexts/AlertContext";
import ScrollToTop from "./ScrollToTop";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { APP_PATHS } from "../../constants/global";

export default function GuestLayout() {
  const { checkUserAuth } = useAuthContext();
  const { auth, location } = useAuth();
  const { toggleLoader } = useAlert();

  const [isLoading, setLoading] = useState(true);

  const previousLocation = useLocation();
  const from = previousLocation.state?.from?.pathname;

  useEffect(() => {
    toggleLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (typeof auth && isLoading) {
      checkUserAuth().finally(() => {
        setLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isLoading ? (
    <>
      <ScrollToTop />
      <Navbar />

      {auth?.id && (
        <Navigate
          to={
            from
              ? from
                : (auth?.is_individual || !auth?.has_requests)
                  ? APP_PATHS.MY_REQUESTS
                  : APP_PATHS.DASHBOARD
          }
          state={{ from: previousLocation }}
        />
      )}
      <Footer />
    </>
  ) : null;
}
