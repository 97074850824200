import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

const ResponseModal = ({ showmodal, response, code }) => {

    const [parameter, setParameter] = useState([]);

    useEffect(() => {
        if (response) {
            setParameter(response.split(','))
        }
    }, [response])

    return (
        <div className='fixed top-0 left-0 z-[100] h-screen w-screen bg-black/10 flex justify-center items-center'>
            <div className='md:w-[50vw] h-[90vh] overflow-y-scroll bg-white rounded-3xl flex flex-col'>
                <button onClick={() => { setParameter([]); showmodal(); }} className='flex justify-end text-primary text-4xl m-3'><AiOutlineClose /></button>
                <div className='w-full px-5 text-center flex flex-col items-center justify-center pb-10 relative'>
                    <h1 className='text-primary font-bold text-2xl'>STATUS CODE {code}</h1>
                    <div className='text-black text-base pt-5 pb-10 text-center'>{parameter.map( (element, index) => <div key={index}>{element}</div>)}</div>
                </div>
            </div>
        </div>
    )
}

export default ResponseModal