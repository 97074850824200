import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { MdKeyboardArrowUp, MdKeyboardArrowDown, MdOutlineLockOpen, MdOutlineLock } from "react-icons/md";
import PhoneInput from 'react-phone-input-2';
import ErrorMessage from '../../UI/ErrorMessage';
import Tooltip from '../../UI/Tooltip';
import { useParams } from 'react-router-dom';
import { currentLanguage } from '../../Config/i18n';

const UserCard = ({ user, disable, onVerify, onEdit, onDelete, onBlock }) => {

    const { t } = useTranslation();
	const {id} = useParams();

    const [openCard, setOpenCard] = useState(false);
	const [formData, setFormData] = useState({ id: user.id });
	const [hasChanges, setHasChanges] = useState(false);
	const [errors, setErrors] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const isDisabled = user.verified

	const handleChange = (e, field) => {
		if (!hasChanges) {
			setHasChanges(true);
		}
		setFormData({ ...formData, [field]: e.target.value });
	}

	const saveChanges = async () => {
        setErrors([]);
		setIsLoading(true);
        const response = await onEdit(formData);
		setIsLoading(false);
        if (response?.status === 422) {
            setErrors(response.data.errors);
        }
    }

	useEffect(() => {
		setFormData({ id: user.id });
		setErrors([]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openCard])

	useEffect(() => {
        if (user.id == id) {
            setOpenCard(true);
        }
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	
    return (
        <>
        <div className="flex flex-col" key={user.id}>
					<div
						onClick={() => { setOpenCard( prevOpenCard => !prevOpenCard ) }}
						className={`bg-white shadow-custom relative rounded-2xl hover:cursor-pointer flex items-center justify-between w-full h-[200px] p-10 ${
							openCard && "border-2 border-primary border-b-0"
						}`}
					>
						<div className="flex items-center gap-x-3">
							<img
								src={
									user.profile_image &&
									`${process.env.REACT_APP_BACKEND_URL_IMG}/users/${user.profile_image}`
								}
								alt="profile"
								className="w-[80px] h-[80px] rounded-full object-cover"
							/>
							<div className="flex items-start gap-x-3">
								<h1 className="text-primary text-3xl font-bold">FO{user.id}</h1>
								<div className="flex flex-col items-start">
									<h1 className="text-primary text-3xl font-bold">
										{user.first_name} {user.last_name}
									</h1>
									<h1 className="text-primary text-2xl">{user.email}</h1>
									<h1 className="text-black text-2xl">{user.occupation}</h1>
								</div>
							</div>
						</div>
							<div className="flex items-center gap-10">
								{ user.last_seen &&
									<div>
										{ t('Dashboard.admin.last_seen') } {user.last_seen}
									</div>
								}
								{ !user.is_admin && 
									<Tooltip message={user.blocked ? t('Dashboard.admin.unblock') : t('Dashboard.admin.block')}>
										<button onClick={ onBlock } className="text-primary text-2xl md:text-5xl">
											{user.blocked ? (
													<MdOutlineLock />
												) : (
													<MdOutlineLockOpen />
												)
											}
                                    	</button>
									</Tooltip>
									// <button onClick={ onDelete } className='text-primary text-2xl md:text-4xl'>
									// 	<BsTrashFill />
									// </button>
								}
								<div className="text-primary md:text-3xl text-xl border-2 rounded-full p-1 border-primary">
									{openCard ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
								</div>
							</div>
					</div>
					{openCard && (
						<div className="grid grid-cols-4 gap-5 rounded-b-2xl items-center bg-white border-2 border-t-0 border-primary z-50 -mt-5 p-10">
							<h1 className="col-span-4 text-2xl text-primary font-bold">{t('Dashboard.admin.personal_info')}</h1>
							<div className="flex flex-col gap-2 text-primary text-lg">
								<h1 className="text-black/80">{t('Dashboard.admin.first_name')}</h1>
								<input
									type="text"
									defaultValue={user.first_name}
									name="name"
									className="border-primary border-2 p-2 rounded-xl"
									onChange={(e) => handleChange(e, 'first_name')}
								/>
								<ErrorMessage errors={errors.first_name} />
							</div>
							<div className="flex flex-col gap-2 text-primary text-lg">
								<h1 className="text-black/80">{t('Dashboard.admin.last_name')}</h1>
								<input
									type="text"
									defaultValue={user.last_name}
									name="last_name"
									className="border-primary border-2 p-2 rounded-xl"
									onChange={(e) => handleChange(e, 'last_name')}
								/>
								<ErrorMessage errors={errors.last_name} />
							</div>
							<div className="flex flex-col gap-2 text-primary text-lg">
								<h1 className="text-black/80">{t('Dashboard.admin.access_email')}</h1>
								<input
									type="text"
									disabled={isDisabled}
									defaultValue={user.email}
									name="email"
									className={`${isDisabled ? 'border-zinc-400 text-zinc-400' : 'border-primary'} border-2 p-2 rounded-xl`}
									onChange={(e) => handleChange(e, 'email')}
								/>
								<ErrorMessage errors={errors.email} />
							</div>
							<div className="flex flex-col gap-2 text-primary text-lg">
								<h1 className="text-black/80">{t('Dashboard.admin.contact_email')}</h1>
								<input
									type="text"
									defaultValue={user.contact_email}
									name="contact_email"
									className="border-primary border-2 p-2 rounded-xl"
									onChange={(e) => handleChange(e, 'contact_email')}
								/>
								<ErrorMessage errors={errors.contact_email} />
							</div>
							<div className="flex flex-col gap-2 text-primary text-lg">
								<h1 className="text-black/80">{t('Dashboard.admin.nationality')}</h1>
								<input
									type="text"
									defaultValue={user.nationality ?? '-'}
									name="nationality"
									className="border-primary border-2 p-2 rounded-xl"
									onChange={(e) => handleChange(e, 'nationality')}
								/>
								<ErrorMessage errors={errors.nationality} />
							</div>
							<div className="flex flex-col gap-2 text-primary text-lg">
								<h1 className="text-black/80">{t('Dashboard.admin.phone')}</h1>
								<PhoneInput
									value={user.phone}
									onChange={(number, country) => {
										if (!hasChanges) {
											setHasChanges(true);
										}
										setFormData({
											...formData,
											phone: number.replace(country.dialCode, ""),
											phone_country_code: `+${country.dialCode}`,
										});
									}}
									buttonStyle={{ border: "none", backgroundColor: "white" }}
									inputStyle={{
										height: "100%",
										width: "100%",
										border: "none",
										fontSize: "18px",
										lineHeight: "28px",
									}}
									containerStyle={{
										fontSize: "18px",
										lineHeight: "28px",
										padding: "8px",
										border: "2px solid #ff6600",
										borderRadius: "12px",
									}}
									enableSearch={true}
									disableDropdown={false}
									defaultMask="... ... ..."
								/>
								<ErrorMessage errors={errors.phone} />
							</div>

							<h1 className="col-span-4 text-2xl text-primary mt-5 font-bold">{t('Dashboard.admin.fortimy_info')}</h1>

							<div className="flex flex-col gap-2 text-primary text-lg">
								<h1 className="text-black/80">{t('Dashboard.admin.plan')}</h1>
								<input
									disabled
									type="text"
									value={currentLanguage() === 'en' ? user.plan?.title ?? 'N/A' : user.plan?.title_pt ?? 'N/A'}
									name="plan_title"
									className="border-primary border-2 p-2 rounded-xl"
								/>
							</div>
							<div className="flex flex-col gap-2 text-primary text-lg">
								<h1 className="text-black/80">{t('Dashboard.admin.plan_type')}</h1>
								<input
									disabled
									type="text"
									defaultValue={user.plan?.plan_type?.title ?? 'N/A'}
									name="plan_type"
									className="border-primary border-2 p-2 rounded-xl"
								/>
							</div>
							<div className="flex flex-col gap-2 text-primary text-lg">
								<h1 className="text-black/80">{t('Dashboard.admin.company')}</h1>
								<input
									disabled
									type="text"
									defaultValue={user.company?.corporate_name ?? 'N/A'}
									name="company_role"
									className="border-primary border-2 p-2 rounded-xl"
								/>
							</div>
							<div className="flex flex-col gap-2 text-primary text-lg">
								<h1 className="text-black/80">{t('Dashboard.admin.role_in_company')}</h1>
								<input
									disabled
									type="text"
									defaultValue={user.company_role?.title ?? 'N/A'}
									name="company_role"
									className="border-primary border-2 p-2 rounded-xl"
								/>
							</div>
							{hasChanges && (
								<div className="col-span-4 flex w-full justify-end">
									<button
										disabled={isLoading}
										onClick={ () => { saveChanges() }}
										className="btn-primary text-white font-bold text-xl p-5 rounded-xl shadow-xl"
									>
										{t('Dashboard.admin.save_changes')}
									</button>
								</div>
							)}
							{(user.company != null && !user.verified) ? (
								user?.company?.verified ? (
									<div className="col-span-4 flex w-full justify-end">
										<button
											disabled={disable}
											onClick={onVerify}
											className="btn-primary text-white font-bold text-xl p-5 rounded-xl shadow-xl"
										>
											{t('Dashboard.admin.verify_user')}
										</button>
									</div>
									) : (
									<div className="col-span-4 flex w-full justify-end">
										<Link
											disabled={disable}
											to={`/app/admin/companies/${user?.company?.id}`}
											className="btn-primary text-white font-bold text-xl p-5 rounded-xl shadow-xl"
										>
											{t('Dashboard.admin.verify_company')}
										</Link>
									</div>
									)
								) : (
									!user.verified &&
									<div className="col-span-4 flex w-full justify-end">
										<button
											disabled={disable}
											onClick={onVerify}
											className="btn-primary text-white font-bold text-xl p-5 rounded-xl shadow-xl"
										>
											{t('Dashboard.admin.verify_user')}
										</button>
									</div>
								)
							}
						</div>
					)}
				</div>
        </>
    )
}

export default UserCard;