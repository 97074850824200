import useAuth from "../../../hooks/useAuth"
import LogoBranco from '../../../assets/LogoIcon-Branco.png';
import { useTranslation } from "react-i18next";

const Plan = (props) => 
{
    const { auth } = useAuth();
    const { t } = useTranslation();

    return (
        <>
        <div className='bg-fourth col-span-1 rounded-[2rem] border-2 py-3 px-4 xl:px-10 w-full flex flex-col'>
            <h1 className='text-black font-bold text-2xl pb-3 border-b-2'>{t('Dashboard.dashboard.your_plan')}</h1>
            <div className='flex 2xl:flex-row flex-col pt-3 gap-x-5 2xl:justify-between 2xl:items-center xl:mt-0 h-full'>
                <div className='flex flex-col'>
                    <h1 className='text-black font-bold text-xl pb-1'>
                        {auth?.company_id 
                            ? t('Dashboard.dashboard.business')
                            : t('Dashboard.dashboard.individual')
                        } {" "}
                        {auth?.plan_id === 1
                            ? t('Dashboard.dashboard.basic')
                            : (auth?.plan_id === 2
                                ? t('Dashboard.dashboard.plus')
                                : t('Dashboard.dashboard.pioneer'))
                        }
                    </h1>
                    <p className='text-black text-md'>
                        <span className="font-bold">{t('Dashboard.dashboard.users')}: </span>
                        {props.teamCount}/{auth?.users_number}
                    </p>
                    <p className='text-black text-md'>
                        <span className="font-bold">{t('Dashboard.dashboard.requests')}: </span>
                        {props.requestsCount}/{auth?.request_number}
                    </p>
                </div>
                <div className='flex 2xl:flex-col flex-row items-center 2xl:items-end justify-between 2xl:mt-0 mt-1'>
                    <div className='bg-fifth text-white font-bold 2xl:text-lg flex items-center justify-center gap-x-1 px-4 rounded-full py-1 2xl:mb-3'>
                        <img src={LogoBranco} alt="fortimy" />
                        {auth?.plan_id === 1
                            ? t('Dashboard.dashboard.basic')
                            : (auth?.plan_id === 2
                                ? t('Dashboard.dashboard.plus')
                                : t('Dashboard.dashboard.pioneer'))
                        }
                    </div>
                    {/* {auth?.plan_id !== 3 &&
                        <button className='2xl:text-lg bg-primary text-white font-bold rounded-full py-1 px-4'>
                            {t('Dashboard.dashboard.upgrade')}
                        </button>
                    } */}
                </div>
            </div>
        </div>
        </>
    )
}

export default Plan