import React, { useEffect } from "react";
import "animate.css/animate.min.css";
import { RouterProvider } from "react-router-dom";
import router from "./router";

import { AlertProvider } from "./contexts/AlertContext";
import Popup from "./components/UI/Popup";
import Loader from "./components/UI/Loader";
import { AuthContexts } from "./components/Config/AuthContext";
import { AuthProvider } from "./contexts/AuthProvider";

function App() {
  useEffect(() => {
    document.title = "Fortimy";
  }, []);

  return (
    <AuthProvider>
      <AuthContexts>
        <AlertProvider>
          <Popup />
          <Loader />
          <RouterProvider router={router} />
        </AlertProvider>
      </AuthContexts>
    </AuthProvider>
  );
}

export default App;
