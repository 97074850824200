import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlinePencilSquare } from "react-icons/hi2";
import { IoCloseCircleSharp } from "react-icons/io5";
import useAuth from '../../../hooks/useAuth';
import axios from "../../Config/axios";
import { useAlert } from "../../../contexts/AlertContext";
import PhoneInput from "react-phone-input-2";
import ErrorMessage from "../../UI/ErrorMessage";

const CompanySettings = () => 
{
    const { auth } = useAuth();
    const { t } = useTranslation();
    const { toggleLoader, triggerPopup } = useAlert();
    const isMounted = useRef(false);

    const [company, setCompany] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({ id: auth.company_id });
    const [hasChanges, setHasChanges] = useState(false);
    const [errors, setErrors] = useState([]);

    const handleImage = (e) =>
    {
        if(e.target.files[0].size < 1000000) {
            setFormData({...formData, file: e.target.files[0]});
        } else {
            triggerPopup('Popups.filesize_error');
        }
    }

    const handleRemoveFile = () => {
        document.getElementById('uploadFile').value = null;
        setFormData({...formData, file: null});
    }

    const handleChange = (e, field) => {
		setFormData({ ...formData, [field]: e.target.value });
	};

    const submit = async () =>
    {
        const fd = new FormData();
        for (let key in formData) {
            fd.append(key, formData[key]);
        }

        setIsLoading(true);
        await axios.post(`/api/companies/${company?.id}`, fd)
        .then((response) => {
            setHasChanges(false);
            triggerPopup('Popups.changes_saved');
        })
        .catch((e) => {
            setErrors(e.response.data.errors);
            triggerPopup('Popups.something_went_wrong');
        })
        .finally(() => {
            setIsLoading(false);
        });
    }

    const getCompany = async () =>
    {
        setIsLoading(true);
        try {
            const response = await axios.get("/api/companies", { params: {id: auth.company_id} })
            if (response.status === 200) {
                setCompany(response.data?.data[0]);
            }
        } catch (error) {
            
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        toggleLoader(isLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    useEffect(() => {
        if(isMounted.current) {
            setHasChanges(true);
        } else {
            isMounted.current = true
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    useEffect(() => {
        getCompany();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div className='bg-white shadow-custom rounded-2xl md:py-10 py-5 md:px-10 px-5 flex flex-col'>
                <div className="flex items-start gap-x-3">
                    {/* <h1 className="text-primary text-3xl font-bold">FO{company?.id}</h1> */}
                    <h1 className="text-primary text-3xl font-bold">
                        {company?.trademark}
                    </h1>
                </div>

                <div className='flex flex-col'>
                    <div className='flex justify-start border-b-2'>
                        <h1 className='text-2xl font-bold pb-5'>{t('Dashboard.settings.general_information')}</h1>
                    </div>
                    <div className='md:grid lg:grid-cols-3 py-5 md:gap-x-14 md:gap-y-5 xl:gap-28'>
                        <div className='col-span-1 flex flex-col'>
                            <p className='xl:text-lg py-2'>{t('Dashboard.settings.trademark')}</p>
                            <input onChange={(e) => handleChange(e, "trademark")} defaultValue={company?.trademark} type="text" className='border-2 shadow-sm rounded-md p-2' />
                            <ErrorMessage errors={errors.trademark} />
                        </div>
                        <div className='col-span-1 flex flex-col'>
                            <p className='xl:text-lg py-2'>{t('Dashboard.settings.corporate_name')}</p>
                            <input onChange={(e) => handleChange(e, "corporate_name")} defaultValue={company?.corporate_name} type="text" className='border-2 shadow-sm rounded-md p-2' />
                            <ErrorMessage errors={errors.corporate_name} />
                        </div>
                        <div className='col-span-1 flex flex-col'>
                            <p className='xl:text-lg py-2'>{t('Dashboard.settings.ami')}</p>
                            <input onChange={(e) => handleChange(e, "ami")} defaultValue={company?.ami} type="text" className='border-2 shadow-sm rounded-md p-2' />
                            <ErrorMessage errors={errors.ami} />
                        </div>
                    </div>
                </div>

                <div className='flex flex-col pt-5'>
                    <div className='flex justify-start border-b-2'>
                        <h1 className='text-2xl font-bold pb-5'>{t('Dashboard.settings.agency_office_address')}</h1>
                    </div>
                    <div className='md:grid lg:grid-cols-3 py-5 md:gap-x-14 md:gap-y-5 xl:gap-28'>
                        <div className='col-span-1 flex flex-col'>
                            <p className='xl:text-lg py-2'>{t('Dashboard.settings.street_name')}</p>
                            <input onChange={(e) => handleChange(e, "address")} defaultValue={company?.address} type="text" className='border-2 shadow-sm rounded-md p-2' />
                            <ErrorMessage errors={errors.address} />
                        </div>
                        <div className='col-span-1 flex flex-col'>
                            <p className='xl:text-lg py-2'>{t('Dashboard.settings.billing_city')}</p>
                            <input onChange={(e) => handleChange(e, "city")} defaultValue={company?.city} type="text" className='border-2 shadow-sm rounded-md p-2' />
                            <ErrorMessage errors={errors.city} />
                        </div>
                        <div className='col-span-1 flex flex-col'>
                            <p className='xl:text-lg py-2'>{t('Dashboard.settings.postal_code')}</p>
                            <input onChange={(e) => handleChange(e, "postal_code")} defaultValue={company?.postal_code} type="text" className='border-2 shadow-sm rounded-md p-2' />
                            <ErrorMessage errors={errors.postal_code} />
                        </div>
                    </div>
                </div>

                <div className='flex flex-col pt-5'>
                    <div className='flex justify-start border-b-2'>
                        <h1 className='text-2xl font-bold pb-5'>{t('Dashboard.settings.contact')}</h1>
                    </div>
                    <div className='md:grid lg:grid-cols-3 py-5 md:gap-x-14 md:gap-y-5 xl:gap-28'>
                        <div className='col-span-1 flex flex-col'>
                            <p className='xl:text-lg py-2'>{t('Dashboard.settings.first_name')}</p>
                            <input onChange={(e) => handleChange(e, "first_name")} defaultValue={auth?.first_name} type="text" className='border-2 shadow-sm rounded-md p-2' />
                            <ErrorMessage errors={errors.first_name} />
                        </div>
                        <div className='col-span-1 flex flex-col'>
                            <p className='xl:text-lg py-2'>{t('Dashboard.settings.last_name')}</p>
                            <input onChange={(e) => handleChange(e, "last_name")} defaultValue={auth?.last_name} type="text" className='border-2 shadow-sm rounded-md p-2' />
                            <ErrorMessage errors={errors.last_name} />
                        </div>
                        <div className='col-span-2 xl:col-span-1 flex flex-col'>
                            <p className='xl:text-lg py-2'>{t('Dashboard.settings.phone')}</p>
                            {/* <input onChange={(e) => handleChange(e, "phone")} defaultValue={company?.phone} type="text" className='border-2 shadow-sm rounded-md p-2' /> */}
                            <PhoneInput
                                defaultMask={"... ... ..."}
                                alwaysDefaultMask
                                value={`${company.phone}`}
                                onChange={(number, country) => {
                                    setFormData({
                                        ...formData,
                                        company_phone: number.replace(country.dialCode, ""),
                                        company_phone_country_code: `+${country.dialCode}`,
                                    });
                                }}
                                buttonStyle={{ border: "none", backgroundColor: "#FFFFFF"}}
                                inputStyle={{
                                    border: "none",
                                    fontSize: "16px",
                                    lineHeight: "28px",
                                    width: '100%',
                                    backgroundColor: "#FFFFFF",
                                    color: "#000"
                                }}
                                containerStyle={{
                                    border: "2px solid #e5e7eb",
                                    borderRadius: "6px",
                                    backgroundColor: "#FFFFFF"
                                }}
                                className="py-1 px-2"
                                />
                                <ErrorMessage errors={errors.company_phone} />
                        </div>
                        {/* <div className='col-span-1 flex flex-col'>
                            <p className='xl:text-lg py-2'>{t('Dashboard.settings.email')}</p>
                            <input onChange={(e) => handleChange(e, "email")} defaultValue={company?.email} type="text" className='border-2 shadow-sm rounded-md p-2' />
                        </div> */}
                    </div>
                </div>

                <div className='flex flex-col pt-5'>
                    <div className='flex justify-start border-b-2'>
                        <h1 className='text-2xl font-bold pb-5'>{t('Dashboard.settings.additional_information')}</h1>
                    </div>
                    <div className='flex flex-col'>
                        <p className='xl:text-lg py-2'>{t('Dashboard.settings.business_description')}</p>
                        <textarea onChange={(e) => handleChange(e, "description")} defaultValue={company?.description} rows="10" cols="50" className='w-full border-2 rounded-md p-2'></textarea>
                    </div>
                </div>
                
                <div className='flex flex-col pt-5'>
                    <div className='flex justify-start border-b-2'>
                        <h1 className='text-2xl font-bold pb-3'>{t('Dashboard.settings.logo')}</h1>
                    </div>
                    <div className='space-y-2 py-5'>
                        <div>
                            <p className='md:text-lg'>{t('Dashboard.settings.add_your_company_logo')}</p>
                            <p className='text-sm md:text-base text-black/40'>{t('Dashboard.settings.recommended_dimensions')}</p>
                            <p className='text-sm md:text-base text-black/40'>{t('Dashboard.settings.max_filesize')}</p>
                        </div>
                        <div className='h-24 flex'>
                            <div className='w-96 h-24'>
                                {!isLoading &&
                                <img
                                    src={ "file" in formData
                                        ? formData?.file == null
                                            ? `${process.env.REACT_APP_BACKEND_URL_IMG}/companies/placeholder.png`
                                            : URL.createObjectURL(formData?.file)
                                        : `${process.env.REACT_APP_BACKEND_URL_IMG}/companies/${company?.logo}`
                                    }
                                    className="object-contain w-full h-full"
                                    alt={t('Dashboard.settings.logo')}
                                />
                                }
                            </div>
                            <input onChange={(e) => { handleImage(e) }} type='file' className='w-full hidden' name='logo_image' id="uploadFile" />
                        </div>
                    </div>
                </div>
                <div className='grid grid-cols-4'>
                    <label htmlFor="uploadFile" className="md:col-span-1 col-span-2 flex justify-start items-center cursor-pointer">
                        <div className='text-xl md:text-3xl mx-2 text-primary'><HiOutlinePencilSquare /></div>
                        <p className='text-sm md:text-lg'>{t('Dashboard.settings.update_logo')}</p>
                    </label>
                    <button onClick={() => { handleRemoveFile() }} className='md:col-span-1 col-span-2 flex justify-start items-center cursor-pointer'>
                        <div className='text-2xl md:text-4xl mx-2 text-primary'><IoCloseCircleSharp /></div>
                        <p className='text-sm md:text-lg'>{t('Dashboard.settings.delete_logo')}</p>
                    </button>
                </div>

                <div className='md:col-start-4 col-start-3 md:col-span-1 col-span-2 flex justify-end items-center md:mt-0 mt-5'>
                    <button onClick={() => { submit() }}
                        disabled={!hasChanges || isLoading}
                        className='btn-primary text-white font-bold rounded-md md:py-3 py-2 px-5 md:text-xl text-lg'
                    >
                        {t('Dashboard.settings.save_changes')}
                    </button>
                </div>
            </div>
        </>
    )
}

export default CompanySettings;