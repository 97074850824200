import { useTranslation } from 'react-i18next';
import ForBusinessesImg from '../../../assets/Index/for-businesses.jpeg';

const ForBusinesses = () => {

    const { t } = useTranslation();

    return (
        <section className='space-y-10 2xl:space-y-20 px-10 py-10 lg:py-20 mx-auto max-w-lg md:max-w-7xl'>
            <h2 className='font-bold text-2xl md:text-4xl xl:text-5xl leading-tight text-center'>{t('Homepage.for_businesses.title')}</h2>
            <div className='flex flex-col lg:flex-row lg:items-center gap-y-8 lg:gap-x-5 2xl:gap-x-10'>
                <img className='rounded-md shadow-xl lg:w-1/2' src={ForBusinessesImg} alt='Professional imobiliária' />
                <div className='space-y-5'>
                    <div>
                        <div className='flex lg:flex-col items-center lg:items-start gap-x-2'>
                            <div className='w-6 h-6 md:w-8 md:h-8 rounded-full bg-primary font-bold text-center text-white md:text-xl md:pt-1 mb-1 shadow-md'>1</div>
                            <h3 className='md:text-2xl font-bold'>{t('Homepage.for_businesses.find_particulars')}</h3>
                        </div>
                        <p>{t('Homepage.for_businesses.expand_your_customer_base')}</p>
                    </div>
                    <div>
                        <div className='flex lg:flex-col items-center lg:items-start gap-x-2'>
                            <div className='w-6 h-6 md:w-8 md:h-8 rounded-full bg-primary font-bold text-center text-white md:text-xl md:pt-1 mb-1 shadow-md'>2</div>
                            <h3 className='w-3/4 md:w-full md:text-2xl font-bold'>{t('Homepage.for_businesses.partnership_opportunities')}</h3>
                        </div>
                        <p>{t('Homepage.for_businesses.access_requests')}</p>
                    </div>
                    <div>
                        <div className='flex lg:flex-col items-center lg:items-start gap-x-2'>
                            <div className='w-6 h-6 md:w-8 md:h-8 rounded-full bg-primary font-bold text-center text-white md:text-xl md:pt-1 mb-1 shadow-md'>3</div>
                            <h3 className='md:text-2xl font-bold'>{t('Homepage.for_businesses.access_exclusive_data')}</h3>
                        </div>
                        <p>{t('Homepage.for_businesses.exclusive_data')}</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ForBusinesses;