import React, { useEffect, useState } from "react";
import axios from "../../Config/axios";
import { useAlert } from "../../../contexts/AlertContext";
import { MANAGE_REQUESTS_FILTERS, CONFIRMATION_MODAL_ACTION } from "../../../constants/global";
import RequestCard from "./RequestCard";
import Modal from "../../Modals/Modal";
import ActionConfirmationModal from "../../Modals/ActionConfirmationModal";
import { useTranslation } from "react-i18next";
import Pagination from "../../UI/Pagination";

const Requests = () => {
	const { toggleLoader, triggerPopup } = useAlert();
	const { t } = useTranslation();

	const [requests, setRequests] = useState([]);
	const [openModal, setOpenModal] = useState(false);
	const [modalAction, setModalAction] = useState('');
	const [modalTitle, setModalTitle] = useState('');
	const [modalDescription, setModalDescription] = useState('');
	const [selectedRequest, setSelectedRequest] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [disable, setDisable] = useState(false);
	const [verifiedRequest, setVerifiedRequest] = useState({});

	//TODO add pagination
	const [currentPage, setCurrentPage] = useState(1);

	const [search, setSearch] = useState("");
	const [filter, setFilter] = useState(MANAGE_REQUESTS_FILTERS.ALL);

	const getRequests = async () => {
		setIsLoading(true);
		// setRequests([]);
		const response = await axios.get("/api/requests", {
			params: {
				per_page: 10,
				page: currentPage,
				search: search,
				filter: filter,
			},
		});
		if (response.status === 200) {
			setRequests(response.data);
			setIsLoading(false);
		}
	};

	const handleModalConfirmation = () => {
		switch (modalAction) {
			case CONFIRMATION_MODAL_ACTION.ARCHIVE:
				archiveRequest(selectedRequest)
				break;
			case CONFIRMATION_MODAL_ACTION.DELETE:
				deleteRequest(selectedRequest)
				break;
			default:
				break;
		}
	}

	const handlePageChange = (page) => {
		setCurrentPage(page)
	}

	const updateRequest = async (formData) => {
		try {
			setIsLoading(true);
			const response = await axios.put(`api/requests/${formData.id}`, formData);
			if(response.status === 204){
				triggerPopup(t('Popups.changes_saved'));
				getRequests();
			}
			setIsLoading(false);
		} catch (error) {
			setIsLoading(false);
			triggerPopup(t('Popups.something_went_wrong'));
			return error.response;
		}
	}

	const handleOnArchive = (e, request) => {
		e.stopPropagation();
		setOpenModal(true);
		setSelectedRequest(request);
		setModalAction(CONFIRMATION_MODAL_ACTION.ARCHIVE);
		setModalTitle(t('Modals.archive_request_title'))
		setModalDescription(t('Modals.archive_request_subtitle'))
	}

	const archiveRequest = async (request) => {
		setIsLoading(true);
		const response = await axios.put(`/api/toggle-archive-request/${request}`)
		if(response.status === 204) {
			setOpenModal(false);
			triggerPopup(t('Popups.request_archived'));
			getRequests();
		}
		setIsLoading(false);
	}

	const handleOnDelete = (e, request) => {
		e.stopPropagation();
		setOpenModal(true);
		setSelectedRequest(request);
		setModalAction(CONFIRMATION_MODAL_ACTION.DELETE);
		setModalTitle(t('Modals.delete_request_title'))
		setModalDescription(t('Modals.delete_request_subtitle'))
	}
	const deleteRequest = async (request) => {
		setIsLoading(true);
		const response = await axios.delete(`/api/requests/${request}`)
		if(response.status === 204) {
			setOpenModal(false);
			triggerPopup(t('Popups.request_deleted'));
			getRequests();
		}
		setIsLoading(false);
	}

	const handleOnVerify = async (id) => {
		setIsLoading(true);
		setDisable(true);
		try {
			const response = await axios.put(`/api/requests/${id}/verify`, {
				verified: 1
			})

			if(response.status === 200) {
				setVerifiedRequest(response.data);
				setDisable(false);
				setIsLoading(false);
				triggerPopup(t('Popups.email_sent_to_user'));
			}
		} catch (error) {
			setDisable(false);
			setIsLoading(false);
			triggerPopup(t('Popups.something_went_wrong'));
		}
		setIsLoading(false);
	}

	useEffect(() => {
		getRequests();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter, currentPage]);

	useEffect(() => {
		toggleLoader(isLoading);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading])

	useEffect(() => {
		setRequests(prevRequests => ({
			...prevRequests,
			data: (prevRequests?.data)?.map((request) => (request.id === verifiedRequest.id ? {...request , verified: 1} : request))
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [verifiedRequest]);
	

	return (
		<>
		<Modal isOpen={ openModal }>
			<ActionConfirmationModal
				title={modalTitle}
				description={modalDescription}
				onCancel={ () => { setOpenModal(false) } }
				onConfirm={ () => { handleModalConfirmation() } }
			/>
		</Modal>
		<div className="flex flex-col w-full gap-y-5">
			<div className="w-fit border-2 rounded-lg grid grid-cols-3 mt-6 md:text-lg text-base">
				<button
					onClick={() => setFilter(MANAGE_REQUESTS_FILTERS.ALL)}
					className={`${
						filter === MANAGE_REQUESTS_FILTERS.ALL ? "bg-primary text-white" : "bg-white text-primary"
					} 'col-span-1 rounded-md py-2 px-8 focus:outline-none'`}
				>
					{t('Dashboard.admin.all')}
				</button>
				<button
					onClick={() => setFilter(MANAGE_REQUESTS_FILTERS.ARCHIVED)}
					className={`${
						filter === MANAGE_REQUESTS_FILTERS.ARCHIVED ? "bg-primary text-white" : "bg-white text-primary"
					} 'col-span-1 rounded-md py-2 px-8 focus:outline-none'`}
				>
					{t('Dashboard.admin.archived')}
				</button>
				<button
					onClick={() => setFilter(MANAGE_REQUESTS_FILTERS.UNARCHIVED)}
					className={`${
						filter === MANAGE_REQUESTS_FILTERS.UNARCHIVED ? "bg-primary text-white" : "bg-white text-primary"
					} 'col-span-1 rounded-md py-2 px-8 focus:outline-none'`}
				>
					{t('Dashboard.admin.unarchived')}
				</button>
			</div>
			<div className="grid grid-cols-4 xl:gap-5 md:gap-2 text-xl mb-5">
				<div className="col-span-3">
					<input
						type="text"
						onChange={(e) => setSearch(e.target.value)}
						value={search}
						placeholder={t('Dashboard.admin.search_for_a_request')}
						className="border-2 rounded-md h-full w-full placeholder:text-black p-2 text-xl"
					/>
				</div>
				<button
					onClick={() => getRequests()}
					type="button"
					className="col-span-1 w-full bg-primary font-bold text-white p-2 rounded-md shadow-md"
				>
					{t('Dashboard.admin.search')}
				</button>
			</div>

			{(requests?.data)?.map((request, index) => (
				<RequestCard
					key={index}
					request={request}
					onEdit={(formData) => updateRequest(formData)}
					onArchive={ (e, request) => { handleOnArchive(e, request) }}
					onDelete={ (e, request) => { handleOnDelete(e, request) }}
					onVerify={ () => handleOnVerify(request.id) }
					disable={disable}
				/>
			))}

			<Pagination
				itemCount={requests.last_page}
				itemsPerPage={currentPage}
				onPageChange={(page) => handlePageChange(page)}
			/>

		</div>
		{ !isLoading && (requests?.data)?.length === 0 && (
			<p className="w-full flex justify-center text-primary text-3xl font-bold">{t('Dashboard.admin.no_results')}</p>
		)}
		</>
	);
};

export default Requests;
