import AccountSettings from "../../../components/Dashboard/Settings/AccountSettings";
import ScrollToTop from "../../../components/Router/ScrollToTop";

const Account = () =>
{
    return (
        <>
            <ScrollToTop />
            <AccountSettings />
        </>
    )
}

export default Account;