import { useEffect } from 'react';
import { createPortal } from 'react-dom';

const Modal = ({isOpen, onClose, children}) => {
    
    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : '';
    }, [isOpen])
    
    if (!isOpen){
        return null
    }

    return createPortal(
        <div className="bg-fourth/70 w-full fixed h-screen flex justify-center items-center z-[100] top-0 py-4">
            {children}
        </div>,
        document.body
    )
}

export default Modal;