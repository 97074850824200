import { useNavigate } from "react-router"
import { MY_REQUESTS_TABS_PATH } from "../../constants/global"
import { useTranslation } from "react-i18next";

const CTA = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()

    const onClick = () => {
        navigate(MY_REQUESTS_TABS_PATH.CREATE, { replace: true, state: { cta: true } })
    }

    return (
        <button onClick={() => onClick()} className="fixed z-[60] bottom-5 right-5 lg:bottom-10 lg:right-10 p-2 shadow-custom bg-customPurple rounded-lg text-white text-lg">
            {t("Modals.cta")}
        </button>
    )
}

export default CTA