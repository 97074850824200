import React, { useState, useEffect } from "react";
import NextStepArrow from "../../../assets/Pricing/NextStepArrow.png";
import useAuthContext from "../../Config/AuthContext";
import ErrorMessage from "../../UI/ErrorMessage";
import Modal from "../../Modals/Modal";
import PrivacyPolicy from "../../Modals/PrivacyPolicy";
import TermsConditions from "../../Modals/TermsConditions";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import InputMask from "react-input-mask";
import ReactFlagsSelect from "react-flags-select";
import { PLAN_TYPES } from "../../../constants/global";

const StepAccountForm = ({ isIndividualAccount, prevStep, nextStep, setData }) => {
	const { errors } = useAuthContext();

	const { t } = useTranslation();

	const [isPolicyOpen, setIsPolicyOpen] = useState(false);
	const [isTermsOpen, setIsTermsOpen] = useState(false);

	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmationPassword, setShowConfirmationPassword] = useState(false);

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [nationality, setNationality] = useState("PT");
	const [phoneIndex, setPhoneIndex] = useState("+351");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordVer, setPasswordVer] = useState("");
	const [picture, setPicture] = useState(null);
	const [privacyPolicy, setPrivacyPolicy] = useState(false);
	const [termsConditions, setTermsConditions] = useState(false);

	const [chars, setChars] = useState("col-span-1 text-black/60");
	const [upper, setUpper] = useState("col-span-1 text-black/60");
	const [lower, setLower] = useState("col-span-1 text-black/60");
	const [number, setNumber] = useState("col-span-1 text-black/60");
	const [symbol, setSymbol] = useState("col-span-1 text-black/60");
	const [matchPasswords, setMatchPasswords] = useState("col-span-1 text-black/60");

	const submitForm = () => {
		let formData = {
			first_name: firstName,
			last_name: lastName,
			nationality: nationality,
			user_phone_country_code: `${phoneIndex}`,
			user_phone: phoneNumber.replace(/\s/g, ""),
			user_email: email,
			password: password,
			password_confirmation: passwordVer,
			profile_image: isIndividualAccount ? "individual_account_icon.png" : (picture ?? "placeholder.png"),
			privacy_policy: privacyPolicy,
			terms_conditions: termsConditions,
			create_account: true
		};
		setData(formData);
	};

	useEffect(() => {
		if (password === passwordVer && password !== "") {
			setMatchPasswords("col-span-1 text-primary");
		} else {
			setMatchPasswords("col-span-1 text-black/60");
		}
	}, [password, passwordVer]);

	const validatePassword = (passwordInput) => {
		const uppercaseRegExp = /(?=.*?[A-Z])/;
		const lowercaseRegExp = /(?=.*?[a-z])/;
		const digitsRegExp = /(?=.*?[0-9])/;
		const specialCharRegExp = /(?=.*?[!@#$%^&*()_+-={}[\]|\\:;'"<>,.?])/;
		const minLengthRegExp = /.{8,}/;
		const uppercasePassword = uppercaseRegExp.test(passwordInput);
		const lowercasePassword = lowercaseRegExp.test(passwordInput);
		const digitsPassword = digitsRegExp.test(passwordInput);
		const specialCharPassword = specialCharRegExp.test(passwordInput);
		const minLengthPassword = minLengthRegExp.test(passwordInput);

		if (uppercasePassword) {
			setUpper("col-span-1 text-primary");
		} else {
			setUpper("col-span-1 text-black/60");
		}
		if (lowercasePassword) {
			setLower("col-span-1 text-primary");
		} else {
			setLower("col-span-1 text-black/60");
		}
		if (digitsPassword) {
			setNumber("col-span-1 text-primary");
		} else {
			setNumber("col-span-1 text-black/60");
		}
		if (specialCharPassword) {
			setSymbol("col-span-1 text-primary");
		} else {
			setSymbol("col-span-1 text-black/60");
		}
		if (minLengthPassword) {
			setChars("col-span-1 text-primary");
		} else {
			setChars("col-span-1 text-black/60");
		}
	};

	// const handleChange = (event) => {
	// 	const file = event.target.files[0];
	// 	setPicture(event.target.files[0]);
	// 	const reader = new FileReader();
	// 	reader.onload = (event) => {
	// 		setImage(event.target.result);
	// 	};
	// 	reader.readAsDataURL(file);
	// };

	return (
		<>
			<Modal isOpen={isPolicyOpen}>
				<PrivacyPolicy
					onClose={() => {
						setIsPolicyOpen(false);
					}}
				/>
			</Modal>

			<Modal isOpen={isTermsOpen}>
				<TermsConditions
					onClose={() => {
						setIsTermsOpen(false);
					}}
				/>
			</Modal>

			<section className="pb-20">
				<div className="flex justify-center mt-2 lg:mt-20 mb-2">
					<button className="w-20 lg:w-full rotate-180" onClick={() => prevStep()}>
						<img className="mx-auto" src={NextStepArrow} alt="Previous Step" />
					</button>
				</div>
				<div className="flex flex-col">
					<div className="flex justify-center">
						<h1 className="text-primary lg:text-xl">
							{ isIndividualAccount ? (
								<>
									<span className="font-bold">{t("Pricing.Step3BusinessTitleHighlight")}</span>
									{t("Pricing.Step4.tell_us_about_yourself")}
								</>
							) : (
								<>
									<span className="font-bold">{t("Pricing.Step4BusinessTitleHighlight")}</span>
									{t("Pricing.Step4BusinessTitle")}
								</>
							)}
						</h1>
					</div>
					<div>
						<div className="md:grid md:grid-cols-10 flex flex-col justify-center my-5">
							<div className="flex md:flex-row flex-col lg:col-start-4 lg:col-end-8 md:col-start-3 md:col-end-9 items-center justify-between">
							{ isIndividualAccount ? (
									<>
										<h1 className="text-black font-bold text-2xl lg:text-3xl">
											{t("Pricing.Step4.personal_data")}
										</h1>
										<p className="text-primary lg:text-lg font-bold">
											{t("Pricing.Step4.visible_to_others")}
										</p>
									</>
								) : (
									<>
										<h1 className="text-black font-bold text-2xl lg:text-3xl">
											{t("Pricing.Step4BusinessSubTitle1")}
										</h1>
										<p className="text-primary lg:text-lg font-bold">
											{t("Pricing.Step4BusinessSubTitle2")}
										</p>
									</>
							)}
							</div>
						</div>
						<div className="md:grid md:grid-cols-10 flex flex-col md:p-0 px-10 justify-center">
							<form className="flex flex-col xl:col-start-5 lg:col-start-4 md:col-start-3 xl:col-end-7 lg:col-end-8 md:col-end-9">
								<div className="flex my-2 space-x-4">
									<div className="w-1/2">
										<input
											onChange={(e) => {
												setFirstName(e.target.value);
											}}
											value={firstName}
											className="w-full p-2 border-[1px] shadow-lg rounded-md border-fifth lg:text-lg placeholder:text-grey"
											type="text"
											placeholder={t("Pricing.Step4BusinessInput1FirstName")}
										/>
										<ErrorMessage errors={errors.first_name} />
									</div>
									<div className="w-1/2">
										<input
											onChange={(e) => {
												setLastName(e.target.value);
											}}
											value={lastName}
											className="w-full p-2 border-[1px] shadow-lg rounded-md border-fifth lg:text-lg placeholder:text-grey"
											type="text"
											placeholder={t("Pricing.Step4BusinessInput2LastName")}
										/>
										<ErrorMessage errors={errors.last_name} />
									</div>
								</div>
								<ReactFlagsSelect
									selectButtonClassName="my-2 p-2 border-2 shadow-lg rounded-md border-fifth lg:text-lg placeholder:text-grey"
									placeholder={t("Pricing.Step4BusinessInput3Nation")}
									selected={nationality}
									onSelect={(code) => setNationality(code)}
									searchable={true}
									fullHeight={true}
								/>
								<ErrorMessage errors={errors.nationality} />
								<div className="flex my-2">
									<PhoneInput
										country={"pt"}
										value={phoneIndex}
										onChange={(code) => setPhoneIndex(code)}
										containerClass='shadow-md !w-2/5 !md:w-1/5'
										inputStyle={{ height: "100%", width: "100%" }}
										enableSearch={true}
										disableDropdown={false}
										inputProps={{ readOnly: true }}
									/>
									<InputMask
										mask="999 999 999"
										onChange={(e) => {
											setPhoneNumber(e.target.value);
										}}
										value={phoneNumber}
										className="ml-2 w-2/3 p-2 border-[1px] shadow-lg rounded-md border-fifth lg:text-lg placeholder:text-grey"
										type="text"
										placeholder={t("Pricing.Step4BusinessInput5Phone")}
									/>
								</div>
								<ErrorMessage errors={errors.user_phone} />
								<input
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									value={email}
									className="my-2 p-2 border-[1px] shadow-lg rounded-md border-fifth lg:text-lg placeholder:text-grey"
									type="email"
									placeholder={t("Pricing.Step4BusinessInput6Email")}
								/>
								<ErrorMessage errors={errors.user_email} />
								<div className="flex items-center w-full relative">
									<input
										onChange={(e) => {
											setPassword(e.target.value);
											validatePassword(e.target.value);
										}}
										value={password}
										type={showPassword ? "text" : "password"}
										className="w-full my-2 p-2 border-[1px] shadow-lg rounded-md border-fifth lg:text-lg placeholder:text-grey"
										placeholder={t("Pricing.Step4BusinessInput7Password")}
									/>
									<button
										type="button"
										onClick={() => {
											showPassword ? setShowPassword(false) : setShowPassword(true);
										}}
										className="absolute right-3 text-xl text-black"
									>
										{showPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
									</button>
								</div>
								<ErrorMessage errors={errors.password} />
								<div className="text-xs flex mt-1 mb-1 gap-x-4 gap-y-2 flex-wrap">
										<div className={chars}>{t("Pricing.characters")}</div>
										<div className={upper}>{t("Pricing.uppercase")}</div>
										<div className={lower}>{t("Pricing.lowercase")}</div>
										<div className={number}>{t("Pricing.digit")}</div>
										<div className={symbol}>{t("Pricing.symbol")}</div>
										<div className="w-[1px]"></div>
								</div>
								<div className="flex items-center w-full relative">
									<input
										onChange={(e) => {
											setPasswordVer(e.target.value);
										}}
										type={showConfirmationPassword ? "text" : "password"}
										className="w-full my-2 p-2 border-[1px] shadow-lg rounded-md border-fifth lg:text-lg placeholder:text-grey"
										placeholder={t("Pricing.Step4BusinessInput8PasswordVerify")}
									/>
									<button
										type="button"
										onClick={() => {
											showConfirmationPassword
												? setShowConfirmationPassword(false)
												: setShowConfirmationPassword(true);
										}}
										className="absolute right-3 text-xl text-black"
									>
										{showConfirmationPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
									</button>
								</div>
								<ErrorMessage errors={errors.password_confirmation} />
								<div className="text-xs flex my-1">
									<div className={matchPasswords}>{t("Pricing.matchPassword")}</div>
								</div>
							</form>
							{ !isIndividualAccount &&
								<div className="flex flex-col lg:col-start-4 lg:col-end-8 md:col-start-3 md:col-end-9 mt-5">
									<div className="flex justify-center">
										<h1 className="text-primary lg:text-xl font-bold">{t("Pricing.Step4BusinessInfo")}</h1>
									</div>
								</div>
							}
							<div className="flex flex-col xl:col-start-5 xl:col-end-7 lg:col-start-4 lg:col-end-8 md:col-start-3 md:col-end-9 mt-5">
								<div className="flex flex-col justify-start my-4 space-y-2">
									<label>
										<input
											onChange={(e) => {
												setPrivacyPolicy(e.target.checked);
											}}
											checked={privacyPolicy === true ? "checked" : null}
											className="mr-2 accent-black"
											type="checkbox"
										/>
										{t("Pricing.Step4BusinessInput9")}
										<button
											className="text-primary underline"
											onClick={() => setIsPolicyOpen(true)}
										>
											{t("Pricing.Step4BusinessInput9Btn")}
										</button>
									</label>
									<ErrorMessage errors={errors.privacyPolicy} />
									<label className="flex items-start">
										<input
											onChange={(e) => {
												setTermsConditions(e.target.checked);
											}}
											checked={termsConditions === true ? "checked" : null}
											className="mr-2 accent-black"
											type="checkbox"
										/>
										<p className="leading-tight">
											{t("Pricing.Step4BusinessInput10Pt1")}
											<button
												type="button"
												className="text-primary underline"
												onClick={() => setIsTermsOpen(true)}
											>
												{t("Pricing.Step4BusinessInput10Btn")}
											</button>
											{t("Pricing.Step4BusinessInput10Pt2")}
										</p>
									</label>
									<ErrorMessage errors={errors.termsConditions} />
								</div>
							</div>
						</div>
						<div className="flex justify-center mt-5 lg:mt-10">
							<button
								disabled={!privacyPolicy || !termsConditions}
								type="submit"
								className="text-white btn-primary text-lg lg:text-2xl font-bold px-12 py-2 rounded-lg shadow-lg"
								onClick={submitForm}
							>
								{t("Pricing.submitForm")}
							</button>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default StepAccountForm;
