import React from 'react';
import CreateComponent from '../../../components/Dashboard/MyRequests/Create';
import ScrollToTop from '../../../components/Router/ScrollToTop';

const MyRequests = () => {
    return (
        <>
            <ScrollToTop />
            <CreateComponent />
        </>
    )
}

export default MyRequests